<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div class="d-flex gray-box">
        <div>
          <div class="form-inp pt-0" style="width: 150px">
            <v-select :items="dropItems" item-text="title" outlined hide-details placeholder="기업명" label="">
            </v-select>
          </div>
          <div class="form-inp ml-2 pt-0" style="width: 122px">
            <v-select :items="dropItems" item-text="title" outlined hide-details placeholder="상태" label="">
            </v-select>
          </div>
          <v-text-field class="form-inp ml-2 pt-0" style="width: 162px" name="name" placeholder="상담사명 " outlined hide-details>
          </v-text-field>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')" dark small class="btn-main-search pa-0" min-width="32px" height="32px" plain >
            <v-icon small class="svg-main-searh white"></v-icon>
          </v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')" dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px" plain >
            <v-icon small class="svg-main-refresh"></v-icon>
          </v-btn>
        </div>
      </div>
      <div class="mt-3">
        <div class="text-right my-2">
          <span class="badge-sucess">호전환가능</span>
          <span class="badge-disable">호전환불가능</span>
        </div>
        <v-data-table dense :headers="gridDataHeaders" :items="gridDataText" item-key="index"
          :items-per-page="30" :page.sync="page" hide-default-footer @page-count="pageCount = $event"
          class="grid-default">
          <template v-slot:item="row">
            <tr>
              <td class="text-center">{{ row.item.index }}</td>
              <td class="text-center">{{ row.item.index01 }}</td>
              <td class="text-center">{{ row.item.index02 }}</td>
              <td class="text-center">{{ row.item.index03 }}</td>
              <td class="text-center">{{ row.item.index04 }}</td>
              <td class="text-center">
                <v-btn v-if="mixin_set_btn($options.name, 'btnCallCheck')" outlined small class="btn-default" @click="onButtonClick(row.item)">선택</v-btn>
              </td>
            </tr>

          </template>
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="totalVisible" next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"></v-pagination>
        </div>
        <div class="text-right"><span class="grid-total-count">전체 <strong > {{ gridDataText.length }}건</strong></span></div>
      </div>

      <!-- 2자 통화 -->
      <div class="mt-3 d-flex">
        <div>
          <v-text-field
              class="form-inp sm pt-0"
              name="name"
              label="선택된 내선번호 "
              outlined
              hide-details
            >
          </v-text-field>
        </div>
        <div class="ml-2 align-self-center mr-0">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnCall2')" outlined  class="btn-default" @click="$emit('call2p')">2자 통화 걸기</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnCallMove1')" outlined  class="btn-point ml-2" @click="$emit('callmove')" disabled>전환</v-btn>
        </div>
        <div class="maincalltrans-modal--time ">
          통화시간 {{ lapseTime }}
        </div>
      </div>
      <!-- 3자 통화 -->
      <div class="mt-3 d-flex">
        <div>
          <v-text-field
              class="form-inp sm pt-0"
              name="name"
              label="선택된 내선번호 "
              outlined
              hide-details
            >
          </v-text-field>
        </div>
        <div class="ml-2 align-self-center mr-0">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnCall3')" outlined class="btn-default " @click="$emit('call3p')">3자 통화 걸기</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnCallMove2')" outlined class="btn-point ml-2" @click="$emit('callmove')" disabled>전환</v-btn>
        </div>
        <div class="maincalltrans-modal--time ">
          통화시간 {{ lapseTime }}
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { mixin } from "@/mixin/mixin.js";

  export default {
    name: "MENU_M110101P01", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {
      headerTitle: {
        type: String,
        default: "제목",
      },
    },
    data: () => ({
      dropItems: [{
        title: "option1"
      }],
      gridDataHeaders: [{
          text: "No",
          align: "center",
          value: "index",
          width: "50px",
          sortable: false,
        },
        {
          text: "상담그룹",
          value: "index01",
          align: "center",
        },
        {
          text: "상담사",
          value: "index02",
          align: "center",
        },
        {
          text: "내선번호",
          value: "index03",
          align: "center",
        },
        {
          text: "상태",
          value: "index04",
          align: "center",
        },
        {
          text: "",
          value: "index05",
          align: "center",
          width: "70px"
        },

      ],
      gridDataText: [{
          index: 1,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },
        {
          index: 2,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },
        {
          index: 3,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },
        {
          index: 4,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },
        {
          index: 5,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },
        {
          index: 6,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },
        {
          index: 7,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },
        {
          index: 8,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },
        {
          index: 9,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },

      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      totalVisible: 10,
      lapseTime: '00:00:00'
    }),
    methods: {
      onButtonClick(item) {
        console.log('click on ' + item.index)
      }
    },
  };
</script>

<style></style>