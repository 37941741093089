<template>
  <div class="layout-maincontainer" style="height: 100%">
    <h1 class="tit-h1">
      <v-icon class="svg-cs-main"></v-icon>
      상담메인
    </h1>
    <div class="layout-chatmain">
      <!-- //mark:콜상담 -->
      <div class="layout-chatmain--call">
        <!-- top -->
        <div class="layout-chatmain--call-top layout-chatmain-box">
          <div class="d-flex">
            <span class="in-label">재택여부</span>
            <v-switch hide-details class="custom-switch3 ml-3" @click="btnCtiHome()" 
              :disabled="CTI_LOGIN_BOOLEAN === true"
              v-model="CTI_HOME_BOOLEAN">
            </v-switch>
          </div>
          <div class="d-flex ml-4">
            <span class="in-label">콜 상담</span>
            <v-switch hide-details class="custom-switch2 ml-3" @click="btnCtiLogin()" v-model="CTI_LOGIN_BOOLEAN">
            </v-switch>
          </div>
          <div class="layout-chatmain--call-top--info">
            <v-icon :class="
              CTI_CALL_STATUS.incall === true
                ? 'svg-chatmain-incall'
                : 'svg-chatmain-outcall'
            "></v-icon>
            <div class="layout-chatmain--call-top--ci">
              <img :src="CTI_CALL_STATUS.img" :alt="CTI_CALL_STATUS.imgAlt" />
            </div>
            <div class="layout-chatmain--call-top--cs">
              <span class="layout-chatmain--call-top--name">{{
              CTI_CALL_STATUS.name
              }}</span>
              <span class="layout-chatmain--call-top--phone" @mouseover="CTI_CALL_STATUS.mask = true"
                @mouseleave="CTI_CALL_STATUS.mask = false">
                {{ CTI_CALL_STATUS.phoneMaskNumber }}
              </span>
              <span
                class="layout-chatmain--call-top--phone-unmask"
                :class="{ active: CTI_CALL_STATUS.mask === true }"
                >{{ CTI_CALL_STATUS.phoneNumber }}</span
              >
            </div>
          </div>
        </div>
        <!--// top -->
        <!-- //mark: 콜상담저장, 고객정보, 이력 -->
        <div class="layout-chatmain--call-ct d-flex">
          <!-- //mark:고객정보 -->
          <div class="d-flex flex-column">
            <!-- 버튼 -->
            <div class="layout-chatmain--btn layout-chatmain-box">
              <v-btn
                v-for="(item, index) in CTI_BTN"
                :key="index"
                class="cti-btn mr-2"
                :disabled="item.disalb === true"
                :name=item.icon
                :id=item.icon                
                :class="{'v-btn--active': item.cls === true, 'cti-btn--expand': item.evt === true}"
                @click="
                  item.restEvt ? btnCtiActiveExpand($event) : btnCtiActive($event);
                  item.evt ? showDialog(item.evtId) : '';
                "
              >
                <v-icon 
                 :class="item.refIcon ? CTI_BRD_MOVE.icon : item.icon"
                ></v-icon>
                <span
                  class="layout-chatmain--btn-text"
                >{{ item.refText ? CTI_BRD_MOVE.current : item.text }}</span>
              </v-btn>
              <!-- 전화수신모달 -->
              <v-dialog
                max-width="320"
                persistent
                v-model="dialogM110101P05"
                content-class="square-modal maincall-modal"
              >
                <div class="modal-wrap">
                  <h1 class="tit-h1 d-flex">
                    수신전화
                    <div class="ml-auto align-self-center">
                      <v-icon
                        x-small
                        class="svg-close"
                        @click="hideDialog('M110101P05')"
                      ></v-icon>
                    </div>
                  </h1>
                  <div class="modal-box">
                    <div>
                      <div calss="maincall-modal--ci">
                        <img :src="CTI_TAKE_CALL.ci" :alt="CTI_TAKE_CALL.company" />
                      </div>
                      <span class="maincall-modal--company">{{
                        CTI_TAKE_CALL.company
                      }}</span>
                      <span class="maincall-modal--phone">{{
                        CTI_TAKE_CALL.phone
                      }}</span>
                      <span class="maincall-modal--text">{{
                        CTI_TAKE_CALL.text
                      }}</span>
                      <div class="maincall-modal--btn">
                        <v-btn outlined large class="btn-etc col-12">
                          전화받기
                          <v-icon
                            x-small
                            class="svg-chatmain-btn-call ml-2"
                            @click="callReceive()"
                          ></v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </v-dialog>

              <!-- 전화걸기 모달 -->
              <!-- <v-dialog max-width="185" persistent v-model="dialogM110101P04" content-class="main-modal">
                <div>
                  <h1 class="tit-h1">걸기</h1>
                  <div class="form-inp full mt-4">
                    <v-select
                      :items="dropItems"
                      item-text="title"
                      outlined
                      placeholder="업체선택"
                      hide-details
                    ></v-select>
                  </div>
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    placeholder="고객전화번호"
                    hide-details
                    outlined >
                  </v-text-field>
                  <div class="d-flex mt-5">
                    <v-btn outlined class="btn-point col-12" @click="hideDialog('M110101P04')">걸기</v-btn>
                  </div>
                </div>
              </v-dialog> -->

              <!-- 호전환 모달 -->
              <v-dialog
                max-width="655"
                persistent
                v-model="dialogM110101P01"
                content-class="square-modal maincalltrans-modal"
              >
                <dialog-M110101P01
                  headerTitle="호전환"
                  @hide="hideDialog('M110101P01')"
                  @submit="submitDialog('M110101P01')"
                  @call2p="call2p('M110101P01')"
                  @call3p="call3p('M110101P01')"
                  @cancel="cancel('M110101P01')"
                  @callmove="callmove('M110101P01')"
                >
                </dialog-M110101P01>
              </v-dialog>
              
              <!--
              <v-btn
                class="cti-btn cti-btn--expand mr-2"
                @click="btnCtiActiveExpand"
              >
                <v-icon
                  ref="ctiToggleIcon"
                  class="cti-btn-icon08"
                  :class="ctiMenuClass"
                  ignore-active-state="true"
                >
                </v-icon>
                <span ref="ctiToggleText">이석</span>
              </v-btn> -->

              <!-- 이석 모달 -->
              <div class="cti-toggle-menu" v-show="CTI_BRD.expand == true">
                <button
                  v-for="(item, index) in CTI_REASON"
                  :key="index"
                  @click="ctiReasonUpdate(index)" class="mb-4"
                  >
                  <v-icon :class="item.className"></v-icon
                  ><span class="cti-btn--expand-title">{{
                    item.text
                  }}</span>
                </button>
              </div>
              <!-- cti 상태 -->
              <div class="cti-current-wrap">
                <span class="cti-current" :class="{'ani-twinkle': CTI_BRD.ctiTextLapse === true}" >
                  {{ ctiStateText }}
                  <!-- {{ ctiBoardMove.current ? ctiBoard.current : "CTI OFF" }} -->
                  <v-icon
                    :class="CTI_BRD_MOVE.status == true ? CTI_BRD_MOVE.icon : CTI_BRD.icon"
                    class="ml-2">
                  </v-icon>
                </span>
                <span class="cti-time" :class="{'clr-error': CTI_BRD.ctiTimeLapse === true}">{{ CTI_BRD.lapseTime }}</span>
              </div>
            </div>
            <!-- //버튼 -->
            <!--// 콜 상담저장 -->
            <div class="layout-chatmain--form layout-chatmain-box flex-grow-1">
              <h2 class="tit-h2 mt-3">콜 상담저장</h2>
              <div class="d-flex justify-space-between align-center mt-5">
                <v-radio-group
                  v-model="CNSL_CALL_TY"
                  row
                  class="radio-inline"
                >
                  <template v-slot:label>
                    <span class="in-label">콜유형</span>
                  </template>
                  <v-radio
                    v-for="(rad01, index) in CNSL_CALL_TY_RADIOGRP"
                    :key="CNSL_CALL_TY_RADIOGRP[index].text"
                    :label="rad01.text"
                    :on-icon="'svg-rad-on'"
                    :off-icon="'svg-rad-off'"
                    :value="rad01.value"
                    :disabled="CNSL_DISABLED === true"
                  >
                  </v-radio>
                </v-radio-group>
                <div class="form-inp sm pa-0">
                  <v-select
                    :items="BL_DROPITEMS"
                    item-text="icon"
                    outlined
                    placeholder="B/L"
                    hide-details
                    v-model="CNSL_ATENT_CUSTOMER"
                    :disabled="CNSL_DISABLED === true"
                  >
                    <template slot="selection" slot-scope="data">
                      <v-icon :class="[data.item.icon]"></v-icon>
                      <div
                        class="v-select__selection v-select__selection--comma"
                      >
                        {{ data.item.title }}
                      </div>
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-icon :class="[data.item.icon]"></v-icon>
                      <div class="v-list-item__title" style="flex-basis: auto">
                        {{ data.item.title }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="d-flex justify-space-between align-center mt-2">
                <v-checkbox
                  :on-icon="'svg-chk-on'"
                  :off-icon="'svg-chk-off'"
                  label="상담예약"
                  hide-details
                  v-model="CNSL_RESVE_CHK"
                  :disabled="CNSL_DISABLED === true"
                ></v-checkbox>
                <v-menu
                  v-model="callCalendar"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  content-class="calendar-modal"
                  color="#FFF"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormatted2"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      hide-details
                      class="form-inp icon-calendar ml-2 pt-0"
                      append-icon="svg-calendar"
                    ></v-text-field>
                  </template>
                  <div class="d-flex align-center">
                    <v-date-picker
                      v-model="date"
                      dark
                      color="#F7AFAE"
                      no-title
                      show-current="false"
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                    <v-time-picker
                      v-model="timeStep"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                    >
                    </v-time-picker>
                  </div>
                  <div class="text-center pt-3 pb-3">
                    <v-btn
                      outlined
                      small
                      class="btn-point ml-3"
                      @click="callCalendar = false"
                      >확인</v-btn
                    >
                  </div>
                </v-menu>
              </div>
              <div class="d-flex justify-space-between align-center mt-2">
                <div class="form-inp col-6 pa-0">
                  <!--
                  <v-select
                    :items="dropItemsCompany"
                    item-text="title"
                    outlined
                    placeholder="업체명"
                    hide-details
                  >
                  </v-select>   :disabled="CNSL_DISABLED === true"
                  -->
                  <v-select
                        class="form-inp free pt-0"
                        :items="ASP_CUST_KEY"
                        item-value="CD"
                        item-text="CD_NM"
                        outlined
                        hide-details
                        placeholder="업체명"
                        v-model="CNSL_ASP_CUST_KEY"
                        style="width: 198px"
                        v-on:change="getCnslTypCd('1')"
                        :disabled="CNSL_DISABLED === true"
                  >
                  </v-select> 
                </div>
                <div class="form-inp free pa-0 ml-2">
                  <v-select
                      outlined
                      placeholder="상담유형1"
                      hide-details
                      :items="CALL_CNSL_TYP_CD"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_CNSL_TYP_CD"
                      v-on:change="getCnslTypCd('2')"
                      :disabled="CNSL_DISABLED === true"
                    >  
                    <template v-slot:label>
                      상담유형1
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="d-flex justify-space-between align-center mt-2">
                <div class="form-inp col-6 pa-0">
                  <v-select
                    :items="PROC_DROPITEMS"
                    :item-value="PROC_DROPITEMS.value"
                    :item-text="PROC_DROPITEMS.text"
                    outlined
                    hide-details
                    v-model="CNSL_PROC_CODE_LCLS"
                    :disabled="CNSL_DISABLED === true"
                  >
                    <template v-slot:label>
                      상담결과
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-select>
                </div>
                <div class="form-inp free pa-0 ml-2">
                  <v-select
                      outlined
                      placeholder="상담유형2"
                      hide-details
                      :items="CALL_CNSL_TYP_CD_2"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_CNSL_TYP_CD_2"
                      v-on:change="getCnslTypCd('3')"
                      :disabled="CNSL_DISABLED === true"
                    >  
                    <template v-slot:label>
                      상담유형2
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="d-flex justify-space-between align-center mt-2">
                <div class="form-inp col-6 pa-0">
                  <v-select
                    :items="CALL_TY_DROPITEMS"
                    :item-value="CALL_TY_DROPITEMS.value"
                    :item-text="CALL_TY_DROPITEMS.text"
                    outlined
                    placeholder="유형"
                    hide-details
                    v-model="CNSL_CALL_TY_DTL"
                    :disabled="CNSL_DISABLED === true"
                  >
                  </v-select>
                </div>
                <div class="form-inp free pa-0 ml-2">
                  <v-select
                      outlined
                      placeholder="상담유형3"
                      hide-details
                      :items="CALL_CNSL_TYP_CD_3"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_CNSL_TYP_CD_3"
                      :disabled="CNSL_DISABLED === true"
                    >  
                    <template v-slot:label>
                      상담유형3
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-select>
                </div>
              </div>
              <!-- OB case -->
              <div class="d-flex justify-space-between align-center mt-2" v-if="CNSL_CALL_TY == '02'">
                <div class="form-inp col-6 pa-0">
                  <v-select
                    :items="PROC_OB_DROPITEMS"
                    :item-value="PROC_OB_DROPITEMS.value"
                    :item-text="PROC_OB_DROPITEMS.text"
                    outlined
                    placeholder="처리구분"
                    hide-details
                    v-model="CNSL_PROC_OB"
                  >
                  </v-select>
                </div>
                <div class="form-inp free pa-0 ml-2">
                  <v-select
                    :items="FISH_OB_DROPITEMS"
                    :item-value="FISH_OB_DROPITEMS.value"
                    :item-text="FISH_OB_DROPITEMS.text"
                    outlined
                    placeholder="처리결과"
                    hide-details
                    v-model="CNSL_FISH_OB"
                  >
                  </v-select>
                </div>
              </div>
              <div class="mt-2">
                <!-- IN case -->
                <v-textarea
                  label=""
                  no-resize
                  outlined
                  :height="CNSL_CALL_TY == '01' ? '51px' : '30px'"
                  :rows="CNSL_CALL_TY == '01' ? '2' : '1'"
                  hide-details
                  dense
                  :class="CNSL_CALL_TY == '01' ? '': 'mt-0'"
                  placeholder="관리메모"
				  v-model="CNSL_CUSTOMER_MEMO"
                  :disabled="CNSL_DISABLED === true"
                ></v-textarea>
              </div>
              <div class="mt-2">
                <v-textarea
                  label=""
                  no-resize
                  outlined
                  :height="CNSL_CALL_TY == '01' ? '84px' : '68px'"
                  hide-details
                  :disabled="CNSL_DISABLED === true"
                  v-model="CNSL_CNTN"
                >
                  <template v-slot:label>
                    상담내용
                    <v-icon color="#EF9191">mdi-circle-small</v-icon>
                  </template>
                </v-textarea>
              </div>
              <div class="mt-2 text-right">
                <v-btn outlined class="btn-point ml-3" :disabled="CNSL_DISABLED === true">저장</v-btn>
              </div>
            </div>
            <!--// 콜 상담저장 -->
          </div>
          <!-- //mark:고객정보, 고객상담이력, -->
          <div class="layout-chatmain--cs-info layout-chatmain-box">
            <v-tabs class="tabs-form" slider-color="#6C6E7A" slider-size="3">
              <v-tab>고객정보</v-tab>
              <v-tab>고객상담이력</v-tab>
              <!-- 고객정보 컨텐츠 -->
              <v-tab-item>
                <div class="layout-chatmain--cs-info-ct">
                  <!-- 전화상담 고객정보 조회 시작 Arthur.Kim -->
                  <div class="align-center gray-box">
                    <div class="d-flex">
                      <v-select
                        class="form-inp free pt-0"
                        :items="ASP_CUST_KEY"
                        item-value="CD"
                        item-text="CD_NM"
                        outlined
                        hide-details
                        label="업체명"
                        placeholder="선택하세요"
                        v-model="SCH_ASP_CUST_KEY"
                        style="width: 198px"
                      >
                        <template v-slot:label>
                          업체명
                          <v-icon color="#EF9191">mdi-circle-small</v-icon>
                        </template>
                      </v-select>
                      <v-text-field
                        class="form-inp free ml-2 pt-0"
                        label="전화번호 "
                        id="callnum"
                        outlined
                        hide-details
                        v-model="SCH_CUSTOMER_PHONE_NO"
                      >
                      </v-text-field>
                    </div>
                    <div class="d-flex mt-2">
                      <v-text-field
                        class="form-inp free pt-0 flex-grow-0"
                        label="고객명 "
                        outlined
                        hide-details
                        style="width: 118px"
                        v-model="SCH_CUSTOMER_NM"
                      >
                      </v-text-field>
                      <div class="ml-2 align-self-center mr-0">
                        <!-- 조회 버튼 @click="showDialog('M110101P02')"-->
                        <v-btn
                          dark
                          small
                          class="btn-main-search pa-0"
                          min-width="32px"
                          height="32px"
                          plain
                          @click="[btnCust(CNSL_WK[0]),showDialog('MP01')]"
                        >
                          <v-icon small class="svg-main-searh white"></v-icon>
                        </v-btn>
                        <!-- 수정/저장 버튼 -->
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              dark
                              small
                              class="btn-main-refresh pa-0 ml-2"
                              min-width="32px"
                              height="32px"
                              plain
                              v-bind="attrs"
                              v-on="on"
                              :disabled="CUS_DISABLED === true"
                              @click="CUS_DISABLED = !CUS_DISABLED"
                            >
                              <v-icon small class="svg-main-down"></v-icon>
                            </v-btn>
                          </template>
                          <span>수정/저장</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <!-- 조회 모달 -->
                    <v-dialog
                      max-width="810"
                      persistent
                      v-model="dialogM110101P02"
                      content-class="square-modal maincalltrans-modal"
                    >
                      <dialog-M110101P02
                        headerTitle="고객정보조회"
                        @hide="hideDialog('M110101P02')"
                      >
                      </dialog-M110101P02>
                    </v-dialog>
                  </div>
                  <!-- 전화상담 고객정보 조회 종료 Arthur.Kim -->

                  <!-- 전화상담 고객정보 시작 Arthur.Kim -->
                  <div class="mt-3 layout-chatmain--cs-info-ct-form">
                    <div class="d-flex align-stretch">
                      <span class="in-label text-left col-4">업체명 </span>
                      <v-text-field
                        class="form-inp free pt-0 ml-2"
                        outlined
                        hide-details
                        :readonly="CUS_DISABLED === true"
                        v-model="CUS_ASP_CUST_NM"
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">
                        <v-badge dot color="#EF9191">고객명</v-badge>
                      </span>
                      <v-text-field
                        class="form-inp free pt-0 ml-2"
                        outlined
                        hide-details
                        :readonly="CUS_DISABLED === true"
                        v-model="CUS_CUSTOMER_NM"
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">Key 값</span>
                      <v-text-field
                        class="form-inp free pt-0 ml-2"
                        outlined
                        hide-details
                        :readonly="CUS_DISABLED === true"
                        v-model="CUS_TALK_USER_KEY" 
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">
                        <v-badge dot color="#EF9191">전화번호1</v-badge>
                        <template>
                          <v-btn
                            depressed
                            icon
                            x-small
                            @click="showDialog('M110101P03')"
                            class="fomr-inp--btn svg-call-red ml-6"
                            :disabled="CUS_PHONE_DISABLED === true"
                          >
                          </v-btn>
                        </template>
                      </span>
                      <v-text-field
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        value=""
                        outlined
                        hide-details
                        :readonly="CUS_DISABLED === true"
                        v-model="CUS_CUSTOMER_PHONE_NO" 
                      >
                        <template v-slot:append>
                          <v-btn
                            depressed
                            icon
                            x-small
                            @click="cpCusPhone()"
                            class="fomr-inp--btn svg-call-red"
                            :disabled="CUS_PHONE_DISABLED === true"
                          >
                          </v-btn>
                        </template>
                      </v-text-field>
                      <!-- 전화 걸기 모달 -->
                      <v-dialog
                        max-width="320"
                        persistent
                        v-model="dialogM110101P03"
                        content-class="square-modal maincall-modal"
                      >
                        <div class="modal-wrap">
                          <h1 class="tit-h1 d-flex">
                            발신전화
                            <div class="ml-auto align-self-center">
                              <v-icon
                                x-small
                                class="svg-close"
                                @click="hideDialog('M110101P03')"
                              ></v-icon>
                            </div>
                          </h1>
                          <div class="modal-box type-call">
                            <div>
                              <div calss="maincall-modal--ci">
                                <img
                                  :src="CTI_SEND_CALL.ci"
                                  :alt="CTI_SEND_CALL.company"
                                />
                              </div>
                              <div
                                class="form-inp pa-0 mt-2"
                                style="width: 163px"
                              >
                                <v-select
                                  :items="dropItems"
                                  item-text="title"
                                  outlined
                                  hide-details
                                  placeholder="업체명"
                                ></v-select>
                              </div>
                              <span
                                class="maincall-modal--company type-call"
                                style="width: 163px"
                                >{{ CTI_SEND_CALL.first }}</span
                              >
                              <span
                                class="maincall-modal--phone type-call"
                              ></span>
                              <span class="maincall-modal--text">{{
                                CTI_SEND_CALL.phone
                              }}</span>
                              <div class="maincall-modal--btn">
                                <v-btn
                                  outlined
                                  large
                                  class="btn-point"
                                  @click="callTest()"
                                >
                                  걸기
                                </v-btn>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-dialog>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">전화번호2 </span>
                      <v-text-field
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        outlined
                        hide-details
                        :readonly="CUS_DISABLED === true"
                        v-model="CUS_WRC_TEL_NO"
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">이메일</span>
                      <v-text-field
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        value="palette@hkcloud.co.kr "
                        outlined
                        hide-details
                        :readonly="CUS_DISABLED === true"
                        v-model="CUS_EMAIL"
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">주소</span>
                      <div class="d-flex flex-wrap">
                        <div class="d-flex col-12 pa-0">
                          <v-text-field
                            class="form-inp full pt-0 ml-2"
                            name="name"
                            outlined
                            hide-details
                            :readonly="CUS_DISABLED === true"
                            placeholder="우편번호"
                          ></v-text-field>
                          <!-- 조회 버튼 -->
                          <div class="ml-2 mr-2">
                            <v-btn
                              dark
                              small
                              class="btn-main-search pa-0"
                              min-width="32px"
                              height="32px"
                              plain
                              :disabled="CUS_DISABLED === true"
                            >
                              <v-icon
                                small
                                class="svg-main-searh white"
                              ></v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <v-textarea
                          class="ml-2"
                          no-resize
                          outlined
                          rows="2"
                          hide-details
                          placeholder="기본주소"
                          :readonly="CUS_DISABLED === true"
                          v-model="CUS_OWNHOM_ADR"
                        ></v-textarea>
                        <v-text-field
                          class="form-inp full pt-0 ml-2"
                          outlined
                          hide-details
                          :readonly="CUS_DISABLED === true"
                          placeholder="상세주소"
                          v-model="CUS_OWNHOM_DETAIL_ADR"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">등급 (VIP , B/L)</span>
                      <div class="form-inp full pa-0 ml-2">
                        <v-select
                          :items="BL_DROPITEMS"
                          item-text="icon"
                          outlined
                          placeholder="등급 (VIP , B/L)"
                          hide-details
                          :readonly="CUS_DISABLED === true"
                          v-model="CUS_ATENT_CD"  
                        >
                          <template slot="selection" slot-scope="data">
                            <v-icon :class="[data.item.icon]"></v-icon>
                            <div
                              class="v-select__selection v-select__selection--comma"
                            >
                              {{ data.item.title }}
                            </div>
                          </template>
                          <template slot="item" slot-scope="data">
                            <v-icon :class="[data.item.icon]"></v-icon>
                            <div
                              class="v-list-item__title"
                              style="flex-basis: auto"
                            >
                              {{ data.item.title }}
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4"
                        >최근 상담일시</span
                      >
                      <v-text-field
                        class="form-inp free pt-0 ml-2"
                        outlined
                        hide-details
                        :readonly="CUS_DISABLED === true"
                        v-model="CUS_CNSL_EOT_DATE" 
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4"
                        >최근 상담내용</span
                      >
                      <v-textarea
                        class="ml-2"
                        no-resize
                        outlined
                        rows="3"
                        hide-details
                        :readonly="CUS_DISABLED === true"
                        v-model="CUS_CNSL_CNTN"
                      ></v-textarea>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">관리메모</span>
                      <v-textarea
                        class="ml-2"
                        no-resize
                        outlined
                        rows="3"
                        hide-details
                        :readonly="CUS_DISABLED === true"
                        v-model="CUS_CUSTOMER_MEMO"
                      ></v-textarea>
                    </div>
                  </div>
                  <!-- 전화상담 고객정보 종료 Arthur.Kim -->
                </div>
              </v-tab-item>
              <!-- 고객상담이력 컨탠츠 -->
              <v-tab-item>
                <div class="layout-chatmain--cs-info-ct">
                  <div class="d-flex align-center gray-box">
                    <div class="form-inp free pa-0">
                      <v-select
                        :items="CHANNEL_DROPITEMS"
                        :item-value="CHANNEL_DROPITEMS.value"
                        :item-text="CHANNEL_DROPITEMS.text"
                        outlined
                        placeholder="채널유형"
                        hide-details
                        v-model="CNSL_CHANNEL_TY_SCH"
                      ></v-select>
                    </div>
                    <div class="form-inp free pa-0 ml-2">
                      <v-select
                        :items="PROC_DROPITEMS"
                        :item-value="PROC_DROPITEMS.value"
                        :item-text="PROC_DROPITEMS.text"
                        outlined
                        placeholder="상담결과"
                        hide-details
                        v-model="CNSL_PROC_CODE_LCLS_SCH"
                      ></v-select>
                    </div>
                    <!-- 조회 버튼 -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          dark
                          small
                          class="btn-main-search pa-0 ml-2"
                          min-width="32px"
                          height="32px"
                          plain
                          @click="csDetailSearch = true"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="CUS_DISABLED === true"
                        >
                          <v-icon small class="svg-main-search2"></v-icon>
                        </v-btn>
                      </template>
                      <span>상세조회</span>
                    </v-tooltip>
                    <!-- 새로고침 버튼 -->
                    <v-btn
                      dark
                      small
                      class="btn-main-refresh pa-0 ml-2"
                      min-width="32px"
                      height="32px"
                      plain
                      :disabled="CUS_DISABLED === true"
                    >
                      <v-icon small class="svg-main-refresh"></v-icon>
                    </v-btn>
                  </div>
                  <!-- 상세조회 슬라이드-->
                  <div
                    class="csDetailSearch"
                    :class="{ active: csDetailSearch == true }"
                  >
                    <h2 class="tit-h2 d-flex">
                      <div class="ml-auto align-self-center">
                        <v-icon
                          x-small
                          class="svg-close20"
                          @click="csDetailSearch = false"
                        ></v-icon>
                      </div>
                    </h2>
                    <div>
                      <div>
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          content-class="calendar-modal"
                          color="#FFF"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateRangeText"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                              outlined
                              hide-details
                              class="form-inp full icon-calendar"
                              append-icon="svg-calendar"
                            ></v-text-field>
                          </template>
                          <div class="datepicker-range">
                            <v-date-picker
                              v-model="dates[0]"
                              no-title
                              dark
                              color="#F7AFAE"
                              @change="startDate"
                              :first-day-of-week="1"
                              locale="ko-KR"
                              :day-format="mixin_getDate"
                            ></v-date-picker>
                            <v-date-picker
                              v-model="dates[1]"
                              dark
                              color="#F7AFAE"
                              no-title
                              show-current="false"
                              :min="dates[0]"
                              @change="endDate"
                              :first-day-of-week="1"
                              locale="ko-KR"
                              :day-format="mixin_getDate"
                            ></v-date-picker>
                          </div>
                          <div class="text-center pt-3 pb-3">
                            <v-btn
                              outlined
                              class="btn-default"
                              @click="menu2 = false"
                              >확인</v-btn
                            >
                          </div>
                        </v-menu>
                      </div>
                      <div class="d-flex align-center mt-2">
                        <div class="form-inp full pa-0">
                          <v-select
                            :items="CHANNEL_DROPITEMS"
                            :item-value="CHANNEL_DROPITEMS.value"
                            :item-text="CHANNEL_DROPITEMS.text"
                            outlined
                            hide-details
                            label="채널유형"
                            v-model="CNSL_CHANNEL_TY_DTLSCH"
                          ></v-select>
                        </div>
                        <div class="form-inp full pa-0 ml-2">
                          <!--
                          <v-select
                            :items="BL_DROPITEMS"
                            item-text="icon"
                            outlined
                            hide-details
                            label="B/L"
                            v-model="CNSL_ATENT_CD_DTLSCH"
                          ></v-select>
                          -->
                           <v-select
                            :items="BL_DROPITEMS"
                            item-text="icon"
                            :item-value="BL_DROPITEMS.gbn"
                            outlined
                            placeholder="B/L"
                            hide-details
                            v-model="CNSL_ATENT_CD_DTLSCH"
                          >
                            <template slot="selection" slot-scope="data">
                              <v-icon :class="[data.item.icon]"></v-icon>
                              <div
                                class="v-select__selection v-select__selection--comma"
                              >
                                {{ data.item.title }}
                              </div>
                            </template>
                            <template slot="item" slot-scope="data">
                              <v-icon :class="[data.item.icon]"></v-icon>
                              <div class="v-list-item__title" style="flex-basis: auto">
                                {{ data.item.title }}
                              </div>
                            </template>
                          </v-select>
                        </div>
                      </div>
                      <div class="d-flex align-center mt-2">
                        <v-text-field
                          class="form-inp full pa-0"
                          name="name"
                          placeholder="업체명"
                          outlined
                          hide-details=""
                          v-model="CNSL_ASP_CUST_NM_DTLSCH"
                        ></v-text-field>
                        <div class="form-inp full pa-0 ml-2">
                          <v-select
                            :items="PROC_DROPITEMS"
                            :item-value="PROC_DROPITEMS.value"
                            :item-text="PROC_DROPITEMS.text"
                            outlined
                            hide-details
                            label="상담결과"
                            v-model="CNSL_PROC_CODE_LCLS_DTLSCH"
                          ></v-select>
                        </div>
                      </div>
                      <div class="d-flex align-center mt-2">
                        <div class="form-inp full pa-0">
                          <v-text-field
                            class="form-inp full pa-0"
                            name="name"
                            placeholder="상담사"
                            outlined
                            hide-details=""
                            v-model="CNSL_REG_MAN_DTLSCH"
                          ></v-text-field>
                        </div>
                        <div class="form-inp full pa-0 ml-2">
                          <v-select
                            :items="CNSL_CALL_TY_RADIOGRP"
                            :item-value="CNSL_CALL_TY_RADIOGRP.value"
                            :item-text="CNSL_CALL_TY_RADIOGRP.text"
                            outlined
                            hide-details
                            label="유형"
                            v-model="CNSL_CALL_TY_DTLSCH"
                          ></v-select>
                        </div>
                      </div>
                      <div class="mt-2 text-right">
                        <v-btn outlined small class="btn-etc2 ml-2">조회</v-btn>
                      </div>
                    </div>
                  </div>
                  <!-- //상세조회 -->
                  <!-- 상담이력 목록 -->
                  <div class="layout-chatmain--cs-info-history-wrap">
                    <div
                      class="layout-chatmain--cs-info-history"
                      :class="{ active: item.expand === true }"
                      v-for="item in CALL_CNSL_LST"
                      :key="item.id"
                    >
                      <div
                        class="layout-chatmain--cs-info-title"
                        @click="chatmainCsToggle(item)"
                      >
                        <v-icon :class="item.icon"></v-icon>
                        <span
                          class="layout-chatmain--cs-info-history-result"
                          :class="[item.resultCode]"
                          >{{ item.result }}</span
                        >
                        <span class="layout-chatmain--cs-info-history-date">{{
                          item.date
                        }}</span>
                        <span
                          class="layout-chatmain--cs-info-history-manager"
                          >{{ item.manager }}</span
                        >
                        <span class="layout-chatmain--cs-info-history-category">
                          {{ item.category01.title }} &gt;
                          {{ item.category02.title }} &gt;
                          {{ item.category03.title }} &gt;
                          {{ item.category04.title }}
                        </span>
                      </div>
                      <div class="layout-chatmain--cs-info-detail">
                        <div class="d-flex align-center mt-2">
                          <div class="form-inp full pa-0">
                            <v-select
                              outlined
                              :items="item.channel"
                              item-value="id"
                              item-text="title"
                              hide-details
                              label="채널유형"
                            ></v-select>
                          </div>
                          <div class="form-inp full pa-0 ml-2">
                            <v-select
                              outlined
                              :items="item.bl"
                              item-value="id"
                              item-text="title"
                              hide-details
                              label="B/L"
                            ></v-select>
                          </div>
                        </div>
                        <div class="d-flex align-center mt-2">
                          <div class="form-inp full pa-0">
                            <v-text-field
                              class="form-inp full pa-0"
                              name="name"
                              placeholder="업체명"
                              outlined
                              hide-details=""
                            ></v-text-field>
                          </div>
                          <div class="form-inp full pa-0 ml-2">
                            <v-select
                              outlined
                              :items="item.csResult"
                              item-value="id"
                              item-text="title"
                              hide-details
                              label="상담결과"
                            ></v-select>
                          </div>
                        </div>
                        <div class="d-flex align-center mt-2">
                          <div class="form-inp full pa-0">
                            <v-text-field
                              class="form-inp full pa-0"
                              name="name"
                              placeholder="상담사"
                              outlined
                              hide-details=""
                            ></v-text-field>
                          </div>
                          <div class="form-inp full pa-0 ml-2">
                            <v-select
                              outlined
                              :items="item.category"
                              item-value="id"
                              item-text="title"
                              hide-details
                              label="유형"
                            ></v-select>
                          </div>
                        </div>
                        <div class="d-flex mt-3 pt-3 bd-top">
                          <div class="form-inp full pa-0">
                            <v-select
                              outlined
                              v-model="item.category01.id"
                              :items="item.category"
                              item-value="id"
                              item-text="title"
                              hide-details
                            ></v-select>
                          </div>
                        </div>
                        <div class="d-flex mt-2">
                          <div class="form-inp full pa-0">
                            <v-select
                              outlined
                              v-model="item.category02.id"
                              :items="item.category"
                              item-value="id"
                              item-text="title"
                              hide-details
                            ></v-select>
                          </div>
                        </div>
                        <div class="d-flex mt-2">
                          <div class="form-inp full pa-0">
                            <v-select
                              outlined
                              v-model="item.category03.id"
                              :items="item.category"
                              item-value="id"
                              item-text="title"
                              hide-details
                            ></v-select>
                          </div>
                        </div>
                        <div class="d-flex align-center mt-3 pt-3 bd-top">
                          <v-textarea
                            class=""
                            no-resize
                            outlined
                            rows="1"
                            background-color="white"
                            hide-details
                            :value="item.csMemo"
                          ></v-textarea>
                        </div>
                        <div class="d-flex align-center mt-2">
                          <v-textarea
                            class=""
                            no-resize
                            outlined
                            rows="3"
                            background-color="white"
                            hide-details
                            :value="item.textarea"
                          ></v-textarea>
                        </div>
                        <div class="d-flex align-center mt-2">
                          <audio
                            class="layout-chatmain--cs-audio"
                            controls
                            :src="item.audio"
                          >
                            Your browser does not support the
                            <code>audio</code> element.
                          </audio>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
          <!--// 고객정보, 고객상담이력-->
        </div>
      </div>
      <!--// 콜상담 -->
      <!-- //mark:상담현황 -->
      <div class="layout-chatmain--cs layout-chatmain-box">
        <div class="layout-chatmain--cs-title">
          <h2 class="tit-h2">상담현황</h2>
          <v-btn v-on:click="test">고객등급조회이벤트</v-btn>
          <v-btn v-on:click="test2">전화걸기</v-btn>
          <v-btn v-on:click="test3">전화받기</v-btn>
        </div>
        <div class="layout-chatmain--cs-ct">
          <!-- 상담대기 -->
          <div class="layout-chatmain--cs-ct--tit type01">
            상담대기
            <span class="clr-point ml-1">{{ chatWaitList.length }}</span>
            <div class="ml-auto">
              <div class="form-inp pt-0" style="width: 95px">
                <v-select
                  :items="mixin_common_code_get(this.commonCodeLst, 'TALK030_NOCACHE', '채팅OFF')"
                  v-model="dropItemsUserStatCd"
                  outlined
                  placeholder="선택하세요"
                  hide-details
                  v-on:change="changeUserStat(true)"
                ></v-select>
              </div>
            </div>
          </div>
          <div class="layout-chatmain--cs-ct--box">
            <div
              class="layout-chatmain--cs-ct--talk"
              v-for="(item, index) in chatWaitList"
              :key="index"
              v-on:click="clickChatWait(index)"
            >
              <v-icon :class="'svg-chatmain-' + item.CHN_ICON"></v-icon>
              <div class="layout-chatmain--cs-ct--stat ml-2">
                {{ item.ASP_SENDER_KEY_NM }}
              </div>
              <!--
              <div class="layout-chatmain--cs-ct--ci">
                <img :src="item.ci" :alt="item.alt"></div>
              -->
              <div class="ml-auto my-1 d-flex align-center flex-column">
                <div class="layout-chatmain--cs-ct--time">
                  {{ item.TALK_DIST_DT }}분
                </div>
                <div class="layout-chatmain--cs-ct--cnt mt-1">
                  {{ item.CNT }}
                </div>
              </div>
            </div>
          </div>
          <!-- 상담목록 -->
          <div class="layout-chatmain--cs-ct--tit type02">
            <span
              class="layout-chatmain--cs-ct--tit-in"
              :class="{ active: csStatList === true }"
              @click="
                csStatList = !csStatList;
                csStatChat = false;
              "
              >상담목록</span
            >
            <span
              class="layout-chatmain--cs-ct--tit-in ml-2"
              :class="{ active: csStatChat === true }"
              @click="
                csStatChat = !csStatChat;
                csStatList = false;
              "
              >챗예약
              <span class="clr-point ml-1">{{ chatListReseve.length }}</span>
            </span>
            <div class="ml-auto">
              <!-- 새로고침 버튼 -->
              <v-btn
                dark
                small
                class="btn-main-refresh pa-0 ml-2"
                min-width="32px"
                height="32px"
                plain
                v-on:click="getTalkList"
              >
                <v-icon small class="svg-main-refresh"></v-icon>
              </v-btn>
              <!-- 필터 버튼 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    small
                    class="btn-main-refresh pa-0 ml-2"
                    min-width="32px"
                    height="32px"
                    plain
                    v-bind="attrs"
                    v-on="on"
                    @click="chatFilter = !chatFilter"
                  >
                    <v-icon small class="svg-main-filter"></v-icon>
                  </v-btn>
                </template>
                <span>필터</span>
              </v-tooltip>
            </div>
          </div>
          <!-- 필터 show-->
          <div class="layout-chatmain--cs-filter" v-show="chatFilter === true">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              dark
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  hide-details
                  class="form-inp sm icon-calendar pt-0"
                  append-icon="svg-calendar"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                color="#F7AFAE"
                no-title
                @input="menu2 = false"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
            </v-menu>
            <div class="form-inp sm pt-0 ml-2" style="width: 92px">
              <v-select
                :items="mixin_common_code_get(this.commonCodeLst, 'TALK006','')"
                outlined
                placeholder="채널"
                hide-details
                v-model="TALK_NEWSTAT"
              ></v-select>
            </div>
          </div>
          <!--// 필터 show-->
          <div class="layout-chatmain--cs-ct--btn">
            <v-btn-toggle v-model="toggle_csCtBtn">
              <v-btn class="cs-ct-btn" @click="FnChatListAll"
                >전체
                <span class="cs-cti-btn--cnt">{{
                  chatList.length
                }}</span></v-btn
              >
              <v-btn class="cs-ct-btn" @click="FnChatListIng"
                >상담중
                <span class="cs-cti-btn--cnt">{{
                  chatListIng.length
                }}</span></v-btn
              >
              <v-btn class="cs-ct-btn" @click="FnChatListDone"
                >완료
                <span class="cs-cti-btn--cnt">{{
                  chatListDone.length
                }}</span></v-btn
              >
              <v-btn class="cs-ct-btn" @click="FnChatListAfter"
                >후처리
                <span class="cs-cti-btn--cnt">{{
                  chatListAfter.length
                }}</span></v-btn
              >
              <v-btn class="cs-ct-btn" @click="FnChatListBookmark"
                >책갈피
                <span class="cs-cti-btn--cnt">{{
                  chatListBookmark.length
                }}</span></v-btn
              >
            </v-btn-toggle>
          </div>
          <div
            class="layout-chatmain--cs-ct--box type02"
            :class="[chatListFilter, chatFilter === true ? 'type03' : '']"
          >
            <div
              class="layout-chatmain--cs-ct--talk"
              :class="`type-${item.type} ${
                item.countdown === true ? 'countdown' : ''
              }`"
              v-for="(item, index) in chatList"
              :key="index"
              v-show="chatListFilter === 'all' || chatListFilter === item.type || chatListFilter === item.bookmark"
              v-on:click="clickChat(index)"
            >
              <v-icon :class="'svg-chatmain-' + item.CHN_ICON"></v-icon>
              <div class="layout-chatmain--cs-ct--stat ml-2">
                {{ item.ASP_SENDER_KEY_NM }}
              </div>
              <!--
              <div class="layout-chatmain--cs-ct--ci">
                <img :src="item.ci" :alt="item.alt"></div>
              -->
              <div class="ml-auto d-flex align-center flex-column">
                <div
                  class="layout-chatmain--cs-ct--stat"
                  :class="{ statChat: item.statChat === true }"
                >
                  {{ item.TALK_STAT_NM }}
                </div>
                <div class="layout-chatmain--cs-ct--time">
                  {{ item.TALK_DIST_DT }}
                </div>
              </div>
              <div class="d-flex">
                <div class="layout-chatmain--cs-ct--text">
                  {{ item.TALK_TITLE }}
                </div>
                <div class="layout-chatmain--cs-ct--cnt mt-1">
                  {{ item.CNT }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--// 상담현황 -->
      <!--//mark: 챗상담 메인-->
      <div class="layout-chatmain--talk">
        <!-- top -->
        <div class="layout-chatmain--call-top layout-chatmain-box">
          <div class="layout-chatmain--call-top--info type02">
            <v-icon
              :class="chatTop.channel === true ? 'svg-main-chat-channel' : ''"
            ></v-icon>
            <div class="layout-chatmain--call-top--ci">
              <img :src="chatTop.img" :alt="chatTop.imgAlt" />
            </div>
            <div class="layout-chatmain--call-top--cs">
              <span class="layout-chatmain--call-top--name">{{ USER_NM }}</span>
              <span class="layout-chatmain--call-top--phone">{{
                USER_TEL_NO
              }}</span>
            </div>
          </div>
          <div class="d-flex ml-auto align-center">
            <v-btn
              outlined
              small
              class="btn-etc3 ml-2"
              @click="showAlertBookmark"
            >
              <v-icon small class="mr-2">mdi-bookmark-outline</v-icon>
              책갈피
            </v-btn>
            <!-- 새로고침 버튼 -->
            <v-btn
              dark
              small
              class="btn-main-refresh pa-0 ml-2"
              min-width="32px"
              height="32px"
              plain
            >
              <v-icon small class="svg-main-refresh"></v-icon>
            </v-btn>
            <!-- 수정/저장 버튼 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  small
                  class="btn-main-refresh pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  plain
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small class="svg-main-down"></v-icon>
                </v-btn>
              </template>
              <span>수정/저장</span>
            </v-tooltip>
          </div>
        </div>
        <!--// top -->
        <div class="layout-chatmain--chat-wrap">
          <!--//mark: 채팅창 -->
          <div class="layout-chatmain--chat-history-wrap">
            <div class="layout-chatmain--chat-history layout-chatmain-box">
              <div
                class="layout-chatmain--chat-history-box"
                v-scroll.self="onScroll"
                :class="{
                  fz10: chatHistoryTextsize == '100',
                  fz12: chatHistoryTextsize == '120',
                  fz14: chatHistoryTextsize == '140',
                }"
              >
                <div class="text-center mt-5 pr-0">
                  <v-btn outlined x-small class="btn-round">이전대화내역</v-btn>
                </div>
                <template v-for="(item, index) in talkHistory">
                  <div
                    :class="
                      item.SNDRCV_CD == 'RCV'
                        ? 'layout-chatmain--chat-history--cs-msg'
                        : 'layout-chatmain--chat-history--manager-msg'
                    "
                    :key="index"
                  >
                    <img v-if="item.TYPE=='IM' || 'photo'" :src="item.IMAGE_URL">
                    <div v-if="item.TYPE!='IM'">
                      {{ item.CONTENT }}
                    </div>
                    <div v-if="item.TYPE!='IM'" class="layout-chatmain--chat-history--time">
                      {{ item.CHAT_DATE }}
                    </div>
                  </div>
                </template>
                <!-- 맨위로 -->
                <v-btn
                  icon
                  class="btn-to-top"
                  :class="scroll > 0 ? 'active' : null"
                  @click="scrlToTop"
                  ><v-icon class="svg-to-top"></v-icon
                ></v-btn>
              </div>
            </div>
            <!-- 버튼 -->
            <div class="layout-chatmain--chat-history-btn">
              <v-btn outlined class="btn-default" @click="chatTemp = !chatTemp"
                :disabled="CANT_MODI"
                >임시저장</v-btn
              >
              <v-btn
                outlined
                class="btn-etc ml-2"
                @click="chatDone = !chatDone"
              >
                상담종료
              </v-btn>
            </div>
            <!-- 입력 form -->
            <div class="layout-chatmain--chat-history--form">
              <v-textarea
                label=""
                no-resize
                outlined
                placeholder="내용을 입력하세요"
                background-color="white"
                dense
                class="layout-chatmain--chat-history--form-textarea"
                v-model.trim="CHAT_MSG"
                v-on:keyup.enter="sendMsg"
                :disabled="CANT_MODI"
              ></v-textarea>
            </div>
            <!-- 기능 버튼 -->
            <div class="layout-chatmain--chat-history--func">
              <div>
                <!-- 이미지 -->
                <v-btn
                  dark
                  small
                  class="btn-main-chatfunc pa-0"
                  min-width="32px"
                  height="32px"
                  plain
                  v-on:click="showDialogImg('M110102P01')"
                  :disabled="CANT_MODI"
                >
                  <v-icon small class="svg-main-chatfunc-img"></v-icon>
                </v-btn>
                <!-- 시스템메시지중단 -->
                <v-btn
                  dark
                  small
                  class="btn-main-chatfunc pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  plain
                  v-on:click="SystemMsgSkip"
                  :disabled="CANT_MODI"
                >
                  <v-icon small class="svg-main-chatfunc-stop"></v-icon>
                </v-btn>
                <!-- 종료링크전송 -->
                <v-btn
                  dark
                  small
                  class="btn-main-chatfunc pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  plain
                  v-on:click="sendEndLink"
                  :disabled="CANT_MODI"
                >
                  <v-icon small class="svg-main-chatfunc-send"></v-icon>
                </v-btn>
                <!-- 챗 이관 -->
                <v-btn
                  dark
                  small
                  class="btn-main-chatfunc pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  plain
                  @click="[getUserSelect(), (chatTrans = !chatTrans)]"
                  :disabled="CANT_MODI"
                >
                  <v-icon small class="svg-main-chatfunc-trans"></v-icon>
                </v-btn>
                <!-- 챗 콜백 -->
                <v-btn
                  dark
                  small
                  class="btn-main-chatfunc pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  plain
                  @click="chatCallback = !chatCallback"
                  :disabled="CANT_MODI"
                >
                  <v-icon small class="svg-main-chatfunc-reserve"></v-icon>
                </v-btn>
                <!-- 글자크기 -->
                <v-btn
                  dark
                  small
                  class="btn-main-chatfunc pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  plain
                  @click="chatFontsize = !chatFontsize"
                  :disabled="CANT_MODI"
                >
                  <v-icon small class="svg-main-chatfunc-size"></v-icon>
                </v-btn>
                <!-- 모달 : 글자크기  -->
                <div
                  class="layout-chatmain--chat-history--func-size"
                  :class="{ active: chatFontsize == true }"
                >
                  <div class="form-inp pa-0 full">
                    <v-select
                      :items="dropItemTextsize"
                      item-text="title"
                      item-value="size"
                      width="120px"
                      outlined
                      placeholder="글자크기선택"
                      @input="FnChatHistoryTextsize"
                      hide-details
                    >
                      <template slot="selection" slot-scope="data">
                        <div
                          class="v-select__selection v-select__selection--comma"
                        >
                          {{ data.item.title }}
                        </div>
                      </template>
                      <template slot="item" slot-scope="data">
                        <div
                          class="v-list-item__title"
                          style="flex-basis: auto"
                        >
                          {{ data.item.title }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
              <!-- 전송 -->
              <v-btn outlined class="btn-point ml-auto" v-on:click="sendMsg"
                :disabled="CANT_MODI"
              >
                전송
              </v-btn>
            </div>
            <!-- //mark: 채팅 임시저장 바텀시트 -->
            <div
              class="layout-chatmain--chatDone"
              :class="{ active: chatTemp == true }"
            >
              <h2 class="tit-h2 mt-3 type02">임시저장</h2>
              <div class="mt-3">
                <div class="d-flex justify-space-between align-center">
                  <div class="form-inp full pa-0">
                    <v-select
                      :items="mixin_common_code_get(this.commonCodeLst, 'TALK005','')" 
                      v-model="CHT_NEWRESULT"
                      outlined
                      hide-details
                    >
                      <template v-slot:label>
                        상담결과
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      outlined
                      placeholder="상담유형1"
                      hide-details
                      :items="CNSL_TY1"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY1"
                      v-on:change="chnCnsl1()"
                    >
                      <template v-slot:label>
                        상담유형1
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      outlined
                      placeholder="상담유형2"
                      hide-details
                      :items="CNSL_TY2"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY2"
                      v-on:change="chnCnsl2()"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      outlined
                      placeholder="상담유형3"
                      hide-details
                      :items="CNSL_TY3"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY3"
                      v-on:change="chnCnsl3()"
                    >
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      outlined
                      placeholder="상담유형4"
                      hide-details
                      :items="CNSL_TY4"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY4"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="mt-2">
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    label="고객명"
                    id="id"
                    outlined
                    v-model="CHT_TIT"
                    hide-details
                  >
                    <template v-slot:label>
                      상담제목
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-text-field>
                  <v-textarea
                    label=""
                    no-resize
                    outlined
                    rows="5"
                    hide-details
                    placeholder="상담내용"
                    v-model="CHT_CON"
                  >
                    <template v-slot:label>
                      상담내용
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-textarea>
                </div>
                <div class="text-right mt-2">
                  <v-btn
                    outlined
                    class="btn-default"
                    v-on:click="[(chatTemp = false), initCnsl()]"
                  >
                    취소
                  </v-btn>
                  <v-btn
                    outlined
                    class="btn-point ml-2"
                    v-on:click="confirmSave('', '상담을 임시저장하시겠습니까?')"
                  >
                    저장
                  </v-btn>
                </div>
              </div>
            </div>
            <!--// 채팅 임시저장 바텀시트 -->
            <!-- //mark: 채팅 상담완료 바텀시트 -->
            <div
              class="layout-chatmain--chatDone"
              :class="{ active: chatDone == true }"
            >
              <h2 class="tit-h2 mt-3 type02">상담완료</h2>
              <div class="mt-3">
                <div class="d-flex justify-space-between align-center">
                  <div class="form-inp full pa-0">
                    <v-select
                      :items="mixin_common_code_get(this.commonCodeLst, 'TALK005','')" 
                      v-model="CHT_NEWRESULT"
                      outlined
                      hide-details
                    >
                      <template v-slot:label>
                        상담결과
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      outlined
                      placeholder="상담유형1"
                      hide-details
                      :items="CNSL_TY1"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY1"
                      v-on:change="chnCnsl1()"
                    >
                      <template v-slot:label>
                        상담유형1
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      outlined
                      placeholder="상담유형2"
                      hide-details
                      :items="CNSL_TY2"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY2"
                      v-on:change="chnCnsl2()"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      outlined
                      placeholder="상담유형3"
                      hide-details
                      :items="CNSL_TY3"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY3"
                      v-on:change="chnCnsl3()"
                    >
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      outlined
                      placeholder="상담유형4"
                      hide-details
                      :items="CNSL_TY4"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY4"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="mt-2">
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    label="고객명"
                    id="id"
                    outlined
                    v-model="CHT_TIT"
                    hide-details
                  >
                    <template v-slot:label>
                      상담제목
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-text-field>
                  <v-textarea
                    label=""
                    no-resize
                    outlined
                    rows="5"
                    hide-details
                    placeholder="상담내용"
                    v-model="CHT_CON"
                  >
                    <template v-slot:label>
                      상담내용
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-textarea>
                </div>
                <div class="text-right mt-2">
                  <v-btn
                    outlined
                    class="btn-default"
                    v-on:click="[(chatDone = false), initCnsl()]"
                  >
                    취소
                  </v-btn>
                  <v-btn
                    outlined
                    class="btn-point ml-2"
                    v-on:click="
                      confirmSave('3', '상담이력을 저장하시겠습니까?')
                    "
                  >
                    저장
                  </v-btn>
                </div>
              </div>
            </div>
            <!--// 채팅 상담완료 바텀시트 -->

            <!-- //mark: 채팅 전달 바텀시트 -->
            <div
              class="layout-chatmain--chatCallback"
              :class="{ active: chatTrans == true }"
            >
              <h2 class="tit-h2 mt-3 type02">전달</h2>
              <div class="mt-3">
                <div class="d-flex align-center">
                  <div class="form-inp full pt-0">
                    <v-select
                      v-model="USER_NEWID"
                      :items="USER_ID"
                      no-data-text="일치하는 데이터가 없습니다."
                      item-text="USER_ID"
                      item-value="USER_ID"
                    >
                      <template v-slot:label>
                        상담사선택
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      outlined
                      placeholder="상담유형1"
                      hide-details
                      :items="CNSL_TY1"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY1"
                      v-on:change="chnCnsl1()"
                    >
                      <template v-slot:label>
                        상담유형1
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      outlined
                      placeholder="상담유형2"
                      hide-details
                      :items="CNSL_TY2"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY2"
                      v-on:change="chnCnsl2()"
                    >
                      <template v-slot:label>
                        상담유형2
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      outlined
                      placeholder="상담유형3"
                      hide-details
                      :items="CNSL_TY3"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY3"
                      v-on:change="chnCnsl3()"
                    >
                      <template v-slot:label>
                        상담유형3
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      outlined
                      placeholder="상담유형4"
                      hide-details
                      :items="CNSL_TY4"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY4"
                    >
                      <template v-slot:label>
                        상담유형4
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="mt-2">
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    label="고객명"
                    id="id"
                    outlined
                    v-model="CHT_TIT"
                    hide-details
                  >
                    <template v-slot:label>
                      상담제목
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-text-field>
                  <v-textarea
                    label=""
                    no-resize
                    outlined
                    rows="5"
                    hide-details
                    placeholder="상담내용"
                    v-model="CHT_CON"
                  >
                    <template v-slot:label>
                      상담내용
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-textarea>
                </div>
                <div class="text-right mt-2">
                  <v-btn
                    outlined
                    class="btn-default"
                    @click="[(chatTrans = false), initCnsl()]"
                  >
                    취소
                  </v-btn>
                  <v-btn
                    outlined
                    class="btn-point ml-2"
                    v-on:click="confirmTrans"
                  >
                    전달
                  </v-btn>
                </div>
              </div>
            </div>
            <!-- //mark: 채팅 전달 바텀시트 -->

            <!-- //mark: 채팅 콜백 바텀시트 -->
            <div
              class="layout-chatmain--chatCallback"
              :class="{ active: chatCallback == true }"
            >
              <h2 class="tit-h2 mt-3 type02">콜백</h2>
              <div class="mt-3">
                <div class="d-flex align-center">
                  <div class="form-inp full pt-0">
                    <v-select
                      :items="CHT_RESULT"
                      item-text="CD_NM"
                      item-value="CD"
                      v-model="CHT_NEWRESULT"
                      outlined
                      hide-details
                      placeholder="선택하세요"
                    >
                      <template v-slot:label>
                        상담결과
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <v-checkbox
                    :on-icon="'svg-chk-on'"
                    :off-icon="'svg-chk-off'"
                    label="콜백예약시간"
                    hide-details
                  ></v-checkbox>
                  <v-menu
                    v-model="chatCalendar2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    color="#FFF"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateFormatted2"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp icon-calendar pt-0 ml-2"
                        append-icon="svg-calendar"
                      ></v-text-field>
                    </template>
                    <div class="d-flex align-center">
                      <v-date-picker
                        v-model="date"
                        dark
                        color="#F7AFAE"
                        no-title
                        show-current="false"
                        :first-day-of-week="1"
                        locale="ko-KR"
                        :day-format="mixin_getDate"
                      ></v-date-picker>
                      <v-time-picker
                        v-model="timeStep"
                        :allowed-minutes="allowedStep"
                        color="#F7AFAE"
                        format="24hr"
                      >
                      </v-time-picker>
                    </div>
                    <div class="text-center pt-3 pb-3">
                      <v-btn
                        outlined
                        small
                        class="btn-point ml-3"
                        @click="chatCalendar2 = false"
                        >확인</v-btn
                      >
                    </div>
                  </v-menu>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select outlined placeholder="콜백예약시간" hide-details>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select outlined placeholder="콜백예약분" hide-details>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      outlined
                      placeholder="상담유형1"
                      hide-details
                      :items="CNSL_TY1"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY1"
                      v-on:change="chnCnsl1()"
                    >
                      <template v-slot:label>
                        상담유형1
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      outlined
                      placeholder="상담유형2"
                      hide-details
                      :items="CNSL_TY2"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY2"
                      v-on:change="chnCnsl2()"
                    >
                      <template v-slot:label>
                        상담유형2
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      outlined
                      placeholder="상담유형3"
                      hide-details
                      :items="CNSL_TY3"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY3"
                      v-on:change="chnCnsl3()"
                    >
                      <template v-slot:label>
                        상담유형3
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      outlined
                      placeholder="상담유형4"
                      hide-details
                      :items="CNSL_TY4"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY4"
                    >
                      <template v-slot:label>
                        상담유형4
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="mt-2">
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    label="고객명"
                    id="id"
                    outlined
                    v-model="CHT_TIT"
                    hide-details
                  >
                    <template v-slot:label>
                      상담제목
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-text-field>
                  <v-textarea
                    label=""
                    no-resize
                    outlined
                    rows="5"
                    hide-details
                    placeholder="상담내용"
                    v-model="CHT_CON"
                  >
                    <template v-slot:label>
                      상담내용
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-textarea>
                </div>
                <div class="text-right mt-2">
                  <v-btn
                    outlined
                    class="btn-default"
                    @click="[(chatCallback = false), initCnsl()]"
                  >
                    취소
                  </v-btn>
                  <v-btn
                    outlined
                    class="btn-point ml-2"
                    v-on:click="confirmSave('4', '콜백을 저장하시겠습니까?')"
                  >
                    저장
                  </v-btn>
                </div>
              </div>
            </div>
            <!-- //mark: 채팅 콜백 바텀시트 -->

            <!-- //mark: 채팅 상담저장 바텀시트 -->
            <!--
            <div class="layout-chatmain--chatDone" :class="{ active : chatDone == true }">
              <h2 class="tit-h2 mt-3 type02">상담저장</h2>
              <div class="mt-3">
                <div class="d-flex align-center">
                  <v-radio-group v-model="chatRadioGroupSelected" row class="radio-inline">
                    <template v-slot:label >
                      <span class="in-label">채널유형</span>
                    </template>
                    <v-radio v-for="(rad01,index) in chatRadioGroup" :key="radioGroup[index].text" :label="rad01.text"
                      :on-icon="'svg-rad-on'"
                      :off-icon="'svg-rad-off'"
                      :value="rad01.text">
                    </v-radio>
                  </v-radio-group>
                  <div class="form-inp sm ml-auto pt-0">
                    <v-select :items="dropItems" item-text="icon" outlined placeholder="B/L" hide-details>
                      <template slot="selection" slot-scope="data">
                        <v-icon :class="[ data.item.icon ]"></v-icon>
                        <div class="v-select__selection v-select__selection--comma">
                          {{ data.item.title }}
                        </div>
                      </template>
                      <template slot="item" slot-scope="data">
                        <v-icon :class="[data.item.icon]"></v-icon>
                        <div class="v-list-item__title" style="flex-basis: auto">
                          {{ data.item.title }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <v-checkbox :on-icon="'svg-chk-on'" :off-icon="'svg-chk-off'" label="상담예약" hide-details></v-checkbox>
                  <v-menu v-model="chatCalendar" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto" content-class="calendar-modal" color="#FFF">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="computedDateFormatted2" v-bind="attrs" v-on="on" outlined hide-details
                        class="form-inp icon-calendar ml-2 pt-0" append-icon="svg-calendar"></v-text-field>
                    </template>
                    <div class="d-flex align-center">
                      <v-date-picker v-model="date" dark color="#F7AFAE" no-title show-current="false"
                        :first-day-of-week="1"
                        locale="ko-KR"
                        :day-format="mixin_getDate"
                      ></v-date-picker>
                      <v-time-picker v-model="timeStep" :allowed-minutes="allowedStep" color="#F7AFAE" format="24hr">
                      </v-time-picker>
                    </div>
                    <div class="text-center pt-3 pb-3">
                      <v-btn outlined small class="btn-point ml-3" @click="chatCalendar = false">확인</v-btn>
                    </div>
                  </v-menu>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select :items="dropItemsCompany" item-text="title" outlined placeholder="업체명" hide-details>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select  item-text="title" outlined placeholder="상담유형1" hide-details>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select :items="dropItemsResult" item-text="title" outlined placeholder="상담결과" hide-details>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select item-text="title" outlined placeholder="상담유형2" hide-details>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select :items="dropItemsCategory" item-text="title" outlined placeholder="유형" hide-details>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select item-text="title" outlined placeholder="상담유형3" hide-details>
                    </v-select>
                  </div>
                </div>
                <div class="mt-2">
                  <v-textarea label="" no-resize outlined rows="2" hide-details placeholder="관리메모"></v-textarea>
                </div>
                <div class="mt-2">
                  <v-textarea label="" no-resize outlined rows="5" hide-details placeholder="상담메모"></v-textarea>
                </div>
                <div class="text-right">
                  <v-btn outlined class="btn-point mt-2" @click="chatDone = false">
                    저장
                  </v-btn>
                </div>
              </div>


            </div>
            -->
            <!--// 채팅 상담저장 바텀시트 -->
          </div>
          <!--// 채팅창 -->
          <!-- //mark: 챗 상담정보 -->
          <div class="layout-chatmain--chat-info layout-chatmain-box">
            <v-tabs class="tabs-form" slider-color="#6C6E7A" slider-size="3">
              <v-tab>고객정보</v-tab>
              <v-tab>고객상담이력</v-tab>
              <!-- 고객정보 컨텐츠 -->
              <v-tab-item>
                <div class="layout-chatmain--cs-info-ct">
                  <div class="align-center gray-box">
                    <div class="d-flex">
                      <v-select
                        class="form-inp free pt-0"
                        :items="ASP_CUST_KEY"
                        item-value="CD"
                        item-text="CD_NM"
                        outlined
                        hide-details
                        label="업체명"
                        placeholder="선택하세요"
                        v-model="SRH_ASP_CUST"
                        style="width: 198px"
                        :disabled="CANT_CUST"
                      >
                        <template v-slot:label>
                          업체명
                          <v-icon color="#EF9191">mdi-circle-small</v-icon>
                        </template>
                      </v-select>
                      <v-text-field
                        class="form-inp free ml-2 pt-0"
                        name="name"
                        label="전화번호 "
                        outlined
                        hide-details
                        v-model="SRH_PHONE_NUM"
                      >
                      </v-text-field>
                    </div>
                    <div class="d-flex mt-2">
                      <v-text-field
                        class="form-inp free pt-0 flex-grow-0"
                        name="name"
                        label="고객명 "
                        outlined
                        hide-details
                        style="width: 118px"
                        v-model="SRH_CUST_NM"
                      >
                      </v-text-field>
                      <div class="ml-2 align-self-center mr-0">
                        <!-- 조회 버튼 -->
                        <v-btn
                          dark
                          small
                          class="btn-main-search pa-0"
                          min-width="32px"
                          height="32px"
                          plain
                          @click="[btnCust(CNSL_WK[1]),showDialog('MP01')]"
                        >
                          <v-icon small class="svg-main-searh white"></v-icon>
                        </v-btn>
                        <!-- 수정/저장 버튼 -->
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              dark
                              small
                              class="btn-main-refresh pa-0 ml-2"
                              min-width="32px"
                              height="32px"
                              plain
                              v-bind="attrs"
                              v-on="on"
                              v-on:click="showAlertReg"
                              :disabled="CLK_CUST === '' ? true : false"
                            >
                              <v-icon small class="svg-main-down"></v-icon>
                            </v-btn>
                          </template>
                          <span>수정/저장</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <!-- 조회모달 MP01로 변경
                    <v-dialog
                      max-width="810"
                      persistent
                      v-model="dialogM110101P02"
                      content-class="square-modal maincalltrans-modal"
                    >
                      <dialog-M110101P02
                        headerTitle="고객정보조회"
                        @hide="hideDialog('M110101P02')"
                      >
                      </dialog-M110101P02>
                    </v-dialog>
                    -->
                  </div>
                  <div class="mt-3 layout-chatmain--cs-info-ct-form">
                    <div class="d-flex align-stretch">
                      <span class="in-label text-left col-4">업체명 </span>
                      <v-text-field
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        value=""
                        outlined
                        hide-details
                        readonly
                        v-model="CLK_CUST"
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4"> 고객명 </span>
                      <v-text-field
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        value=""
                        outlined
                        hide-details
                        :readonly="chatmainChatEdit === true"
                        v-model="USER_NM"
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">
                        <v-badge dot color="#EF9191">Key 값</v-badge>
                      </span>
                      <v-text-field
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        value="N/A"
                        outlined
                        hide-details
                        v-model="CLK_USER"
                        readonly
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4"> 전화번호1 </span>
                      <v-text-field
                        v-model="USER_TEL_NO"
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        value=""
                        outlined
                        hide-details
                        :readonly="chatmainChatEdit === true"
                      >
                      </v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">전화번호2 </span>
                      <v-text-field
                        v-model="USER_TEL_NO"
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        outlined
                        hide-details
                        :readonly="chatmainChatEdit === true"
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">이메일</span>
                      <v-text-field
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        value="palette@hkcloud.co.kr "
                        outlined
                        hide-details
                        v-model="EMAIL"
                        :readonly="chatmainChatEdit === true"
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">주소</span>
                      <div class="d-flex flex-wrap">
                        <div class="d-flex col-12 pa-0">
                          <v-text-field
                            class="form-inp full pt-0 ml-2"
                            name="name"
                            outlined
                            hide-details
                            :readonly="chatmainChatEdit === true"
                            placeholder="우편번호"
                            v-model="postcode"
                          ></v-text-field>
                          <!-- 조회 버튼 -->
                          <div class="ml-2 mr-2">
                            <v-btn
                              dark
                              small
                              class="btn-main-search pa-0"
                              min-width="32px"
                              height="32px"
                              plain
                              v-on:click="execDaumPostcode()"
                              :disabled="CLK_CUST === '' ? true : false"
                            >
                              <v-icon
                                small
                                class="svg-main-searh white"
                              ></v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <v-textarea
                          class="ml-2"
                          no-resize
                          outlined
                          rows="2"
                          hide-details
                          :readonly="chatmainChatEdit === true"
                          v-model="address"
                        ></v-textarea>
                        <v-text-field
                          class="form-inp full pt-0 ml-2"
                          name="name"
                          outlined
                          hide-details
                          :readonly="chatmainChatEdit === true"
                          placeholder="상세주소"
                          v-model="detailAddress"
                        ></v-text-field>
                        <v-text-field
                          class="form-inp full pt-0 ml-2"
                          name="name"
                          outlined
                          hide-details
                          :readonly="chatmainChatEdit === true"
                          placeholder="참고항목"
                          v-model="extraAddress"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">등급 (VIP , B/L)</span>
                      <div class="form-inp full pa-0 ml-2">
                        <v-select
                          :items="USER_BL"
                          item-text="icon"
                          item-value="value"
                          outlined
                          placeholder="등급 (VIP , B/L)"
                          hide-details
                          :readonly="chatmainChatEdit === true"
                          v-model="USER_NEWBL"
                        >
                          <template slot="selection" slot-scope="data">
                            <v-icon :class="[data.item.icon]"></v-icon>
                            <div
                              class="v-select__selection v-select__selection--comma"
                            >
                              {{ data.item.title }}
                            </div>
                          </template>
                          <template slot="item" slot-scope="data">
                            <v-icon :class="[data.item.icon]"></v-icon>
                            <div
                              class="v-list-item__title"
                              style="flex-basis: auto"
                            >
                              {{ data.item.title }}
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4"
                        >최근 상담일시</span
                      >
                      <v-text-field
                        v-model="RCT_CHAT_DATE"
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        value="2022-02-23 14:02"
                        outlined
                        hide-details
                        readonly
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4"
                        >최근 상담내용</span
                      >
                      <v-textarea
                        v-model="RCT_CHAT_CON"
                        class="ml-2"
                        no-resize
                        outlined
                        rows="3"
                        hide-details
                        value=""
                        readonly
                      ></v-textarea>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">관리메모</span>
                      <v-textarea
                        v-model="USER_MEMO"
                        class="ml-2"
                        no-resize
                        outlined
                        rows="3"
                        hide-details
                        value=""
                        :readonly="chatmainChatEdit === true"
                      ></v-textarea>
                    </div>
                  </div>
                </div>
              </v-tab-item>
              <!-- 고객상담이력 컨탠츠 -->
              <v-tab-item>
                <div class="layout-chatmain--cs-info-ct">
                  <div class="d-flex align-center gray-box">
                    <div class="form-inp free pa-0">
                      <v-select 
                        :items="mixin_common_code_get(this.commonCodeLst, 'PLT002','')" 
                        outlined 
                        placeholder="채널유형" 
                        hide-details
                        v-model="dropItemChatCnslChannel">
                      </v-select>
                    </div>
                    <div class="form-inp free pa-0 ml-2">
                      <v-select 
                        :items="mixin_common_code_get(this.commonCodeLst, 'TALK004','')" 
                        outlined placeholder="상담결과" 
                        hide-details
                        v-model="dropItemChatCnslProc">
                      </v-select>
                    </div>
                    <!-- 조회 버튼 -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn dark small class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain
                          @click="csDetailSearch2 = true"
                          v-bind="attrs"
                          v-on="on">
                          <v-icon small class="svg-main-search2"></v-icon>
                        </v-btn>
                      </template>
                      <span>상세조회</span>
                    </v-tooltip>
                    <!-- 새로고침 버튼 -->
                    <v-btn
                      dark
                      small
                      class="btn-main-refresh pa-0 ml-2"
                      min-width="32px"
                      height="32px"
                      plain
                      v-on:click="getTalkHist('', '')"
                    >
                      <v-icon small class="svg-main-refresh"></v-icon>
                    </v-btn>
                  </div>
                  <!-- 상세조회 슬라이드-->
                  <div
                    class="csDetailSearch"
                    :class="{ active: csDetailSearch2 == true }"
                  >
                    <h2 class="tit-h2 d-flex">
                      <div class="ml-auto align-self-center">
                        <v-icon
                          x-small
                          class="svg-close20"
                          @click="csDetailSearch2 = false"
                        ></v-icon>
                      </div>
                    </h2>
                    <div>
                      <div>
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          content-class="calendar-modal"
                          color="#FFF"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateRangeText"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                              outlined
                              hide-details
                              class="form-inp full icon-calendar"
                              append-icon="svg-calendar"
                            ></v-text-field>
                          </template>
                          <div class="datepicker-range">
                            <v-date-picker
                              v-model="dates[0]"
                              no-title
                              dark
                              color="#F7AFAE"
                              @change="startDate"
                              :first-day-of-week="1"
                              locale="ko-KR"
                              :day-format="mixin_getDate"
                            ></v-date-picker>
                            <v-date-picker
                              v-model="dates[1]"
                              dark
                              color="#F7AFAE"
                              no-title
                              show-current="false"
                              :min="dates[0]"
                              @change="endDate"
                              :first-day-of-week="1"
                              locale="ko-KR"
                              :day-format="mixin_getDate"
                            ></v-date-picker>
                          </div>
                          <div class="text-center pt-3 pb-3">
                            <v-btn
                              outlined
                              class="btn-default"
                              @click="menu2 = false"
                              >확인</v-btn
                            >
                          </div>
                        </v-menu>
                      </div>
                      <div class="d-flex align-center mt-2">
                        <div class="form-inp full pa-0">
                          <v-select 
                            :items="mixin_common_code_get(this.commonCodeLst, 'PLT002','')" 
                            outlined 
                            placeholder="채널유형" 
                            hide-details
                            v-model="dropItemDtlChatCnslChannel">
                          </v-select>
                        </div>
                        <div class="form-inp full pa-0 ml-2">
                          <v-select
                            outlined
                            item-value="id"
                            item-text="title"
                            hide-details
                            label="B/L"
                          ></v-select>
                        </div>
                      </div>
                      <div class="d-flex align-center mt-2">
                        <v-text-field
                          class="form-inp full pa-0"
                          name="name"
                          placeholder="업체명"
                          outlined
                          hide-details=""
                        ></v-text-field>
                        <div class="form-inp full pa-0 ml-2">
                          <v-select 
                            :items="mixin_common_code_get(this.commonCodeLst, 'PLT024','')" 
                            outlined 
                            placeholder="상담결과" 
                            hide-details
                            v-model="dropItemDtlChatCnslProc">
                          </v-select>
                        </div>
                      </div>
                      <div class="d-flex align-center mt-2">
                        <div class="form-inp full pa-0">
                          <v-text-field
                            class="form-inp full pa-0"
                            name="name"
                            placeholder="상담사"
                            outlined
                            hide-details=""
                          ></v-text-field>
                        </div>
                        <div class="form-inp full pa-0 ml-2">
                          <v-select
                            outlined
                            item-value="id"
                            item-text="title"
                            hide-details
                            label="유형"
                          ></v-select>
                        </div>
                      </div>
                      <div class="mt-2 text-right">
                        <v-btn outlined small class="btn-etc2 ml-2">조회</v-btn>
                      </div>
                    </div>
                  </div>
                  <!-- //상세조회 -->
                  <!-- 상담이력 목록 -->
                  <div class="layout-chatmain--cs-info-history-wrap">
                    <div
                      class="layout-chatmain--cs-info-history"
                      :class="{ active: item.EXPAND }"
                      v-for="item in chatHistory"
                      :key="item.ID"
                    >
                      <div
                        class="layout-chatmain--cs-info-title"
                        @click="chatmainCsToggle(item)"
                      >
                        <v-icon :class="item.ICON"></v-icon>
                        <span
                          class="layout-chatmain--cs-info-history-result"
                          :class="[item.PROC_CODE]"
                          >{{ item.PROC_CODE_NM }}</span
                        >
                        <span class="layout-chatmain--cs-info-history-date">{{
                          item.REG_DTTM
                        }}</span>
                        <span
                          class="layout-chatmain--cs-info-history-manager ml-1"
                          >{{ item.USER_NM }}</span
                        >
                        <span class="layout-chatmain--cs-info-history-category">
                          {{ item.CNSL_TYP }} &gt;
                          {{ item.CNSL_TYP2 }}
                        </span>
                      </div>
                      <!-- 펼쳤을때 컨텐츠 -->
                      <div class="layout-chatmain--cs-info-detail">
                        <div class="layout-chatmain--cs-info-result">
                          처리결과 : {{ item.PROC_CODE_NM }}
                        </div>
                        <div class="d-flex align-center mt-2">
                          <v-textarea
                            class=""
                            no-resize
                            outlined
                            rows="3"
                            background-color="white"
                            hide-details
                            :value="item.HST_CONTEXT"
                          ></v-textarea>
                        </div>
                        <!-- 채팅창 불러오기 내역 -->
                        <div class="layout-chatmain--chat-history-wrap type02">
                          <div
                            class="layout-chatmain--chat-history layout-chatmain-box"
                          >
                            <div
                              class="layout-chatmain--chat-history-box"
                              v-scroll.self="onScroll"
                              :class="{
                                fz10: chatHistoryTextsize == '100',
                                fz12: chatHistoryTextsize == '120',
                                fz14: chatHistoryTextsize == '140',
                              }"
                            >
                              <template
                                v-for="(item, index) in talkHistList[
                                  item.ID - 1
                                ]"
                              >
                                <div
                                  :class="
                                    item.SNDRCV_CD == 'RCV'
                                      ? 'layout-chatmain--chat-history--cs-msg'
                                      : 'layout-chatmain--chat-history--manager-msg'
                                  "
                                  :key="index"
                                >
                                  <img v-if="item.TYPE=='IM'" :src="item.IMAGE_URL">
                                  <div v-if="item.TYPE!='IM'">
                                    {{ item.CONTENT }}
                                  </div>
                                  <div v-if="item.TYPE!='IM'" class="layout-chatmain--chat-history--time">
                                    {{ item.CHAT_DATE }}
                                  </div>
                                </div>
                              </template>
                            </div>
                            <div class="text-center mt-5">
                              <v-btn outlined x-small class="btn-round"
                                >상담이 종료되었습니다.</v-btn
                              >
                            </div>
                          </div>
                        </div>
                        <!--// 채팅창 불러오기 내역 -->
                      </div>
                    </div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
          <!--// 챗 상담정보 -->
        </div>
        <!-- 이미지 전송 모달창 -->
        <div class="">
          <v-dialog
            max-width="655"
            max-height="655"
            v-model="DialogM110102P01"
            content-class="square-modal min-auto"
          >
            <dialog-M110102P01
              headerTitle="이미지 전송"
              @hide="hideDialog('M110102P01')"
              :Imgdata="Imgdata"
              @input="Imgdata = $event"
              @selImg="getTalkInfo(), hideDialog('M110102P01')"
            >
            </dialog-M110102P01>
            <template v-slot:body> </template>
          </v-dialog>
        </div>
        <!-- 이미지 전송 모달창 -->
        
        <!-- 고객정보 모달창 -->
          <div>
            <v-dialog
              max-width="1400"
              max-height="1200"
              v-model="dialogMP01"
              content-class="square-modal"
            >
              <dialog-MP01
                headerTitle="고객정보"
                @hide="hideDialog('MP01')"
                :cusInfo="cusInfo"
                @input="cusInfo = $event"
                @selectCus="[hideDialog('MP01'), setCustData()]"
              ></dialog-MP01>
            </v-dialog>
          </div>
        <!-- 고객정보 모달창 -->
      </div>
      <!--// 챗상담 메인-->
    </div>
  </div>
</template>

<script>
// 호전환 모달
import DialogM110101P01 from "./M110101P01.vue"; //호전환
import DialogM110101P02 from "./M110101P02.vue"; //고객정보조회
import { mixin } from "@/mixin/mixin.js";
import api from "@/store/apiUtil.js";
import apiChat from "@/store/apiChatParamUtil.js";
import { apiCall } from "@/store/callUtil.js";
import DialogM110102P01 from "./M110102P01.vue";
import moment from "moment";
import Vue from "vue";
import io from "socket.io-client"; //socket.io 모듈 추가_김민진
import { mapGetters } from "vuex";

import alertStore from "@/store/modules/alertStore"; //알림
import { eventBus } from "@/store/eventBus.js"; //Event Bus
import { stompUtil } from "@/store/stomp-util.js";

import DialogMP01 from "./MP01.vue";

export default {
  name: "MENU_M110101", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogM110101P01,
    DialogM110101P02,
    DialogM110102P01,
    DialogMP01,
  },

  created() {

    //this.$socket.on('connect', ()=> {
    //  
    //  let ncmc_key = this.INLNE_NO;
    //
    //  console.log("))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))");
    //  console.log("this.INLNE_NO; : " + ncmc_key);
    //
    //  this.$socket.emit("guestjoin", "cloudnode", ncmc_key, "192.168.101.103");
    //  console.log("this.$socket : " + this.$socket);
    //}),
    //
    //this.$socket.on("softphone", function (msg) {
    //
    //    if (msg) {
    //      
    //      /* 구현부 */
    //      var arrMsg      = msg.split("|");
    //      var msg_evt     = arrMsg[0];          // 메시지 종류
    //      var telNo       = arrMsg[1];          // 전화번호
    //      var campCode    = arrMsg[2];          // 캠페인코드
    //      var today       = new Date();
    //      var year        = today.getFullYear();
    //      var month       = ("0" + (today.getMonth() + 1)).slice(-2);
    //      var day         = ("0" + today.getDate()).slice(-2);
    //      var dateString  = year + "-" + month + "-" + day;
    //      var hours       = ("0" + today.getHours()).slice(-2);
    //      var minutes     = ("0" + today.getMinutes()).slice(-2);
    //      var seconds     = ("0" + today.getSeconds()).slice(-2);
    //      var timeString  = hours + ":" + minutes + ":" + seconds;
    //      
    //      // ###############################################################################
    //      // API 팝업
    //      // 예시 : RINGING|01082055960|07045130953
    //      // ###############################################################################
    //      //if (msg_evt == "RINGING") {
    //      //  console.log("message : " + msg);
    //      //  console.log("telNo : " + telNo);
    //      //  tmpCallRing(telNo);
    //      //}
    //
    //      switch (msg_evt) {
    //
    //        case "RINGING" :      //전화벨 RINGING|07046495048|07080153021|1 (고객전화번호|대표번호|IVR디짓)
    //          break;
    //        
    //        case "ANSWER" :       //전화수신 통화시작 ANSWER|07080151167|20220608/1099-20220608-202826-IB-07080151167-1654687706.97
    //          break;
    //        
    //        case "HANGUP" :       //통화종료 
    //          break;
    //        
    //        case "OUTBOUND" :     //전화발신 통화시작  OUTBOUND|01082055960|20220608/1099-20220608-201711-OB-01082055960-1654687031.93
    //          break;
    //      }
    //    }
    //  });

    /****************************************************************************************************************************************************
     * 공통 변수 / 함수 정보 START
     ***************************************************************************************************************************************************/

      /** 현재시간 추출 (1/10000 초)  */
      setInterval(() => {
        this.nowTime = moment();
      }, 10000);

    /****************************************************************************************************************************************************
     * 공통 변수 / 함수 정보 END
     ****************************************************************************************************************************************************
     ***************************************************************************************************************************************************/

    /****************************************************************************************************************************************************
     * EventBus created START
     ***************************************************************************************************************************************************/

      /**
       * 로그인/아웃 CTI 배열 조정 
       */
      eventBus.$on("busInitCallCti", (e) => {

        let ctiBtnGbn    = "";                                //  CTI 버튼 배열 조정 값 

        let result = "";
        let desc   = "";
        let data   = "";

        result = e.result;
        desc   = e.desc;
        
        switch (desc) {
                
          case "db_login" :                                   // CTI 로그인 

            if (result == "true") {
              
              this.citHandleAuto();
              this.CTI_DB_STATE = "2";

              this.CTI_SOFTPHONE.url = this.CTI_SOFTPHONE.defaultUrl + "" + this.CTI_SOFTPHONE.pathUrl; // 소프트폰 직접 호출 주소 생성 

			      ctiBtnGbn = "on";                               //  CTI 버튼 배열 조정 값 

            } else {
              setTimeout(this.btnCtiLogin(), 3000);
            }

            break;

          case "db_logout" :                                  // CTI 로그아웃 

            if (result == "true") {
              ctiBtnGbn = "off";                              //  CTI 버튼 배열 조정 값 
            } else {
              setTimeout(this.btnCtiLogin(), 3000);
            }

            break;

          case "db_state" :                                   // IPCC 이벤트 결과 ((1:대기, 2:후처리, 이석_3:휴식, 이석_4:교육, 이석_5:식사, 이석_6:기타))

            if (result == "true") { 
                  
              data = e.data;

              /** CTI DB 상태값 */
              if (data != "") { 
                this.CTI_DB_STATE = data; 
              }

              /** CTI 대기버튼 */
              if (data == "1") {                              // 대기 
                
                this.CTI_BTN[2].text = "대기해제";
                ctiBtnGbn = "ready";                          //  CTI 버튼 배열 조정 값 

              } else if (data == "2") {                       // 후처리
                
                if (this.CTI_BTN[2].text == "대기해제") {
                  this.CTI_BTN[2].text = "대기";          
                }

                ctiBtnGbn = "handle";                         //  CTI 버튼 배열 조정 값 
              }
            }  
                
            break;
        }

        /** CTI 버튼 배열 조정 */
        if (result == "true") {
          this.initCallCti(ctiBtnGbn);
        }
         
      });

      /**
       * 이벤트 호출
       * Stomp Disconnect
       */
      eventBus.$on("busStompDisconnect", () => {
        this.disconnect();
      });

      /**
       * 상담원 대기 구독 메시지 인입
       */
      eventBus.$on("busReadyCallback", (message) => {
        this.readyCallback(message);
        this.getTalkList();
      });

      /**
       * 채팅 메시지 인입
       */
      eventBus.$on("busInoutCallback", (message) => {
        this.inoutCallback(message);
        this.getTalkList();
        this.getTalkInfo();
      });

      /**
        * 채팅 메시지 전송 
        */
      eventBus.$on('userchatMessage', (message) => {
        this.$store.dispatch("messages/AC_USER_CHAT_MESSAGE", message);
        //chatStore.userchatMessage(message);
      });

    /****************************************************************************************************************************************************
     * EventBus created START
     ****************************************************************************************************************************************************
     ***************************************************************************************************************************************************/

  },
  
  data() {
    return {

            // 콜관련공통코드_콜상담유형_상담통합이력 IN_상담통합이력 OB_B/L 구분_상담통합 상담결과, CTI경과시간알림, CTI 경과시간구분, CTI 상태 알림 상태값
      cmmnCd: ['PLT025', 'PLT003', 'PLT004','PLT006', "PLT024", "OU003", "OU005", "CRS022", "CRS047", "CRS048", "CRS049"],  
      cmmnCdLst: [],                                          // 콜관련공통코드_공통코드 조회 값 

      arrMsg: "",
      msg_evt: "",
      telNo: "",
      campCode: "",
      today: "",
      year: "",
      month: "",
      day: "",
      dateString: "",
      hours: "",
      minutes: "",
      seconds: "",
      timeString: "",
      el: "",
      callnum: "",
      testEx: "",
      testEx2: "",
      valueEx: "",
      telNob: "",

      dialogM110101P01: false, //호전환 모달
      dialogM110101P02: false, //고객정보 조회 모달
      dialogM110101P03: false, //고객정보 전화걸기 모달
      // dialogM110101P04: false, //cti 전화걸기 모달
      dialogM110101P05: false, //임시 전화 수신 모달
      DialogM110102P01: false,

      dialogMP01: false,      //고객정보모달
      cusInfo: {},            //고객정보 파라미터

      menu2: false,
      menu5: false,

      chatTop: {
        channel: true,
        img: require("@/assets/logo.png"),
        imgAlt: "기업명",
        name: "챗상담홍",
        phoneNumber: "000-0000-0000",
      },
      
      chatRadioGroup: [
        {
          text: "CHAT",
        },
      ],
      chatRadioGroupSelected: "CHAT",
      
      CNSL_WK: ['CALL', 'CHAT'],                // 상담구분_콜,챗
      BL_DROPITEMS : [],                        // B/L
      dropItems: [
        //{
        //  title: "B/L",
        //  icon: "svg-dot-green",
        //},
        //{
        //  title: "B/L",
        //  icon: "svg-dot-red",
        //},
        //{
        //  title: "B/L",
        //  icon: "svg-dot-orange",
        //},
      ],
      
      chatHistoryTextsize: 100,
      dropItemTextsize: [
        {
          title: "100%",
          size: 100,
        },
        {
          title: "120%",
          size: 120,
        },
        {
          title: "140%",
          size: 140,
        },
      ],
      
      PROC_DROPITEMS : [],       // 상담결과

      dropItemsChatChannel: [
        { title: "전체" },
        { title: "카카오톡" },
        { title: "채널톡" },
        { title: "라인" },
      ],

      //dropItemsCategory: [
      //  { title: "이관" },
      //  { title: "예약" },
      //  { title: "콜백" },
      //  { title: "켐페인" },
      //],
      
      chatCalendar: false,
      chatCalendar2: false,
      timeStep: "00:00",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      //chatmainCsEdit: true,
      chatmainChatEdit: true,
      chatmainCsPhone: [
        {
          title: "010-0000-0000",
        },
        {
          title: "010-1234-0000",
        },
      ],
      csDetailSearch: false,
      csDetailSearch2: false,
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      
      chatmainHistory2: [],

      // 상담현황
      chatWaitList: [],
      chatList: [],
      toggle_csCtBtn: 0,
      chatFilter: false,
      chatListFilter: "all",
      csStatList: true,
      csStatChat: false,
      // 채팅 상담창
      chatHistory: [],
      scroll: 0,
      scrollTarget: null,
      chatDone: false,
      chatTemp: false,
      chatTrans: false,
      chatCallback: false,
      chatFontsize: false,

      //220525 김태준 채팅 이벤트 추가
      //
      USER_STAT: [{ CD_NM: "채팅OFF", CD: "CHATOFF" }],
      USER_NEWSTAT: "CHATOFF",

      TALK_CHN: [{ CD_NM: "카카오톡", CD: "KAKAO" }],
      TALK_NEWCHN: "KAKAO",

      CNSL_TY: [{ CD: "", CD_NM: "전체" }],
      CNSL_NEWTY: "",

      TALK_STAT: [{ CD: "", CD_NM: "전체" }],
      TALK_NEWSTAT: "",

      // 상담현황
      // 채팅 상담창
      talkHistory: [],

      CUST: this.fullCust(),

      ChatListIng: "",
      ChatListDone: "",
      ChatListAfter: "",
      ChatListWait: "",

      CLK_CUST: "",
      CLK_CHAT: "",
      CLK_USER: "",
      CLK_SENDER: "",
      CLK_MSGSKIP: "",
      CLK_STAT: "",
      CLK_ICON: "",
      CLK_IMG: "",
      MARK_UP:"",

      CHT_RESULT: [],
      CHT_NEWRESULT: "",

      USER_NM: "",
      USER_TEL_NO: "",
      CHK_BL: false,
      USER_MEMO: "",

      talkHistList: [],

      CNSL_DIV: [
        { title: "전체", value: "" },
        { title: "대분류", value: "1" },
        { title: "중분류", value: "2" },
        { title: "소분류", value: "3" },
      ],

      CNSL_NEWDIV: "",
      SEARCH_CONT: "",

      Imgdata: {},

      USER_ID: [],
      USER_NEWID: "",

      CNSL_TY1: [],
      CNSL_NEWTY1: "",
      CNSL_TY2: [],
      CNSL_NEWTY2: "",
      CNSL_TY3: [],
      CNSL_NEWTY3: "",
      CNSL_TY4: [],
      CNSL_NEWTY4: "",
      CHT_TIT: "",
      CHT_CON: "",

      MSG: "",

      EXEC_RST_CD: "",

      postcode: "",
      address: "",
      detailAddress: "",
      extraAddress: "",
      //
      //220525 김태준 채팅 이벤트 추가


      /****************************************************************************************************************************************************
       * 콜 변수 / 함수 정보 START 
       ***************************************************************************************************************************************************/

      callCalendar: false,

      //ncmc_key: this.INLNE_NO,
      socket: "",
      ip_address: "",

      IPCC_SOCKET_URL: process.env.VUE_APP_IPCC_SOCKET_URL,     //IPCC 소켓 연결 주소
      SECURE_MODE : false,                                      // SSL option
      PING_INTERVAL: 1000 * 10,
      PING_TIMEOUT: 1000 * 60 * 60 * 3,                         // 스크린세이버 대응 : 3시간
      
      CTI_HOME_BOOLEAN  : false,                                // 재택여부
      CTI_LOGIN_BOOLEAN : false,                                // CTI 로그인 여부      
      CTI_CALL_TIMER: '',                                       // CTI 타이머                                   
      CTI_TIMER_ALAM : [],                                      // CTI 타이머 알람시간
      CTI_ALAM_STATE : [],                                      // CTI 타이머 알람 상태값
      CTI_ALAM_TEXT : [],                                       // CTI 상태 알림 상태값
      
      CALL_CNSL_TYP_CD : [],                                    // 상담유형코드
      CALL_CNSL_TYP_CD_2 : [],                                  // 상담유형코드2
      CALL_CNSL_TYP_CD_3 : [],                                  // 상담유형코드3

      CALL_TY_DROPITEMS : [],                                   // 상담유형

      PROC_OB_DROPITEMS : [],                                   // 캠페인/콜백/예약콜 처리구분      
      FISH_OB_DROPITEMS : [],                                   // 캠페인/콜백 처리결과 

      PROC_TY_DROPITEMS : [],                                   // 캠페인 처리구분      
      FISH_TY_DROPITEMS : [],                                   // 캠페인 처리결과 
      EOT_TY_RLT_DROPITEMS : [],                                // 콜백 처리구분    
      PROC_CODE_DROPITEMS : [],                                 // 콜백 처리결과
      FISH_YN_DROPITEMS : [],                                   // 예약 처리결과 

      CHANNEL_DROPITEMS : [],                                   // 채녈유형 PLT002
      
      CALL_CNSL_LST: [
        {
          id: 1,
          expand: false,
          icon: "svg-chatmain-calltype01",                      // 수신
          result: "완료",
          resultCode: "done",
          date: "2020-00-00",
          manager: "상담사",
          channel: "채널유형",
          bl: "bl",
          company: "업체명",
          csResult: "상담결과",
          csMemo: "관리메모 관리메모 관리메모 관리메모 ",
          category: [
            { id: 1, title: "선택된 유형1" },
            { id: 2, title: "선택된 유형2" },
            { id: 3, title: "선택된 유형3" },
            { id: 4, title: "선택된 유형4" },
          ],
          category01: { id: 1, title: "선택된 유형1" },
          category02: { id: 2, title: "선택된 유형2" },
          category03: { id: 3, title: "선택된 유형3" },
          category04: { id: 4, title: "선택된 유형4" },
          textarea:
            "상담내용 길다. 상담내용 길다. 상담내용 길다. 상담내용 길다. ",
          audio: require("@/assets/@sample.mp3"),
        },
        {
          id: 2,
          expand: false,
          icon: "svg-chatmain-calltype02", //발신
          result: "미완료",
          resultCode: "undone",
          date: "2020-00-00",
          manager: "상담사",
          category: [
            { id: 1, title: "선택된 유형1" },
            { id: 2, title: "선택된 유형2" },
            { id: 3, title: "선택된 유형3" },
            { id: 4, title: "선택된 유형4" },
          ],
          category01: { id: 1, title: "선택된 유형1" },
          category02: { id: 2, title: "선택된 유형2" },
          category03: { id: 3, title: "선택된 유형3" },
          category04: { id: 4, title: "선택된 유형4" },
          textarea:
            "상담내용 길다. 상담내용 길다. 상담내용 길다. 상담내용 길다. ",
          audio: require("@/assets/@sample.mp3"),
        },
        {
          id: 3,
          expand: false,
          icon: "svg-chatmain-calltype03", //챗
          result: "처리중",
          resultCode: "ing",
          date: "2020-00-00",
          manager: "상담사",
          category: [
            { id: 1, title: "선택된 유형1" },
            { id: 2, title: "선택된 유형2" },
            { id: 3, title: "선택된 유형3" },
            { id: 4, title: "선택된 유형4" },
          ],
          category01: { id: 1, title: "선택된 유형1" },
          category02: { id: 2, title: "선택된 유형2" },
          category03: { id: 3, title: "선택된 유형3" },
          category04: { id: 4, title: "선택된 유형4" },
          textarea:
            "상담내용 길다. 상담내용 길다. 상담내용 길다. 상담내용 길다. ",
          audio: require("@/assets/@sample.mp3"),
        },
        {
          id: 4,
          expand: false,
          icon: "svg-chatmain-calltype01",
          result: "완료",
          resultCode: "done",
          date: "2020-00-00",
          manager: "상담사",
          category: [
            { id: 1, title: "선택된 유형1" },
            { id: 2, title: "선택된 유형2" },
            { id: 3, title: "선택된 유형3" },
            { id: 4, title: "선택된 유형4" },
          ],
          category01: { id: 1, title: "선택된 유형1" },
          category02: { id: 2, title: "선택된 유형2" },
          category03: { id: 3, title: "선택된 유형3" },
          category04: { id: 4, title: "선택된 유형4" },
          textarea:
            "상담내용 길다. 상담내용 길다. 상담내용 길다. 상담내용 길다. ",
          audio: require("@/assets/@sample.mp3"),
        },
      ],

      /**
       * 아웃바운드 세팅 정보
       */
      CALL_OUT_BOUND : {
        QUEST_ID : ""	                  // 설문번호
        ,CUST_NO : ""	                  // 고객번호
        ,CUST_NM : ""	                  // 고객명
        ,CAM_NM  : ""	                  // 캠페인이름
        ,CAM_ID  : ""	                  // 캠페인ID
        ,CENT_TY : ""                   // 센터구분
      },

      /**
       * 콜백세팅정보
       */
      CALL_CALL_BACK: {
        CLBK_NO : ""                    //콜백 ID
      },

      /**
       * 예약콜 세팅정보
       */
      CALL_RESV_CALL : {
        RESV_SEQ : ""	                  // 예약콜ID
        ,FISH_YN : ""	                  // 승인여부
        ,TRY_CNT : ""	                  // 시도횟수
      },

      CTI_BRD: {
        isBtnActive: false,
        expand: false,
        menuActive: null,
        current: null,
        lapseTime: "00:00:00",
        icon: null,
        ctiTimeLapse: false,
        ctiTextLapse: false,
      },

      CTI_BRD_MOVE: {
        status: undefined,
        current: '이석',
        icon: 'cti-btn-icon08',
      },

      /**
       * CTI Button 배열 
       */
      CTI_BTN: [
        {
          icon: "cti-btn-mute",
          text: "음소거",
          disalb: true,
          desc: "mute",
          cls : ''                    //사용자 정의 클래스
        },
        {
          icon: "cti-btn-hold",
          text: "보류",
          disalb: true,
          desc: "hold",
          cls:''                    //사용자 정의 클래스
        },
        {
          icon: "cti-btn-ready",
          text: "대기",
          disalb: true,
          desc: "ready",
          cls:''                    //사용자 정의 클래스
        },
        {
          icon: "cti-btn-hang",
          text: "끊기",
          disalb: true,
          desc: "hang",
          cls:''                    //사용자 정의 클래스
        },
        {
          icon: "cti-btn-call",
          text: "걸기",
          evt: true,
          evtId: "M110101P03",
          disalb: true,
          desc: "call",
          cls:''                    //사용자 정의 클래스
        },
        {
          icon: "cti-btn-tran",
          text: "호전환",
          evt: true,
          evtId: "M110101P01",
          disalb: true,
          desc: "tran",
          cls:''                    //사용자 정의 클래스
        },
        {
          icon: "cti-btn-handle",
          text: "후처리",
          disalb: true,
          desc: "handle",
          cls:''                    //사용자 정의 클래스
        },
        {
          icon: 'cti-btn-reason',
          text: '이석',
          evt: true,
          restEvt: true,
          restIcon: null,
          refIcon: true,
          refText: true,
          disalb: true,
          desc: "reason",
          cls:''                    //사용자 정의 클래스
        }
      ],

      /**
       * CTI 버튼 활성화 여부
       * 통화중_icon01, 보류_icon02, 대기_icon03, 끊기_icon04, 걸기_icon07, 호전환_icon05, 후처리_icon06, 이석_icon08
       */
      CTI_BTN_DISABLED : {
        
        //ctiOff: [false, false, false, false, false, false, false, false],         // CTI OFF 
        //ctiOn: [false, false, true, false, true, false, false, true],             // CTI ON
        //ctiRinging: [false, false, false, false, false, false, false, false],     // CTI IN/OUT 전화벨  
        //ctiAnswer: [true, true, false, true, false, true, false, false],          // CTI  ANSWER / OUTBOUND 
        //ctiHangup: [false, false, false, false, false, true, true, false],        // CTI IN/OUT 통화종료

        ctiOff: [                                   // CTI OFF
          {desc:"mute", bol:true},                  // 음소거
          {desc:"hold", bol:true},                  // 보류
          {desc:"ready", bol:true},                 // 대기
          {desc:"hang", bol:true},                  // 끊기
          {desc:"call", bol:true},                  // 걸기  
          {desc:"tran", bol:true},                  // 호전환
          {desc:"handle", bol:true},                // 후처리
          {desc:"reason", bol:true}                 // 이석
        ],
        ctiOn: [                                    // CTI ON
          {desc:"mute", bol:true},                  // 음소거
          {desc:"hold", bol:true},                  // 보류
          {desc:"ready", bol:false},                // 대기
          {desc:"hang", bol:true},                  // 끊기
          {desc:"call", bol:false},                 // 걸기  
          {desc:"tran", bol:true},                  // 호전환
          {desc:"handle", bol:false},               // 후처리
          {desc:"reason", bol:false}                // 이석
        ],
        ctiReady: [                                 // CTI READY
          {desc:"mute", bol:true},                  // 음소거
          {desc:"hold", bol:true},                  // 보류
          {desc:"ready", bol:false},                // 대기
          {desc:"hang", bol:true},                  // 끊기
          {desc:"call", bol:true},                  // 걸기  
          {desc:"tran", bol:true},                  // 호전환
          {desc:"handle", bol:true},                // 후처리
          {desc:"reason", bol:true}                 // 이석
        ],
        ctiRinging: [                               // CTI IN/OUT 전화벨 
          {desc:"mute", bol:true},                  // 음소거
          {desc:"hold", bol:true},                  // 보류
          {desc:"ready", bol:true},                 // 대기
          {desc:"hang", bol:false},                 // 끊기
          {desc:"call", bol:true},                  // 걸기  
          {desc:"tran", bol:true},                  // 호전환
          {desc:"handle", bol:true},                // 후처리
          {desc:"reason", bol:true}                 // 이석
        ],
        ctiAnswer: [                                // CTI  ANSWER / OUTBOUND 
          {desc:"mute", bol:false},                 // 음소거
          {desc:"hold", bol:false},                 // 보류
          {desc:"ready", bol:true},                 // 대기
          {desc:"hang", bol:false},                 // 끊기
          {desc:"call", bol:true},                  // 걸기  
          {desc:"tran", bol:false},                 // 호전환
          {desc:"handle", bol:true},                // 후처리
          {desc:"reason", bol:true}                 // 이석
        ],
        ctiHangup: [                                // CTI IN/OUT 통화종료
          {desc:"mute", bol:true},                  // 음소거
          {desc:"hold", bol:true},                  // 보류
          {desc:"ready", bol:true},                 // 대기
          {desc:"hang", bol:true},                  // 끊기
          {desc:"call", bol:true},                  // 걸기  
          {desc:"tran", bol:true},                  // 호전환
          {desc:"handle", bol:false},               // 후처리
          {desc:"reason", bol:true}                 // 이석
        ]
        
      },

      /**
       * CTI 이석 버튼 배열  1:대기, 2:후처리, 3:휴식, 4:교육, 5:식사, 6:기타
       */
      CTI_REASON: [
        {
          className: "cti-btn-expand-icon04",
          text: "휴식",
          dbState: "3"
        },
        {
          className: "cti-btn-expand-icon02",
          text: "교육",
          dbState: "4"
        },
        {
          className: "cti-btn-expand-icon03",
          text: "식사",
          dbState: "5"
        },
        {
          className: "cti-btn-expand-icon01",
          text: "기타",
          dbState: "6"
        },
        {
          className: "cti-btn-expand-icon05",
          text: "이석해제",
          dbState: "99"
        }
      ],

      CTI_DB_STATE : "",                              //CTI 상세 이벤트_1:대기, 2:후처리, 3:휴식, 4:교육, 5:식사, 6:기타 

      /**
       * 수신전화 모달
       */
      CTI_TAKE_CALL: {
        ci: require("@/assets/@call-ci.png"),
        company: "",                                  // 고객사명
        phone: "",                                    // 010-0000-0000
        text: "",                                     // 업무구분  : IVR시나리오
      },

      /**
       * 발신전화 모달
       */
      CTI_SEND_CALL: {
        ci: require("@/assets/@call-ci.png"),
        first: "",                                    // 대표번호
        phone: "010-0000-0000",                       // 010-0000-0000
        text: "업무구분  : IVR시나리오",              // 업무구분  : IVR시나리오
      },

      /**
       * 콜상담 상단 고객정보 표시 
       */
      CTI_CALL_STATUS: {
        incall: true,
        img: require("@/assets/logo.png"),
        imgAlt: "",                                   // 기업명
        name: "",                                     // 고객명 홍홍홍
        phoneNumber: "",                              // 000-0000-0000
        phoneMaskNumber: "",                          // 000-****-0000
        mask: false,
      },

      CTI_SOFTPHONE : {                               // 소프트폰 팝빌 전화기 
        url : "",
        defaultUrl: "http://admin:meta12!!@",
        pathUrl : "/cgi-bin/ConfigManApp.com?key="
      },

      //radioGroup: [],                               // 상담이력 콜 유형
      //radioGroupSelected: "",                       // 상담이력 콜 유형 기본값

      CNSL_DISABLED : true,                           // 콜상담저장_Disabled
      CNSL_CALL_TY_RADIOGRP : [],                     // 콜상담저장_콜유형 
      
      CNSL_CALL_TY : "",                              // 콜상담저장_콜유형 선택값 
      CNSL_ATENT_CUSTOMER : "",                       // 콜상담저장_주의고객
      CNSL_CNSL_TYP_CD : "",                          // 콜상담저장_상담유형
      CNSL_CNSL_TYP_CD_2 : "",                        // 콜상담저장_상담유형2
      CNSL_CNSL_TYP_CD_3 : "",                        // 콜상담저장_상담유형3
      CNSL_RESVE_CHK : "",                            // 콜상담저장_상담예약 체크 여부 
      CNSL_ASP_CUST_KEY : "",                         // 콜상담저장_업체명
      CNSL_PROC_CODE_LCLS : "",                       // 콜상담저장_상담결과 
      CNSL_CALL_TY_DTL : "",                          // 콜상담저장_콜유형_OB 상세 유형
      CNSL_PROC : "",                                 // 콜상담저장_캠페인/콜백/예약콜 처리구분
      CNSL_FISH : "",                                 // 콜상담저장_캠페인/콜백 처리결과 
      CNSL_CUSTOMER_MEMO : "",                        // 콜상담저장_관리메모 
      CNSL_CNTN : "",                                 // 콜상담저장_상담내용

      CUS_DISABLED : true,                            // 고객정보__Disabled  
      CUS_PHONE_DISABLED : true,                      // 고객정보_전화번호1 걸기/복사하기

      SCH_ASP_CUST_KEY : "",                          // 고객정보_고객검색_업체명
      SCH_CUSTOMER_PHONE_NO : "",                     // 고객정보_고객검색_전화번호 
      SCH_CUSTOMER_NM : "",				                    // 고객정보_고객검색_고객명 

      CUS_CUSTOMER_ID : "",                           // 고객정보_고객 ID
      CUS_ASP_CUST_NM : "",	                          // 고객정보_업체명 
      CUS_CUSTOMER_NM : "",	                          // 고객정보_고객명 
      CUS_TALK_USER_KEY : "",                         // 고객정보_Key 
      
      CUS_CUSTOMER_PHONE_NO : "",                     // 고객정보_전화번호 1
      CUS_CUSTOMER_PHONE_NO_ENC : "",                 // 고객정보_전화번호 1 암호화
      CUS_WRC_TEL_NO  : "",				                    // 고객정보_전화번호 2 
      CUS_WRC_TEL_NO_ENC : "",                        // 고객정보_전화번호 2 암호화

      CUS_EMAIL : "",			                            // 고객정보_이메일 
      CUS_OWNHOM_ADR : "",	                          // 고객정보_주소 
      CUS_OWNHOM_DETAIL_ADR : "",                     // 고객정보_상세주소
      CUS_ATENT_CD : "",                              // 고객정보_주의고객코드 (B/L)
      CUS_CNSL_EOT_DATE : "",                         // 고객정보_최근 상담일시
      CUS_CNSL_CNTN : "",                             // 고객정보_상담정보 
      CUS_CUSTOMER_MEMO : "",                         // 고객정보_관리메모 

      CNSL_CHANNEL_TY_SCH : "",                       // 고객정보_고객상담이력_검색_채녈유형
      CNSL_PROC_CODE_LCLS_SCH : "",                   // 고객정보_고객상담이력_검색_상담결과 

      CNSL_CHANNEL_TY_DTLSCH : "",		                // 고객정보_고객상담이력_상세검색_채널유형
      CNSL_ATENT_CD_DTLSCH : "",                      // 고객정보_고객상담이력_상세검색_주의고객
      CNSL_ASP_CUST_NM_DTLSCH : "",                   // 고객정보_고객상담이력_상세검색_업체명 
      CNSL_PROC_CODE_LCLS_DTLSCH : "",                // 고객정보_고객상담이력_상세검색_상담결과 
      CNSL_REG_MAN_DTLSCH : "",                       // 고객정보_고객상담이력_상세검색_상담사 
      CNSL_CALL_TY_DTLSCH : "",                       // 고객정보_고객상담이력_상세검색_유형 (OB)

      /****************************************************************************************************************************************************
       * 콜 변수 / 함수 정보 END
       ***************************************************************************************************************************************************/
    

      /****************************************************************************************************************************************************
         * 공통 변수 / 함수 정보 START 
         ***************************************************************************************************************************************************/
        
        nowTime : moment(),                                                                           // 현재시간 
        
        commonCode: ["TALK004","TALK006","TALK030_NOCACHE","PLT002","PLT024","TALK004","TALK037","PLT001","TALK005"],    //공통코드 PLT002_상담통합이력콜유형, PLT024_상담통합 상담결과  
        commonCodeLst : [],     
        
        baseSocketUrl: process.env.VUE_APP_SOCKET_URL,                                  //소켓 연결 주소
        
        /****************************************************************************************************************************************************
         * 공통 변수 / 함수 정보 END 
         ****************************************************************************************************************************************************
         ***************************************************************************************************************************************************/

        /****************************************************************************************************************************************************
         * 챗 변수 / 함수 정보 START
         ***************************************************************************************************************************************************/
        
        chatCustInfo : '',                    // 챗 고객정보 
        dropItemsUserStatCd : '',             //챗 상담사 상태값

        dropItemChatCnslChannel: "",              //챗_상담이력_채널유형
        dropItemChatCnslProc: "",                 //챗_상담이력_상담유형
        dropItemDtlChatCnslChannel:"",            //챗_상담이력_상세_채널유형 
        dropItemDtlChatCnslProc:"",               //챗_상담이력_상세_상담결과(상담유형) 

        // 챗_상담이력_Arthur.Kim
        chatCnsl: [
          //{
          //  id: 1,
          //  expand: false,
          //  icon: 'svg-chatmain-calltype01',
          //  result: '완료',
          //  resultCode: true,
          //  date: '2020-00-00',
          //  manager: '상담사',
          //  channel: '채널유형',
          //  bl: 'bl',
          //  company: '업체명',
          //  csResult: '상담결과',
          //  csMemo: '관리메모 관리메모 관리메모 관리메모 ',
          //  category: [
          //    { id: 1, title: '선택된 유형1' },
          //    { id: 2, title: '선택된 유형2' },
          //    { id: 3, title: '선택된 유형3' },
          //    { id: 4, title: '선택된 유형4' },
          //  ],
          //  category01: { id: 1, title: '선택된 유형1'},
          //  category02: { id: 2, title: '선택된 유형2'},
          //  category03: { id: 3, title: '선택된 유형3'},
          //  category04: { id: 4, title: '선택된 유형4'},
          //  textarea: '',
          //  audio: require('@/assets/@sample.mp3')
          //},
        ],

        isBtnPreChat : false,      //이전대화내역버튼
        
        cnslSaveFrame: false,              //상담 결과창 표시 여부 - true로 할 시 채팅이 종료되지 않아도 계속 보인다.
        msg: "",
	      text: "",
	      showSkipOnAlert : false,
	      sysmessageSkip	: '',
        talkStatCd		: '',
        execRstCd         : '',
	      isChating			: false,         ///////// 여기가 채팅중여부 bws
	      isDisable			: false,
	      talkContactId		: this.$store.state.messages.talkContactInfo.talk_contact_id,

        // 엑셀다운로드 field 
	  	  excel_fields: {
          'UUID': 'TALK_SENDER_KEY_NM',
          '대화자': 'CUSTOMER_NM',
          '메세지타입': 'TYPE_NM',
			    '채팅내용': 'CONTENT',
			    '시간':'IT_PROCESSING'
        },
    	  excel_data: [],
        excel_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        is_temp     : false,                // 상담이력 임시저장 여부 
        result_cd   : '',                   // 상담이력 처리결과 값
        talk_history_form_title : '',       // 상담이력 저장 화면 title 
        dialogTransfer : false,             // 상담전달 다이얼로그 view     
        dialogImage     : false,            // 이미지선택 다이얼로그 view 
        linefeed        : false,            // 라인피드 
        excelFileName   : '',               // telewebUtil.getTimeStampMilis() + "_" + jsonReq.getHeaderString("FILENAME") + ".xlsx";
        callTypCd       : '',               // 채널유형
        emoticon        : false,             // 이모티콘레이어

        /****************************************************************************************************************************************************
         * 챗 변수 / 함수 정보 END 
         ****************************************************************************************************************************************************
         ***************************************************************************************************************************************************/

        /**
         * 220603 김태준 채팅 변수 추가 
         */
        
        ASP_CUST_KEY: this.$store.getters["userStore/GE_USER_ROLE"].company,

        CLK_WAIT:0,

        CANT_MODI:true,
        CANT_CUST:false,

        RCT_CHAT_CON:'',
        RCT_CHAT_DATE:'',

        page: 1,
        pageCount: 30,
        itemsPerPage: 5,
        totalVisible: 10,
        gridScriptHeader: [
          {
            text: "NO",
            align: "center",
            width: "30px",
            value: "INDEX",
          },
          {
            text: "분류",
            align: "center",
            width: "50px",
            value: "LVL_NO_NM",
          },
          {
            text: "스크립트명",
            align: "center",
            width: "100px",
            value: "SCRIPT_TIT",
          },
        ],
        gridScriptBody: [],

        SCP_DETTY_NM:'',
        SCP_DET_TIT:'',
        SCP_DET_CON:'',



        EMAIL:'',
        USER_BL:[
          {
            title: 'B/L',
            icon: 'svg-dot-green',
            value: 'N'
          },
          {
            title: 'B/L',
            icon: 'svg-dot-red',
            value: 'Y'
          },
          {
            title: 'B/L',
            icon: 'svg-dot-orange',
            value: 'D'
          },
        ],
        USER_NEWBL:'',

        CHAT_MSG:'',

        SRH_ASP_CUST:'',
        SRH_PHONE_NUM:'',
        SRH_CUST_NM:'',
        SRH_CUST_NO:'',

        /**
         * 220603 김태준 채팅 변수 추가 
         */

        //추가삭제 MERGE
    };
  },
  methods: {

    /****************************************************************************************************************************************************
     * 공통 methods START 
     ***************************************************************************************************************************************************/

      // 모달 공통
      showDialog(type) {
        this[`dialog${type}`] = true;
      },

      hideDialog(type) {
        this[`dialog${type}`] = false;
      },

      call2p(type) {
        console.log("call2p");
      },

      call3p(type) {
        console.log("call3p");
      },

      cancel(type) {
        console.log("cancel");
      },

      callmove(type) {
        console.log("callmove");
      },

      callmainCsToggle(item) {
        return (item.expand = !item.expand);
      },

      /** 새로고침 방지  */
      setAvailableRefresh(e) {
        
        if(e.key === 116){

          if(typeof(event) == "object"){
            event.key = 0;
          }
          
          return false;

        } else if(e.key === 82 && e.ctrl) {
          return false;
        } else if(e.key === 8) {

          if(e.target.nodeName === "INPUT" || e.target.nodeName === "TEXTAREA"){
            
            if(typeof($(e.target).attr("readonly")) !== "undefined" || $(e.target).hasClass("twbDisabled") || $(e.target).is(":disabled")){
              return false;
            }else{
              return true;
            }

          } else {
            return false;
          }
        }
      },

      /** 경고메시지 */
      showAlertWarn(e){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: e,
          iconClass: 'svg-error-lg',
          type: 'default'
        })
      },
      /** 완료 메세지 */
      showAlertComplete(e){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: e,
          iconClass: 'svg-done-lg',
          type: 'default'
        })
      },

      /** Null 체크  */
      isNull(e){
            if (e == "" || e == null || e == undefined)
                return true;
            else
                return false;
      },

      /** ASP CUST KEY 추출 */
      getAspCustKey() {

        let aspCustKey = '';
        let i   = 0;
        let len = this.$store.getters["userStore/GE_USER_ROLE"].company.length;

        for (; i < len; i++) {

          if (i == 0) {
            aspCustKey = this.$store.getters["userStore/GE_USER_ROLE"].company[i].CD;
          } else {
            aspCustKey = aspCustKey + ',' + this.$store.getters["userStore/GE_USER_ROLE"].company[i].CD;
          }
        }

        return aspCustKey;
      },

      /**
       * 상담유형코드 조회
       * gbn : 구분 (call / chat)
       * aspCustKey : 인입 고객사 키 
       * lvl : 상담유형코드 단계 (1 ~ 3)
       * cnslTypCd : 선택 상담유형 코드 
       */
      getCmmnCnslTypCd(gbn, aspCustKey, lvl, cnslTypCd) {

        api.post("api/setting/consulttype/manage/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: aspCustKey,
            SPST_CNSL_TYP_CD: cnslTypCd,
            USE_YN: "Y",
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD
          },
          {
            head: {
              SERVICE: "setting.consulttype.manage",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        ).then((response) => {
          
          if (lvl == "1") {

            if (gbn == this.CNSL_WK[0]) {
              this.CALL_CNSL_TYP_CD = [...this.CALL_CNSL_TYP_CD, ...response.data.DATA];    
            } else if (gbn == this.CNSL_WK[1]) {
              this.CNSL_TY1 = [...this.CNSL_TY1, ...response.data.DATA];
            }
           
          } else if (lvl == "2") {

            if (gbn == this.CNSL_WK[0]) {
              this.CALL_CNSL_TYP_CD_2 = [...this.CALL_CNSL_TYP_CD_2, ...response.data.DATA];
            } else if (gbn == this.CNSL_WK[1]) {
              this.CNSL_TY2 = [...this.CNSL_TY2, ...response.data.DATA];
            }
            
          } else if (lvl == "3") {

            if (gbn == this.CNSL_WK[0]) {
              this.CALL_CNSL_TYP_CD_3 = [...this.CALL_CNSL_TYP_CD_3, ...response.data.DATA];
            } else if (gbn == this.CNSL_WK[1]) {
              this.CNSL_TY3 = [...this.CNSL_TY3, ...response.data.DATA];
            }

          }

        }).catch((err) => {
          console.log(err);
        });
      },

      /**
       * 시간 형식을 만들어주는 함수
       * return : strinmg (00:00)형식;
       */
      getHHMISS(callTime) {

        let rtnTime = "";				
        let intHH = 0;				                        // 시
        let intMI = 0;				                        // 분
        let intSS = 0;				                        // 초
    
        if (callTime == 0 || callTime == "") {        // callTime 아무것도 없을시 예외처리
          return rtnTime = "00:00:00";
        } else {
            
          /** callTime 60보다 작으면 초 */
          if (callTime < 60){
            intSS =  callTime;             
          } else {
            
            /** 60보다 크면 (초/60) = 분  */ 
            intMI = parseInt(callTime / 60);
            intSS = callTime % 60;
              
            /** 60보다 크면 (분/60) = 시 */ 
            if (intMI > 60) {	
              intHH = parseInt(intMI / 60);
              intMI = intMI % 60;
            }
          }
            
          /** 10보다 작으면 앞에 0을 추가해서 형식을 맞추어준다. */
          if (intHH < 10) { intHH = "0" + intHH};
          if (intMI < 10) { intMI = "0" + intMI};
          if (intSS < 10) { intSS = "0" + intSS};
            
          /** 시:분:초 형식을 위해서  : 추가한다. */
          rtnTime = intHH + ":" + intMI + ":" + intSS;
            
          return rtnTime;
        }
      },

    /****************************************************************************************************************************************************
     * 공통 methods END 
     ***************************************************************************************************************************************************/
    
    /****************************************************************************************************************************************************
     * 콜 methods START 
     ***************************************************************************************************************************************************/

      // cti 버튼
      btnClassRemove() {

        const activeBtn = document.querySelector(
          ".layout-chatmain--btn .v-btn--active"
        );

        activeBtn !== null ? activeBtn.classList.remove("v-btn--active") : "";
      },

      ctiReasonUpdate(i) {
        const valClass = this.CTI_REASON[i].className;
        const valText = this.CTI_REASON[i].text;

        this.CTI_BRD.expand = false;
        this.ctiMenuActive = i;

        //this.$refs.ctiToggleIcon.$el.classList.remove(
        //  "cti-btn-icon08",
        //  "cti-btn-expand-icon01",
        //  "cti-btn-expand-icon02",
        //  "cti-btn-expand-icon03",
        //  "cti-btn-expand-icon04"
        //);

        //this.$refs.ctiToggleIcon.$el.classList.add(valClass);
        //this.$refs.ctiToggleText.textContent = valText;
        this.CTI_BRD.current = valText;
        this.CTI_BRD.icon = valClass;

        /** 이석처리 */
        this.ctiReason(this.CTI_REASON[i].dbState);
      },

      //ctiMenuClass() {
      //  switch (this.ctiMenuActive) {
      //    case 0:
      //      this.CTI_REASON[0].className;
      //      break;
      //    case 1:
      //      this.CTI_REASON[1].className;
      //      break;
      //    case 2:
      //      this.CTI_REASON[2].className;
      //      break;
      //    case 3:
      //      this.CTI_REASON[3].className;
      //      break;
      //    case 4:
      //      this.CTI_REASON[4].className;
      //      break;
      //    default:
      //      "cti-btn-icon08";
      //      break;
      //  }
      //},

      /**
       * IPCC Socket 연결
       */
      ctiIpccConnect(e) {
        
        /** 소프트폰 중계 소켓 생성 */
        const socket = io.connect(this.IPCC_SOCKET_URL, {
          secure: this.SECURE_MODE,                                     // SSL option
          pingInterval: this.PING_INTERVAL,
          pingTimeout: this.PING_TIMEOUT,
        });
        
        Vue.prototype.$socket = socket;

        /** 노드 서버 접속 */
        socket.on("connect", function () {
          socket.emit("guestjoin", "cloudnode", e, "192.168.101.103");
        });

        /** 중복로그인 알림 - 로그아웃 처리 */ 
        socket.on('sameUserAlert',function(userid, userip){

          try{
              // 소켓 닫는다.
              if(socket) socket.disconnect();                        
              this.showAlertWarn('동일한 사용자아이디로 접속하여\n\n연결이 종료 되었습니다.!!!!!!!!!!!!!! (중복 로그인 불가) \n\n\n 접속자아이피 : ' + userip + ', 사용자아이디 : ' + userid + '\n\n');
          }catch(e){
            //
          }
        });

        /** API 명령어 결과 수신 */
        socket.on('recvmsg', function(username, data) {
          
          console.log('recvmsg => user : ' + username + ', data : ' + data);

          let rtnData;

          let rtnResult = "";                                         // 이벤트 처리 결과
          let rtnDesc   = "";                                         // 이벤트 구분 
          let rtnDbState   = "";                                      // CTI 상세 이벤트_1:대기, 2:후처리, 3:휴식, 4:교육, 5:식사, 6:기타

          if (e == username) {
            
            data = data.replace('[', '');
            data = data.replace(']', '');
            
            rtnData = JSON.parse(data)                                // JSON 데이터 파싱 

            rtnResult = rtnData.result;
            rtnDesc = rtnData.desc;

            eventBus.busInitCallCti(rtnData); 
          }
        });

        socket.on("softphone", function (msg) {

          if (msg) {
            
            /* 구현부 */
            let arrMsg      = msg.split("|");
            let msg_evt     = arrMsg[0];                                // 메시지 종류
            let telNo       = arrMsg[1];                                // 전화번호
            let campCode    = arrMsg[2];                                // 캠페인코드
            let today       = new Date();
            let year        = today.getFullYear();
            let month       = ("0" + (today.getMonth() + 1)).slice(-2);
            let day         = ("0" + today.getDate()).slice(-2);
            let dateString  = year + "-" + month + "-" + day;
            let hours       = ("0" + today.getHours()).slice(-2);
            let minutes     = ("0" + today.getMinutes()).slice(-2);
            let seconds     = ("0" + today.getSeconds()).slice(-2);
            let timeString  = hours + ":" + minutes + ":" + seconds;
            
            // ###############################################################################
            // API 팝업
            // 예시 : RINGING|01082055960|07045130953
            // ###############################################################################
            //if (msg_evt == "RINGING") {
            //  console.log("message : " + msg);
            //  console.log("telNo : " + telNo);
            //  tmpCallRing(telNo);
            //}

            switch (msg_evt) {
              
              case "RINGING" :      //전화벨 RINGING|07046495048|07080153021|1 (고객전화번호|대표번호|IVR디짓)

                console.log("message : " + msg);
                console.log("telNo : " + telNo);

                break;
              
              case "ANSWER" :       //전화수신 통화시작 ANSWER|07080151167|20220608/1099-20220608-202826-IB-07080151167-1654687706.97
                break;
              
              case "HANGUP" :       //통화종료 
                break;
              
              case "OUTBOUND" :     //전화발신 통화시작  OUTBOUND|01082055960|20220608/1099-20220608-201711-OB-01082055960-1654687031.93
                break;
            }
            // ###############################################################################

          }
        });
      },

      //소프트폰 초기 세팅_김민진
      //init() {
      //  let ncmc_key = this.INLNE_NO; // UNIQUE-KEY 적용.
      //  let PING_INTERVAL = 1000 * 10;
      //  let PING_TIMEOUT = 1000 * 60 * 60 * 3; // 스크린세이버 대응 : 3시간
      //  let telNob = "";
      //  console.log("###################################1 : ");
      //  // 소프트폰 중계 소켓 생성
      //
      //  const socket = io.connect("http://139.150.75.152:7777", {
      //    secure: "false" /* SSL option */,
      //    pingInterval: PING_INTERVAL,
      //    pingTimeout: PING_TIMEOUT,
      //  });
      //  Vue.prototype.$socket = socket;
      //  let tmpCallRing = (telNo) => this.callRing(telNo);
      //  socket.on("connect", function () {
      //    socket.emit("guestjoin", "cloudnode", ncmc_key, "192.168.103.15");
      //    console.log("###################################2 : ");
      //  });
      //  socket.on("softphone", function (msg) {
      //    if (msg) {
      //      /* 구현부 */
      //      var arrMsg = msg.split("|");
      //      var msg_evt = arrMsg[0]; // 메시지 종류
      //      var telNo = arrMsg[1]; // 전화번호
      //      var campCode = arrMsg[2]; // 캠페인코드
      //      var today = new Date();
      //      var year = today.getFullYear();
      //      var month = ("0" + (today.getMonth() + 1)).slice(-2);
      //      var day = ("0" + today.getDate()).slice(-2);
      //      var dateString = year + "-" + month + "-" + day;
      //      var hours = ("0" + today.getHours()).slice(-2);
      //      var minutes = ("0" + today.getMinutes()).slice(-2);
      //      var seconds = ("0" + today.getSeconds()).slice(-2);
      //      var timeString = hours + ":" + minutes + ":" + seconds;
      //      // ###############################################################################
      //      // API 팝업
      //      // 예시 : RINGING|01082055960|07045130953
      //      // ###############################################################################
      //      if (msg_evt == "RINGING") {
      //        console.log("message : " + msg);
      //        console.log("telNo : " + telNo);
      //        tmpCallRing(telNo);
      //      }
      //
      //      // ###############################################################################
      //    }
      //  });
      //  //Vue.use(moment);
      //
      //  console.log("###################################socket2 : ", socket);
      //},

      /**
       * CTI 로그인
       */
      ctiLogin() {
        this.$socket.emit("sendmsg", "db_login", "");
      },

      /**
       * CTI 로그아웃 
       */
      ctiLogout() {
        this.ctiHandleCls(false);                 //후처리 버튼 사용자 선택여부 
        this.$socket.emit("sendmsg", "db_logout", "");
      },

      /**
       * 콜 대기
       */
      ctiRead() {
        this.$socket.emit("sendmsg", "db_state", "1");
      },

      /**
       * CTI 후처리 버튼 선택 처리 
       */
      ctiHandle() {
        this.$socket.emit("sendmsg", "db_state", "2");
      },

      /**
       * CTI 후처리 상태값 표시
       */
      citHandleAuto() {

        this.ctiHandleCls(true);                 //후처리 버튼 사용자 선택여부 

        this.CTI_BRD.current = '후처리';
        this.CTI_BRD.icon = 'cti-btn-handle';  
      },

      /**
       * 후처리 버튼 선택 표시 여부 
       */
      ctiHandleCls(e) {
        this.CTI_BTN[6].cls = e;
      },
      
      /**
       * CTI 이석처리
       */
      ctiReason(e) {
        this.$socket.emit("sendmsg", "db_state", e);
      },
      
      /**
       * 타이머 시작
       */
      ctiCallTimerStart(alam, textAlam) {

        let i = 0;
        let ctiTimerAlam    = 0;
        let ctiTimerAlamLen = 0;

        ctiTimerAlamLen = this.CTI_TIMER_ALAM.length;       // CTI경과시간알림

        /** CTI경과시간알림 */
        if (ctiTimerAlamLen == 1) {
          ctiTimerAlam = this.CTI_TIMER_ALAM[ctiTimerAlamLen-1].value;
        }
        
        if (this.CTI_CALL_TIMER) {
          this.ctiCallTimerEnd();
        }

        this.CTI_CALL_TIMER =  setInterval(() => {
          
          i++;
          
          /** CTI 타이머 알람 상태값 */
          if (alam && ctiTimerAlam > 0 && i >= ctiTimerAlam) {
            this.CTI_BRD.ctiTimeLapse = true;  
          }

          /** CTI 상태 알림 상태값 */
          if (textAlam && ctiTimerAlam > 0 && i >= ctiTimerAlam) {
            this.CTI_BRD.ctiTextLapse = true;  
          }

          this.CTI_BRD.lapseTime = this.getHHMISS(i);
        }, 1000);

      },

      /**
       * 타이머 종료 
       */
      ctiCallTimerEnd() {

        clearInterval(this.CTI_CALL_TIMER);

        let i = 0;
        this.CTI_BRD.lapseTime = this.getHHMISS(i);
      },

      /**
       * 재택여부
       */
      btnCtiHome() {
      },

      /**
       * CTI Login Buttn Event
       */
      btnCtiLogin() {

        this.CTI_BRD.current = '';                // CTI 상태값 텍스트 초기화        
        this.CTI_BRD.icon    = null;              // CTI 상태값 아이콘 초기화 

        if (this.CTI_LOGIN_BOOLEAN == true) {
          this.ctiLogin();                        // 로그인
        } else {
          this.ctiLogout();                       // 로그아웃
        }
      },

      /**
       * CTI 클릭 버튼 활성화 처리 
       */
      btnCtiActive(e) {

        const _t = e.currentTarget;
        this.CTI_SEND_CALL.phone = this.callnum;
        
        this.ctiHandleCls(false);                 //후처리 버튼 사용자 선택여부 
        this.btnClassRemove();

        this.CTI_BRD.ctiTimeLapse = false;        // CTI 타이머 알람 상태값
         this.CTI_BRD.ctiTextLapse = false;       // CTI 상태 알림 상태값

        _t.classList.add("v-btn--active");
        
        this.CTI_BRD.current  = _t.querySelector(".layout-chatmain--btn-text").textContent;
        this.CTI_BRD.icon     = _t.querySelector(".v-icon").className.split(" ").splice(-1, 1);
        
        //console.log(this.CTI_BRD.icon[0].replace(/\-/g, ",").slice(-1));

        this.ctiBtnEvent(this.CTI_BRD.icon[0].replace(/\-/g, ",").slice(-1));
      },

      /**
       * CTI 클릭 시 모달창 활성화 처리 
       */
      btnCtiActiveExpand(e) {

        const _t = e.currentTarget;

        this.btnClassRemove();

        _t.classList.add("v-btn--active");
        this.CTI_BRD.expand = !this.CTI_BRD.expand;
      },

      //전화 콜 인입
      callRing(telNo) {
        this.callnum = telNo;
        this.CTI_TAKE_CALL.phone = telNo;
        //console.log("##############!!!!!!!!!!!!!!!!!!!!!!!");
        this.showDialog("M110101P05");
      },
      
      //콜 보류
      callHlod() {
        this.$socket.emit("sendmsg", "phone_hold", "");
        //this.testEx = "보류";
      },
      
      //콜 종료
      callClose() {
        this.$socket.emit("sendmsg", "phone_hang", "");
        //this.testEx = "통화종류";
      },
      
      //전화걸기
      callTest() {
        let telNum = this.callnum;
        //console.log("###################################telNum : ", telNum);
        this.$socket.emit("sendmsg", "phone_call", telNum);
        this.hideDialog("M110101P03");
        //this.testEx = "outbound 통화";
      },

      //전화 수신
      callReceive() {
        this.$socket.emit("sendmsg", "phone_acce", "");
        this.hideDialog("M110101P05");
      },
      
      /**
       * 버튼 이벤트 따른 CTI 이벤트 호출 
       */
      ctiBtnEvent(valueEx) {

        switch (valueEx) {

          case "1":                               // 음소거버튼
            break;

          case "2":                               // 보류버튼
            this.callHlod();
            break;

          case "3":                               // 대기버튼
            
            if (this.CTI_DB_STATE != '1') {
              this.ctiRead();                     // 대기
            } else {

              this.ctiCallTimerEnd();             // 타이머 종료 

              this.btnClassRemove()               // 버튼 클릭 Class 삭제 
              this.ctiBtnEvent('6');              // 대기해제 이벤트
            }
            
            break;

          case "4":                               // 끊기버튼
            this.callClose();
            break;

          case "5":                               // 호전환 버튼
            break;

          case "6":                               // 후처리버튼

            if (this.CTI_DB_STATE != "2") {

              /** CTI 로그인/대기해제/이석해제 자동 이벤트 처리 */
              if (this.CTI_BRD.icon != 'cti-btn-handle') {
                this.citHandleAuto();
              }   

              this.ctiHandle();                   // 후처리
            }
            
            break;

          case "7":                               // 걸기버튼
            break;
          
          case "8":                               // 이석버튼
            break;

          default:
            break;
        }
      },
      
      /**
       * 전화번호1 복사 
       */
      cpCusPhone(){
      
        //navigator.clipboard.writeText(this.chatmainCsPhone[0].title);

        navigator.clipboard.writeText(this.CUS_CUSTOMER_PHONE_NO);
        this.showToastInfo("전화번호를 복사했습니다.")
      },

      /**
       * 콜상담저장_상담유형코드
       */
      getCnslTypCd(e) {
        
        let cnslCnslTypCd = "";

        this.initCnslTypCdCmmn(this.CNSL_WK[0], e);            // 콜상담저장_상담유형코드1,2,3 초기화 
        
        if (e == "2") {
          cnslCnslTypCd = this.CNSL_CNSL_TYP_CD;
        } else if (e == "3") {
          cnslCnslTypCd = this.CNSL_CNSL_TYP_CD_2;
        }

        this.getCmmnCnslTypCd(this.CNSL_WK[0], this.CNSL_ASP_CUST_KEY, e, cnslCnslTypCd);
      },

      /**
       * 콜상담저장_상담유형코드1,2,3 초기화 
       */
      initCnslTypCdCmmn(gbn, e) {
        
        /** 상담유형 1 */
        if (e == '1') {
          if (gbn == this.CNSL_WK[0]) {
            this.CALL_CNSL_TYP_CD = [];
          } else if (gbn == this.CNSL_WK[1]) {
            this.CNSL_TY1 = [{ CD: "", CD_NM: "전체" }];
          }
        }

        /** 상담유형 2 */
        if (e == '1' || e == "2") {
          if (gbn == this.CNSL_WK[0]) {
            this.CALL_CNSL_TYP_CD_2 = [];
          } else if (gbn == this.CNSL_WK[1]) {
            this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
          }
        }

        /** 상담유형 3 */
        if (e == '1' || e == "2" || e == "3") {
          if (gbn == this.CNSL_WK[0]) {
            this.CALL_CNSL_TYP_CD_3 = [];
          } else if (gbn == this.CNSL_WK[1]) {
            this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
          }
        }
      },

      /**
       * 공통코드_cmmnCd: ['PLT002', 'PLT003', 'PLT004','PLT006']_콜관련공통코드_상담통합이력 콜유형_상담통합이력 IN_상담통합이력 OB_B/L 구분 
       */
      initCallCmmn() {
        
        let dropItemsLst = [];
        let dropItemsLen = 0;
        let i = 0;

        this.PROC_TY_DROPITEMS = this.mixin_common_code_get(this.cmmnCdLst, 'OU003');         // 캠페인 처리구분      
        this.FISH_TY_DROPITEMS = this.mixin_common_code_get(this.cmmnCdLst, 'OU003');         // 캠페인 처리결과 
        this.EOT_TY_RLT_DROPITEMS = this.mixin_common_code_get(this.cmmnCdLst, 'OU003');      // 콜백 처리구분    
        this.PROC_CODE_DROPITEMS = this.mixin_common_code_get(this.cmmnCdLst, 'OU005');       // 콜백 처리결과
        this.FISH_YN_DROPITEMS = this.mixin_common_code_get(this.cmmnCdLst, 'CRS022');        // 예약 처리결과 

        this.CNSL_CALL_TY_RADIOGRP = this.mixin_common_code_get(this.cmmnCdLst, 'PLT025');    // 콜상담저장_콜유형

        this.PROC_DROPITEMS = this.mixin_common_code_get(this.cmmnCdLst, 'PLT024');           // 콜상담저장_상담통합 상담결과
        this.CALL_TY_DROPITEMS = this.mixin_common_code_get(this.cmmnCdLst, 'PLT004');        // 콜상담저장_상담통합이력 OB
        
        this.CTI_TIMER_ALAM = this.mixin_common_code_get(this.cmmnCdLst, 'CRS047');           // CTI 타이머 알람시간
        this.CTI_ALAM_STATE = this.mixin_common_code_get(this.cmmnCdLst, 'CRS048');           // CTI 타이머 알람 상태값
        this.CTI_ALAM_TEXT  = this.mixin_common_code_get(this.cmmnCdLst, 'CRS049');           // CTI 상태 알림 상태값

        this.PROC_OB_DROPITEMS = [],                                                          // 콜상담저장_캠페인/콜백/예약콜 처리구분      
        this.FISH_OB_DROPITEMS = [],                                                          // 콜상담저장_캠페인/콜백 처리결과
        
        this.CHANNEL_DROPITEMS = this.mixin_common_code_get(this.commonCodeLst, 'PLT002',''); // 콜상담_고객상담이력_채녈유형

        dropItemsLst = this.mixin_common_code_get(this.cmmnCdLst, 'PLT006');                  // 콜상담저장_B/L 구분

        dropItemsLen = dropItemsLst.length;

        /** 콜상담저장_B/L 구분 */
        if (dropItemsLen > 0) {

           for(; i < dropItemsLen; i++) {

            let val = dropItemsLst[i].value;
            let icon1 = "svg-dot-";

            if (val == "G") {
              icon1 = icon1 + "green";
            } else if (val == "R") {
              icon1 = icon1 + "red";
            } else if (val == "O") {
              icon1 = icon1 + "orange";
            } else if (val == "V") {
              icon1 = icon1 + "black";
            } 

            this.BL_DROPITEMS.push({title: "B/L",icon: icon1,gbn: val});
           } 
        }
      },
      
      /**
       * 콜관련 전체 초기화 
       */
      intiCall(e) {

        if (e == 'ALL') {
          this.initCallCti('off');              // CTI 버튼 초기화
        }
        
        this.initCallCtiCall();                 // CIT CALL 정보 (수/발신 정보)
        this.initCallCtiBrd();                  // CIT CALL 정보 고객정보 상단 정보 

        this.initCnslTypCdCmmn(this.CNSL_WK[0], '1') // 상담유형코드1,2,3 초기화
        this.initCallOb();                      // OB_캠페인,콜백,예약콜 전역 변수 일괄 초기화 

        this.initCallCnsl();                    // 콜상담_콜상담저장 초기화 
        this.initCallCusSch();                  // 콜상담_고객정보 검색 초기화 
        this.initCallCus();                     // 콜상담_고객정보 초기화 

        this.initCallCnslSch();                 // 콜상담_고객상담이력_검색초기화 
        this.initCallCnslDtlSch();              // 콜상담_고객상담이력_상세검색초기화 
        this.initCallCnslLst();                 // 콜상담_고객상담이력
      },

      /**
       * CIT CALL 정보 (수/발신 정보)
       */
      initCallCtiCall() {

        /**
         * 수신전화 모달
         */
        this.CTI_TAKE_CALL = {
          ci: require("@/assets/@call-ci.png"),
          company: "",                                  // 고객사명
          phone: "",                                    // 010-0000-0000
          text: "",                                     // 업무구분  : IVR시나리오
        };

        /**
         * 발신전화 모달
         */
        this.CTI_SEND_CALL = {
          ci: require("@/assets/@call-ci.png"),
          first: "",                                    // 대표번호
          phone: "010-0000-0000",                       // 010-0000-0000
          text: "업무구분  : ",                         // 업무구분  : IVR시나리오
        };
      },
      
      /**
       * CIT CALL 정보 고객정보 상단 정보 
       */
      initCallCtiBrd() {
        
        /**
         * 콜상담 상단 고객정보 표시 
         */
        this.CTI_CALL_STATUS = {
          incall: true,
          img: require("@/assets/logo.png"),
          imgAlt: "",                                   // 기업명
          name: "",                                     // 고객명 홍홍홍
          phoneNumber: "",                              // 000-0000-0000
          phoneMaskNumber: "",                          // 000-****-0000
          mask: false,
        };
      },

      /**
       * 콜상담_CTI 버튼 초기화  
       */
      initCallCti(e) {
        
        let i = 0;
        let ctiBtnLen = 0;

        let ctiBtnDisableLst = [];                      // CTI BTN 리스트 
        let ctiBtnDisableLen = 0;
        
        if (e == 'off') {
          ctiBtnDisableLst = this.CTI_BTN_DISABLED.ctiOff;
        } else if ( e == 'on' || e == 'handle') {
          ctiBtnDisableLst = this.CTI_BTN_DISABLED.ctiOn;  
        } else if ( e == 'ready') {
          ctiBtnDisableLst = this.CTI_BTN_DISABLED.ctiReady;  
        }
        
        ctiBtnLen = this.CTI_BTN.length;
        ctiBtnDisableLen = ctiBtnDisableLst.length;

        /** CTI BTN 재 배열  */
        if (ctiBtnDisableLen > 0) {

          for(; i < ctiBtnDisableLen; i++) {

            let btn = ctiBtnDisableLst[i].desc;
            let bol = ctiBtnDisableLst[i].bol;

            if (ctiBtnLen > 0) {
              
              let x = 0;

              for(; x < ctiBtnLen; x++) {
            
                let desc = this.CTI_BTN[x].desc;

                if (desc == btn) {
                  this.CTI_BTN[x].disalb = bol;
                  break;
                }
              }
            }
          }
        }
        
        if (e == 'off') {

          this.ctiCallTimerEnd();                                       // 콜타이머 초기화
          this.CTI_BRD.ctiTimeLapse = false;                            // CTI 타이머 알람 상태값
          this.CTI_BRD.ctiTextLapse = false;                            // CTI 상태 알림 상태값

        } else if ( e == 'on' || e == 'handle' || e == 'ready') {       // CTI 경과시간 알람 상태값 
          
          let i = 0;
          let x = 0;

          let ctiAlamStateLen = 0;                                      // CTI 타이머 알람 상태값
          let ctiAlamTextLen  = 0;                                      // CTI 상태 알림 상태값

          let bol = false;
          let textBol = false;

          ctiAlamStateLen = this.CTI_ALAM_STATE.length;
          ctiAlamTextLen = this.CTI_ALAM_TEXT.length;

          /** CTI 타이머 알람 상태 */          
          if (ctiAlamStateLen > 0) {
            
            for(; i < ctiAlamStateLen; i++) {

              let tmpCtiState = this.CTI_ALAM_STATE[i].value;
              
              if (e == tmpCtiState) {
                bol = true;
                break;
              }
            }
          }   

          /** CTI 상태 알림 상태값 */
          if (ctiAlamTextLen > 0) {

            for(; x < ctiAlamTextLen; x++) {

              let tmpCtiText = this.CTI_ALAM_TEXT[x].value;

              if (e == tmpCtiText) {
                textBol = true;
                break;
              }

            }
          }

          this.ctiCallTimerStart(bol, textBol);
        }
      },

      /**
       * OB_캠페인,콜백,예약콜 전역 변수 일괄 초기화 
       */
      initCallOb() {

        this.initCallOutBound();                // 아웃바운드 세팅 정보
        this.initCallCallBack();                // initCallCallBack
        this.initCallResvCall();                // initCallResvCall
      },

      /**
       * 아웃바운드 세팅 초기화 
       */
      initCallOutBound() {

        this.CALL_OUT_BOUND = {  
          QUEST_ID : "",	                                // 설문번호
          CUST_NO : "",	                                  // 고객번호
          CUST_NM : "",	                                  // 고객명
          CAM_NM  : "",	                                  // 캠페인이름
          CAM_ID  : "",	                                  // 캠페인ID
          CENT_TY : ""                                    // 센터구분
        }
      }, 

      /**
       * 콜백세팅정보
       */
      initCallCallBack() {

        this.CALL_CALL_BACK ={
          CLBK_NO : ""                                      //콜백 ID
        } 
      },

      /**
       * 예약콜 세팅정보
       */
      initCallResvCall() {

        this.objResvCall = {
          RESV_SEQ : "",	                                  // 예약콜ID
          FISH_YN : "",	                                    // 승인여부
          TRY_CNT : ""	                                    // 시도횟수
        }
      },

      /**
       * 콜상담_콜상담 저장 초기화 
       */
      initCallCnsl() {
        
        this.PROC_OB_DROPITEMS = [],                         // 콜상담저장_캠페인/콜백/예약콜 처리구분      
        this.FISH_OB_DROPITEMS = [],                         // 콜상담저장_캠페인/콜백 처리결과

        this.CNSL_DISABLED = true;                           // 콜상담저장_Disabled
        //this.CNSL_CALL_TY_RADIOGRP : [],                   // 콜상담저장_콜유형 

        this.CNSL_CALL_TY = "";                              // 콜상담저장_콜유형 선택값 
        this.CNSL_ATENT_CUSTOMER = "";                       // 콜상담저장_주의고객
        this.CNSL_CNSL_TYP_CD = "";                          // 콜상담저장_상담유형
        this.CNSL_CNSL_TYP_CD_2 = "";                        // 콜상담저장_상담유형2
        this.CNSL_CNSL_TYP_CD_3 = "";                        // 콜상담저장_상담유형3
        this.CNSL_RESVE_CHK = "";                            // 콜상담저장_상담예약 체크 여부 
        this.CNSL_ASP_CUST_KEY = "";                         // 콜상담저장_업체명
        this.CNSL_PROC_CODE_LCLS = "";                       // 콜상담저장_상담결과 
        this.CNSL_CALL_TY_DTL = "";                          // 콜상담저장_콜유형_OB 상세 유형
        this.CNSL_PROC_OB = "";                              // 콜상담저장_캠페인/콜백/예약콜 처리구분
        this.CNSL_FISH_OB = "";                              // 콜상담저장_캠페인/콜백 처리결과 
        this.CNSL_CUSTOMER_MEMO = "";                        // 콜상담저장_관리메모 
        this.CNSL_CNTN = "";                                 // 콜상담저장_상담내용
        
        /** 콜 상담 저장 */
        this.CNSL_CALL_TY = '01';
      },

      /**
       * 콜상담_고객정보 검색 초기화 
       */
      initCallCusSch() {

        this.SCH_ASP_CUST_KEY = "";                          // 고객정보_고객검색_업체명
        this.SCH_CUSTOMER_PHONE_NO = "";                     // 고객정보_고객검색_전화번호 
        this.SCH_CUSTOMER_NM = "";				                    // 고객정보_고객검색_고객명 
      },

      /**
       * 콜상담_고객정보 초기화 
       */
      initCallCus() {
        
        this.CUS_DISABLED = true;                            // 고객정보__Disabled  
        this.CUS_PHONE_DISABLED = true,                      // 고객정보_전화번호1 걸기/복사하기
        
        this.CUS_CUSTOMER_ID = "";                           // 고객정보_고객 ID
        this.CUS_ASP_CUST_NM = "";	                          // 고객정보_업체명 
        this.CUS_CUSTOMER_NM = "";	                          // 고객정보_고객명 
        this.CUS_TALK_USER_KEY = "";                         // 고객정보_Key 
        
        this.CUS_CUSTOMER_PHONE_NO = "";                     // 고객정보_전화번호 1
        this.CUS_CUSTOMER_PHONE_NO_ENC = "";                 // 고객정보_전화번호 1 암호화
        this.CUS_WRC_TEL_NO  = "";				                    // 고객정보_전화번호 2 
        this.CUS_WRC_TEL_NO_ENC = "";                        // 고객정보_전화번호 2 암호화

        this.CUS_EMAIL = "";			                            // 고객정보_이메일 
        this.CUS_OWNHOM_ADR = "";	                            // 고객정보_주소 
        this.CUS_OWNHOM_DETAIL_ADR = "";                      // 고객정보_상세주소
        this.CUS_ATENT_CD = "";                              // 고객정보_주의고객코드 (B/L)
        this.CUS_CNSL_EOT_DATE = "";                          // 고객정보_최근 상담일시
        this.CUS_CNSL_CNTN = "";                             // 고객정보_상담정보 
        this.CUS_CUSTOMER_MEMO = "";                         // 고객정보_관리메모 
      },

      /**
       * 콜상담_고객상담이력_검색초기화  
       */
      initCallCnslSch() {

        this.CNSL_CHANNEL_TY_SCH = "";                       // 고객정보_고객상담이력_검색_채녈유형
        this.CNSL_PROC_CODE_LCLS_SCH = "";                   // 고객정보_고객상담이력_검색_상담결과 
      },

      /**
       * 콜상담_고객상담이력_상세검색초기화 
       */
      initCallCnslDtlSch() {

        this.CNSL_CHANNEL_TY_DTLSCH = ""; 	                // 고객정보_고객상담이력_상세검색_채널유형
        this.CNSL_ATENT_CD_DTLSCH = "";                      // 고객정보_고객상담이력_상세검색_주의고객
        this.CNSL_ASP_CUST_NM_DTLSCH = "";                   // 고객정보_고객상담이력_상세검색_업체명 
        this.CNSL_PROC_CODE_LCLS_DTLSCH = "";                // 고객정보_고객상담이력_상세검색_상담결과 
        this.CNSL_REG_MAN_DTLSCH = "";                       // 고객정보_고객상담이력_상세검색_상담사 
        this.CNSL_CALL_TY_DTLSCH = "";                       // 고객정보_고객상담이력_상세검색_유형 (OB)
      },

      /**
       * 고객상담이력 초기화 
       */
      initCallCnslLst() {
          
        this.CALL_CNSL_LST = [];
      },
    /****************************************************************************************************************************************************
     * 콜 methods END 
     ***************************************************************************************************************************************************/


    //일시 모달
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    allowedStep: (m) => m % 10 === 0,
    chatmainCsCall(e) {
      console.log("전화번호1", e);
    },
    chatmainCsToggle(item) {
      this.getTalkHist(item.ID, !this.chatHistory[item.ID - 1].EXPAND);
      this.getTalkHistList(item.ID - 1, item.JOBBY_CNSL_HST_ID);
    },
    // 채팅 상단 후처리, 책갈피
    showAlertWrite() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '후처리로 <span class="clr-sucess">전환</span> 하시겠습니까?',
        iconClass: "svg-chk-lg",
        type: "confirm",
        callYes: this.callYes,
        callNo: this.callNo,
      });
    },
    showAlertBookmark() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '책갈피를 <span class="clr-sucess">등록</span> 하시겠습니까?',
        iconClass: "svg-chk-lg",
        type: "confirm",
        callYes: this.callYes,
        callNo: this.callNo,
      });
    },
    callYes() {
      console.log("call yes");
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      this.markUp();
    },
    callNo() {
      console.log("call No");
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
    // 상담현황
    FnChatListAll() {
      this.chatListFilter = "all";
    },
    FnChatListIng() {
      this.chatListFilter = "ing";
    },
    FnChatListDone() {
      this.chatListFilter = "done";
    },
    FnChatListAfter() {
      this.chatListFilter = "after";
    },
    FnChatListBookmark() {
      this.chatListFilter = "bookmark";
    },
    // 채팅상담 맨위로
    onScroll(e) {
      this.scroll = e.target.scrollTop;
    },
    scrlToTop() {
      this.scrollTarget = document.querySelector(
        ".layout-chatmain--chat-history-box"
      );

      if (this.scrollTarget) {
        this.scrollTarget.scrollTop = 0;
      }
    },
    FnChatHistoryTextsize(e) {
      this.chatHistoryTextsize = e;
      this.chatFontsize = false;
    },

    //220525 김태준 채팅 이벤트 추가
    //

    //상담원 전체 고객사 키
    fullCust() {
      let CUST = "";
      for (
        let i = 0;
        i < this.$store.getters["userStore/GE_USER_ROLE"].company.length;
        i++
      ) {
        if (i == 0) {
          CUST = this.$store.getters["userStore/GE_USER_ROLE"].company[i].CD;
        } else {
          CUST =
            CUST +
            "," +
            this.$store.getters["userStore/GE_USER_ROLE"].company[i].CD;
        }
      }

      return CUST;
    },

    /**
       * 상담원 상태 변경 
       */
      changeUserStat (showMsg, userStatCd) {
        
        if( userStatCd == null || userStatCd == '')   {
          userStatCd = this.dropItemsUserStatCd;
        }
   
        //20210106
        let simsessionId =  this.$store.state.messages.simsessionId;

        apiChat.post("api/chat/main/cnslr-sttus/regist", {
          USER_STATUS_CD : userStatCd,
          USER_ID: this.$store.getters['userStore/GE_USER_ID'],
          ASP_NEWCUST_KEY : this.getAspCustKey(),
        }, {head: {
            "SERVICE" : "api.chat.main.cnslr-sttus",
            "METHOD" : "regist",
            "TYPE" : "BIZ_SERVICE",
        }})
        .then((ret) => {
          
          /* 처리후 메시지 보여줄지 여부 */  
          if(showMsg) {
            
            let count     = ret.data.header.COUNT;
            let errorFlag = ret.data.header.ERROR_FLAG;
            
            if (count != null && errorFlag == false) {

                if(count >= 0) {
                    this.showAlertComplete( '상태가 변경 되었습니다.')
                } else {
                  this.showAlertWarn( '상태가 실패 되었습니다.');    
                }
            }
          }
        });
      },

    //상담 목록 조회
      getTalkList(){

        apiChat.post("api/chat/main/cnslt/inqire",   //api url입력
        {
          TYPE: 'ready;talking;callback;post',
          SORT_BY: 'TALK_DIST_DT',
          DECENDING: 'ASC',
          TALK_USER_KEY: '',
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
        },
        {head: {
        }})
        .then((response) => {
          console.log("결과>>>>>>>>>>>",response.data.data);
          this.chatWaitList = [];
          this.chatList = [];
          for (var i = 0; i < response.data.data.length; i++) {
            if(response.data.data[i].TALK_STAT_CD == '11'){
              let WAIT_LENGTH = this.chatWaitList.length;
              this.chatWaitList[WAIT_LENGTH] = response.data.data[i];
              this.chatWaitList[WAIT_LENGTH]["type"] = 'wait';
              
              this.chatWaitList[WAIT_LENGTH]["index"] = i + 1;

              if(this.chatWaitList[WAIT_LENGTH]["CALL_TYP_CD"] == "KAKAOBZC"){
                this.chatWaitList[WAIT_LENGTH]["CHN_ICON"] = 'chat';
              }
            } else {
              let CHAT_LENGTH = this.chatList.length;
              this.chatList[CHAT_LENGTH] = response.data.data[i];

              this.chatList[CHAT_LENGTH]["index"] = i + 1;

              if(this.chatList[CHAT_LENGTH]["CALL_TYP_CD"] == "KAKAOBZC"){
                this.chatList[CHAT_LENGTH]["CHN_ICON"] = 'chat';
              }

              if(this.chatList[CHAT_LENGTH].TALK_STAT_CD == "12"){
                this.chatList[CHAT_LENGTH]["type"] = 'ing';
              }else if(this.chatList[CHAT_LENGTH].TALK_STAT_CD == "21"){
                this.chatList[CHAT_LENGTH]["type"] = 'done';
              }else if(this.chatList[CHAT_LENGTH].TALK_STAT_CD == "20"){
                this.chatList[CHAT_LENGTH]["type"] = 'after';
              }

              if(this.chatList[CHAT_LENGTH].MARK_UP == "Y"){
                this.chatList[CHAT_LENGTH]["bookmark"] = 'bookmark';
              }
            }
          }

          this.$store.dispatch("messages/AC_INIT_TALK_LIST", response.data.data).then(() => {
            this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : this.selected_stat, userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.CUST});
          });

          if(this.CLK_WAIT==1){
            console.log("if this.chatList", this.chatList);
            this.clickChat(0);
            this.CLK_WAIT=0;
          }
          console.log("this.chatWaitList>>>><<<", this.chatWaitList);
          console.log("this.chatList>>>><<<", this.chatList);
        })
        .catch((err) => {
          console.log("결과<<<<<",err);
          alert(err);
        })

      },

    //상담 클릭 시 이벤트
    clickChatWait(i) {
      this.CLK_WAIT=1;

      this.CLK_CUST = this.chatWaitList[i].ASP_CUST_KEY;
      this.CLK_CHAT = this.chatWaitList[i].TALK_CONTACT_ID;
      this.CLK_USER = this.chatWaitList[i].TALK_USER_KEY;
      this.CLK_SENDER = this.chatWaitList[i].ASP_SENDER_KEY;
      this.CLK_STAT = this.chatWaitList[i].TALK_STAT_CD;
      this.CLK_ICON = this.chatWaitList[i].ICON;
      this.CLK_IMG = this.chatWaitList[i].IMG;
      this.CLK_MSGSKIP = this.chatWaitList[i].SYSMSG_SKIP_YN;
      this.MARK_UP = this.chatWaitList[i].MARK_UP;

      this.CNSL_TY1 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
      this.getCnslTY("1", "*");

      this.getTalkMsg();
      this.getTalkInfo();
      this.getTalkHist("", "");
      this.getUserInfo();
      
      //this.getTalkList();
    },
    clickChat(i) {
      this.CANT_CUST = true;
      if(this.chatList[i].TALK_STAT_CD == "20"){
        this.CANT_MODI = true;
        this.chatDone = true;
      } else {
        this.CANT_MODI = false;
        this.chatDone = false;
      }
      this.CLK_CUST = this.chatList[i].ASP_CUST_KEY;
      this.SRH_ASP_CUST = this.chatList[i].ASP_CUST_KEY;
      this.CLK_CHAT = this.chatList[i].TALK_CONTACT_ID;
      this.CLK_USER = this.chatList[i].TALK_USER_KEY;
      this.CLK_SENDER = this.chatList[i].ASP_SENDER_KEY;
      this.CLK_STAT = this.chatList[i].TALK_STAT_CD;
      this.CLK_ICON = this.chatList[i].ICON;
      this.CLK_IMG = this.chatList[i].IMG;
      this.CLK_MSGSKIP = this.chatList[i].SYSMSG_SKIP_YN;
      this.MARK_UP = this.chatList[i].MARK_UP;

      this.CNSL_TY1 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
      this.getCnslTY("1", "*");

      this.getTalkMsg();
      this.getTalkInfo();
      this.getTalkHist("", "");
      this.getUserInfo();

      this.getTalkList();
    },

    //상담 정보 조회
    getTalkMsg() {
      apiChat
        .post(
          "api/chat/main/cnslt-info/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            TALK_CONTACT_ID: this.CLK_CHAT == "" ? "" : this.CLK_CHAT,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/cnslt-info/inqire",
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          console.log("채팅방 정보<<<<<", response.data.data);
          //this.CLK_MSGSKIP = response.data.data[0].SYSMSG_SKIP_YN;
          if(response.data.data.length != 0){
            this.CNSL_NEWTY = response.data.data[0].CNSL_TYP_CD;
            this.CNSL_NEWTY2 = response.data.data[0].CNSL_TYP_CD_2;
            this.CNSL_NEWTY3 = response.data.data[0].CNSL_TYP_CD_3;
            this.CNSL_NEWTY4 = response.data.data[0].CNSL_TYP_CD_4;
          } else {
            this.getTalkList();
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    //상담 상세 정보
    getTalkInfo() {
      apiChat
        .post(
          "api/chat/main/cnslt-cn/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            TALK_CONTACT_ID: this.CLK_CHAT == "" ? "" : this.CLK_CHAT,
            TALK_USER_KEY: this.CLK_USER,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/cnslt-cn/inqire",
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          console.log("채팅정보>>>>>>>>", response.data.data);
          this.talkHistory = response.data.data;
          for (var i = 0; i < this.chatList.length; i++) {
            //this.talkHistory[i]["index"] = i + 1;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    //상담 이력 조회
    getTalkHist(n, tf) {
      apiChat
        .post(
          "api/chat/main/cnslt-hist/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            TALK_USER_KEY: this.CLK_USER,
            CNSL_DIV: "",
            CSTMR_TELNO: "",
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/cnslt-hist/inqire",
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          console.log("상담이력<<<<>>>>", response.data.data);
          let LIST = this.chatHistory;
          this.chatHistory = response.data.data;
          if(response.data.data.length != 0){
            this.RCT_CHAT_CON = response.data.data[0].HST_CONTEXT;
            this.RCT_CHAT_DATE = response.data.data[0].REG_DTTM;
          }
          for(let i=0;i<response.data.data.length;i++){
            if(n == '' || n == null){
              this.chatHistory[i]["EXPAND"] = false;
            } else{
              this.chatHistory[i]["EXPAND"] = LIST[i].EXPAND;
              this.chatHistory[n-1]["EXPAND"] = tf;
            }
            this.chatHistory[i]["ID"] = i+1;
            if(response.data.data[i].HST_TYPE == 'kakao'){
              this.chatHistory[i]["ICON"] = 'svg-chat-icon-kakao';
            } else if(response.data.data[i].HST_TYPE == 'line'){
              this.chatHistory[i]["ICON"] = 'svg-chat-icon-line';
            } else if(response.data.data[i].HST_TYPE == 'wechat'){
              this.chatHistory[i]["ICON"] = 'svg-chat-icon-wechat';
            } else if(response.data.data[i].HST_TYPE == 'ttalk'){
              this.chatHistory[i]["ICON"] = 'svg-chat-icon-ttalk';
            }

            if(this.chatHistory[i]["PROC_CODE_NM"] == '완결' || this.chatHistory[i]["PROC_CODE_NM"] == '전달'){
              this.chatHistory[i]["PROC_CODE"] = 'done';
            } else if(this.chatHistory[i]["PROC_CODE_NM"] == '미결'){
              this.chatHistory[i]["PROC_CODE"] = 'undone';
            } else if(this.chatHistory[i]["PROC_CODE_NM"] == '콜백'){
              this.chatHistory[i]["PROC_CODE"] = 'ing';
            }
          }
          console.log("chatHistory상담이력<<<<>>>>", this.chatHistory);
        })
        .catch((err) => {
          alert(err);
        });
    },

    //고객정보 조회
    getUserInfo() {
      apiChat
        .post(
          "api/chat/main/cstmr-info/inqire/" + this.CLK_USER, //api url입력
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.CUST,
            CUSTOMER_ID: this.SRH_CUST_NO,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/cstmr-info/inqire/" + this.CLK_USER,
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          console.log("사용자 정보>>>><<<<", response.data.data);
          this.USER_NM = response.data.data[0].CUSTOMER_NM;
          this.USER_MEMO = response.data.data[0].MEMO;
          this.USER_TEL_NO = response.data.data[0].CUSTOMER_PHONE_NO;
          this.address = response.data.data[0].ADDRESS;
          this.detailAddress = response.data.data[0].DETAILADDRESS;
          //this.extraAddress = response.data.data[0].EXTRAADDRESS;  //추후 DB추가
          //this.extraAddress = response.data.data[0].POST_CODE;     //추후 DB추가
          this.EMAIL = response.data.data[0].EMAIL;
          this.USER_NEWBL = response.data.data[0].ATENT_CUSTOMER;
          if (response.data.data[0].ATENT_CUSTOMER == "Y") this.CHK_BL = true;
        })
        .catch((err) => {
          alert(err);
        });
    },

    //상담이력 조회
    getTalkHistList(index, key) {
      apiChat
        .post(
          "api/chat/main/cnslt-hist/chat-inqire/" + key, //api url입력
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/cnslt-hist/chat-inqire/" + key,
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          console.log("상담이력<<<<>>>>", response.data.data);
          console.log("index", index);
          this.talkHistList[index] = response.data.data;
          console.log("이력메시지목록<<<<>>>>", this.talkHistList);
        })
        .catch((err) => {
          alert(err);
        });
    },

    //고객정보 저장
    regiUserInfo() {
      //if(or == 'chat'){
        let BL = "";
        if (this.CHK_BL == true) {
          BL = "Y";
        } else if (this.CHK_BL == false) {
          BL = "N";
        }
        apiChat
          .post(
            "api/chat/main/cstmr-info/regist/" + this.CLK_USER, //api url입력
            {
              ASP_NEWCUST_KEY: this.CLK_CUST,
              ASP_SENDER_KEY: this.CLK_SENDER,
              ATENT_CUSTOMER: this.CUS_ATENT_CD,
              CUSTOMER_ID: this.CLK_USER,
              CUSTOMER_NM: this.USER_NM,
              CUSTOMER_PHONE_NO: this.USER_TEL_NO.replace(/\-/g, ""),
              MEMO: this.USER_MEMO,
              TALK_USER_KEY: this.CLK_USER,
              EMAIL: this.EMAIL,
              ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
              ASP_USER_NM: this.$store.getters["userStore/GE_USER_ROLE"].userNm,
              ASP_CUST_KEY: this.CUST,
              USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
              action: "api/chat/main/cstmr-info/regist/" + this.CLK_USER,
              method: "post",
            },
            { head: {} }
          )
          .then((response) => {
            console.log("사용자 정보>>>><<<<", response.data.data);
            this.success();
            this.chatmainChatEdit = true;
          })
          .catch((err) => {
            alert(err);
          });
      //}
    },

    //고객정보 저장 확인
    showAlertReg() {
        if(this.chatmainChatEdit == false){
          this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: "고객정보를 저장하시겠습니까?",
            iconClass: "svg-chk-lg",
            type: "confirm",
            callYes: this.callYes1,
            callNo: this.callNo1,
          });
        } else if (this.chatmainChatEdit == true) {
          this.chatmainChatEdit = false;
        }
    },

    //고객정보 저장 [확인] 클릭
    callYes1() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      this.regiUserInfo();
    },

    //고객정보저장 [취소]클릭
    callNo1(or) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      if(or == 'chat'){
        this.chatmainChatEdit = true;
      }
    },

    //성공메시지
    success() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "정상처리 되었습니다.",
        iconClass: "svg-done-lg",
        type: "default",
      });
    },

    showDialogImg(menu) {
      let dataObj = { 
                      CLK_CUST: this.CLK_CUST,
                      CLK_CHAT: this.CLK_CHAT,
                      CLK_USER: this.CLK_USER,
                      CLK_SENDER: this.CLK_SENDER,
                      CLK_MSGSKIP: this.CLK_MSGSKIP,
                      CLK_STAT: this.CLK_STAT,
                      CLK_ICON: this.CLK_ICON,
                      CLK_IMG: this.CLK_IMG
                    };
      this.Imgdata = dataObj;
      console.log(this.Imgdata);
      this[`Dialog${menu}`] = true;
    },

    //시스템 메시지 발송
    SystemMsgSkip() {
      let MSGSKIP_YN = "";
      if (this.CLK_MSGSKIP == "Y") {
        MSGSKIP_YN = "N";
      } else if (this.CLK_MSGSKIP == "N") {
        MSGSKIP_YN = "Y";
      }
      apiChat
        .post(
          "api/chat/main/un-rspns-mssage-sndng-setup/process", //api url입력
          {
            TALK_CONTACT_ID: this.CLK_CHAT,
            SYSMSG_SKIP_YN: MSGSKIP_YN,
            ASP_NEWCUST_KEY: this.CLK_CUST,
            ASP_CUST_KEY: this.CLK_CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/un-rspns-mssage-sndng-setup/process",
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          console.log("시스템메시지 발송<<<<>>>>", response.data.data);
          if (MSGSKIP_YN == "N") {
            this.successSysMsg("시스템 메시지 발송이 중지되었습니다.");
            this.CLK_MSGSKIP = "N";
          } else if (MSGSKIP_YN == "Y") {
            this.successSysMsg("시스템 메시지가 발송됩니다.");
            this.CLK_MSGSKIP = "Y";
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    //시스템 메시지 발송 성공
    successSysMsg(successMsg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: successMsg,
        iconClass: "svg-done-lg",
        type: "default",
      });
    },

    //종료링크 보내기
    sendEndLink() {
      let strMsg = "고객님께 종료 버튼을 전송하였습니다.";

      let MSG_INFO = {'msg' : strMsg, message_type : 'LI', SNDRCV_CD: 'SND', type : 'chatClose'}
      let CHAT_INFO = {'talk_contact_id' : this.CLK_CHAT
                      , 'talk_sender_key' : this.CLK_SENDER
                      , 'call_typ_cd' : 'KAKAOBZC'
                      , 'aspNewCustKey' : this.CLK_CUST
                      , 'talk_user_key': this.CLK_USER
                      , 'SEND_USER_NM' : this.USER_NM
                      , 'token' : this.$store.state.messages.token
                      , 'userId' : this.$store.getters['userStore/GE_USER_ROLE'].userId
                      };
      let chatmsg = this.$store.getters["messages/GE_MSG_OBJ"]({
                    user_key: CHAT_INFO.talk_user_key
                    , sndrcv_cd: 'snd'
                    , type: MSG_INFO.message_type
                    , content: MSG_INFO.msg
                    , talk_contact_id: CHAT_INFO.talk_contact_id
                    , IMAGE_TALK_PATH : MSG_INFO.IMAGE_TALK_PATH != '' ? MSG_INFO.IMAGE_TALK_PATH : ''
                    , FILE_GROUP_KEY : MSG_INFO.FILE_GROUP_KEY != '' ? MSG_INFO.FILE_GROUP_KEY : ''
                    , FILE_KEY : MSG_INFO.FILE_KEY != '' ? MSG_INFO.FILE_KEY : ''
                    , FILE_BLOB : MSG_INFO.FILE_BLOB != '' ? MSG_INFO.FILE_BLOB : ''
                    , FILE_EXTS : MSG_INFO.FILE_EXTS != '' ? MSG_INFO.FILE_EXTS : ''
                    //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
                    , SEND_USER_NM : CHAT_INFO.SEND_USER_NM
                    , SEND_USER_SHORT_NM : '나'
                    , consultEnd : MSG_INFO.consultEnd
                    , ASP_NEWCUST_KEY : CHAT_INFO.aspNewCustKey
                });

      let msgObj = this.$store.getters['messages/GE_CONTACT_MSG']({ talk_contact_id: CHAT_INFO.talk_contact_id });
      
      stompUtil.sendMessage(MSG_INFO,CHAT_INFO, chatmsg, msgObj);
    },

    //전달 시 채팅상담 가능 상담사 목록 조회
    getUserSelect() {
      apiChat
        .post(
          "api/chat/common-vue/chaton-agents/list", //api url입력
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            KEYWORD: "",
            ASP_CUST_KEY: this.CLK_CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/common-vue/chaton-agents/list",
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          console.log("사용자 선택박스 생성<<<<>>>>", response.data.data);
          this.USER_ID = response.data.data[0];
        })
        .catch((err) => {
          alert(err);
        });
    },

    //전달 확인
    confirmTrans() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: this.USER_NEWID + "님께 채팅을 전달하시겠습니까?",
        iconClass: "svg-chk-lg",
        type: "confirm",
        callYes: this.chatTransToUser,
        callNo: this.callNo1,
      });
    },

    //채팅 전달
    chatTransToUser() {
      apiChat
        .post(
          "api/stomp/chat/agent/forwarding", //api url입력
          {
            CNSL_TYP_CD: this.CNSL_NEWTY1,
            CNSL_TYP_CD_2: this.CNSL_NEWTY2,
            CNSL_TYP_CD_3: this.CNSL_NEWTY3,
            CNSL_TYP_CD_4: this.CNSL_NEWTY4,
            EXEC_RST_CD: "3",
            TALK_ANS: this.CHT_TIT,
            TALK_QST: this.CHT_CON,
            TALK_CONTACT_ID: this.CLK_CHAT,
            agent_id: this.USER_NEWID,
            ASP_NEWCUST_KEY: this.CLK_CUST,
            ASP_CUST_KEY: this.CLK_CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/stomp/chat/agent/forwarding",
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          this.successSysMsg("채팅을 전달하였습니다.");
        })
        .catch((err) => {
          alert(err);
        });
    },

    //상담유형1
    chnCnsl1() {
      this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
      this.getCnslTY("2", this.CNSL_NEWTY1);
    },
    //상담유형2
    chnCnsl2() {
      this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
      this.getCnslTY("3", this.CNSL_NEWTY1);
    },
    //상담유형3
    chnCnsl3() {
      this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
      this.getCnslTY("4", this.CNSL_NEWTY1);
    },

    //사용자 상담유형 콤보박스 생성
    getCnslTY(num, cnsl) {
      if (num == "1") {
        this.CNSL_TY1 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY1 = "";
        this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY2 = "";
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY3 = "";
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY4 = "";
        let TY_CD = "*";
      } else if (num == "2") {
        this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY2 = "";
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY3 = "";
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY4 = "";
        let TY_CD = cnsl;
      } else if (num == "3") {
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY3 = "";
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY4 = "";
        let TY_CD = cnsl;
      } else if (num == "4") {
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY4 = "";
        let TY_CD = cnsl;
      }
      api
        .post(
          "api/setting/consulttype/manage/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            SPST_CNSL_TYP_CD: cnsl,
            USE_YN: "Y",
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "setting.consulttype.manage",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          console.log(response.data.DATA);
          if (num == "1") {
            this.CNSL_TY1 = [...this.CNSL_TY1, ...response.data.DATA];
          } else if (num == "2") {
            if (this.CNSL_NEWTY1 == "") {
              this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY2 = "";
              this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY3 = "";
              this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY4 = "";
            } else {
              this.CNSL_TY2 = [...this.CNSL_TY2, ...response.data.DATA];
            }
          } else if (num == "3") {
            if (this.CNSL_NEWTY2 == "") {
              this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY3 = "";
              this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY4 = "";
            } else {
              this.CNSL_TY3 = [...this.CNSL_TY3, ...response.data.DATA];
            }
          } else if (num == "4") {
            if (this.CNSL_NEWTY3 == "") {
              this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY4 = "";
            } else {
              this.CNSL_TY4 = [...this.CNSL_TY4, ...response.data.DATA];
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    //상담유형 초기화
    //취소 선택시 폼 초기화 ( 상담제목/내용 초기화 추가 )
    initCnsl() {
      this.CNSL_NEWTY1 = "";
      this.CNSL_NEWTY2 = "";
      this.CNSL_NEWTY3 = "";
      this.CNSL_NEWTY4 = "";

      this.CHT_NEWRESULT = "";
      this.CHT_TIT = "";
      this.CHT_CON = "";
    },

    //메시지 전송
      sendMsg(){
        let msgcopy = this.CHAT_MSG
        if (msgcopy.replace(/(?:\r\n|\r|\n)/g, '').length === 0) {
          return false;
        }

        let MSG_INFO = {'msg' : msgcopy, message_type : 'TX', SNDRCV_CD: 'SND', type : 'message'}
        let CHAT_INFO = {'talk_contact_id' : this.CLK_CHAT
                        , 'talk_sender_key' : this.CLK_SENDER
                        , 'call_typ_cd' : 'KAKAOBZC'
                        , 'aspNewCustKey' : this.CLK_CUST
                        , 'talk_user_key': this.CLK_USER
                        , 'SEND_USER_NM' : this.USER_NM
                        , 'token' : this.$store.state.messages.token
                        , 'userId' : this.$store.getters['userStore/GE_USER_ROLE'].userId
                        };

        let chatmsg = this.$store.getters["messages/GE_MSG_OBJ"]({
                      user_key: CHAT_INFO.talk_user_key
                      , sndrcv_cd: 'snd'
                      , type: MSG_INFO.message_type
                      , content: MSG_INFO.msg
                      , talk_contact_id: CHAT_INFO.talk_contact_id
                      , IMAGE_TALK_PATH : MSG_INFO.IMAGE_TALK_PATH != '' ? MSG_INFO.IMAGE_TALK_PATH : ''
                      , FILE_GROUP_KEY : MSG_INFO.FILE_GROUP_KEY != '' ? MSG_INFO.FILE_GROUP_KEY : ''
                      , FILE_KEY : MSG_INFO.FILE_KEY != '' ? MSG_INFO.FILE_KEY : ''
                      , FILE_BLOB : MSG_INFO.FILE_BLOB != '' ? MSG_INFO.FILE_BLOB : ''
                      , FILE_EXTS : MSG_INFO.FILE_EXTS != '' ? MSG_INFO.FILE_EXTS : ''
                      //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
                      , SEND_USER_NM : CHAT_INFO.SEND_USER_NM
                      , SEND_USER_SHORT_NM : '나'
                      , consultEnd : MSG_INFO.consultEnd
                      , ASP_NEWCUST_KEY : CHAT_INFO.aspNewCustKey
                  });

        let msgObj = this.$store.getters['messages/GE_CONTACT_MSG']({ talk_contact_id: CHAT_INFO.talk_contact_id });

        stompUtil.sendMessage(MSG_INFO,CHAT_INFO, chatmsg, msgObj)
        this.CHAT_MSG = '';

        this.getTalkList();
        this.getTalkInfo();
      },

    //상담이력 저장(임시저장,후처리,콜백,)
    saveChat(num) {
      let TIME = this.computedDateFormatted2;
      let TIME_ARR = TIME.split("-");
      let TIME_ARR_ARR = TIME_ARR[2].split(" ");
      let TIME_ARR_ARR_ARR = TIME_ARR_ARR[1].split(":");

      apiChat
        .post(
          "api/chat/main/cnslt-hist/regist", //api url입력
          {
            CNSL_TYP_CD: this.CNSL_NEWTY1,
            CNSL_TYP_CD_2: this.CNSL_NEWTY2,
            CNSL_TYP_CD_3: this.CNSL_NEWTY3,
            CNSL_TYP_CD_4: this.CNSL_NEWTY4,
            EXEC_RST_CD: num,
            TALK_ANS: this.CHT_TIT,
            TALK_QST: this.CHT_CON,
            TALK_CONTACT_ID: this.CLK_CHAT,
            TALK_STAT_CD: 12,
            CALLBACK_YMD: TIME_ARR[0] + TIME_ARR[1] + TIME_ARR_ARR[0],
            CALLBACK_HH: TIME_ARR_ARR_ARR[0],
            CALLBACK_MM: TIME_ARR_ARR_ARR[1],
            CNSL_DIV: "",
            CSTMR_TELNO: this.USER_TEL_NO,
            CSTMR_ID: this.CLK_USER,
            ASP_NEWCUST_KEY: this.CLK_CUST,
            ASP_CUST_KEY: this.CLK_CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/cnslt-hist/regist",
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          if (this.CLK_STAT == "12" && num != "4") {
            apiChat
              .post(
                "api/chat/main/end-mssage/inqire", //api url입력
                {
                  ASP_NEWCUST_KEY: this.CLK_CUST,
                  system_id: "20180403040958193MSG34895",
                  ASP_CUST_KEY: this.CLK_CUST,
                  USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
                  ASP_USER_ID:
                    this.$store.getters["userStore/GE_USER_ROLE"].userId,
                  action: "api/chat/main/end-mssage/inqire",
                  method: "post",
                },
                { head: {} }
              )
              .then((response) => {
                let MSG = {
                  type: "system",
                  message_type: "TX",
                  SNDRCV_CD: "SND",
                  type: "message",
                  consultEnd: "Y",
                  msg: response.data.data,
                };
                stompUtil.sendMessage(MSG);
              })
              .catch((err) => {
                alert(err);
              });
          } else {
            if (num == "4") {
              this.successSysMsg("콜백이 저장되었습니다.");

              this.getTalkList();

              this.CHT_NEWRESULT = "";
              this.CNSL_NEWTY1 = "";
              this.CNSL_NEWTY2 = "";
              this.CNSL_NEWTY3 = "";
              this.CNSL_NEWTY4 = "";
              this.CHT_NEWRESULT = "";
              this.CHT_TIT = "";
              this.CHT_CON = "";
              this.date = new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .substr(0, 10);
              this.timeStep = "00:00";
            } else {
              this.successSysMsg("상담이력이 저장되었습니다.");
              this.chatDone = false;
            }

            this.getTalkList();

            this.talkHistory = [];

            this.CLK_CUST = "";
            this.CLK_CHAT = "";
            this.CLK_USER = "";
            this.CLK_SENDER = "";
            this.CLK_MSGSKIP = "";
            this.CLK_STAT = "";

            this.CHT_NEWRESULT = "";

            this.USER_NM = "";
            this.USER_TEL_NO = "";
            this.CHK_BL = false;
            this.USER_MEMO = "";

            this.chatHistory = [];

            this.CNSL_NEWDIV = "";
            this.SEARCH_CONT = "";

            this.USER_NEWID = "";

            this.CNSL_NEWTY1 = "";
            this.CNSL_NEWTY2 = "";
            this.CNSL_NEWTY3 = "";
            this.CNSL_NEWTY4 = "";
            this.CHT_NEWRESULT = "";
            this.CHT_TIT = "";
            this.CHT_CON = "";

            this.MSG = "";
            this.CANT_CUST=false;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    //임시저장
    saveChatTemp() {
      apiChat
        .post(
          "api/chat/main/cnslt-hist-tmpr/regist", //api url입력
          {
            CNSL_TYP_CD: this.CNSL_NEWTY1,
            CNSL_TYP_CD_2: this.CNSL_NEWTY2,
            CNSL_TYP_CD_3: this.CNSL_NEWTY3,
            CNSL_TYP_CD_4: this.CNSL_NEWTY4,
            EXEC_RST_CD: "",
            TALK_ANS: this.CHT_TIT,
            TALK_QST: this.CHT_CON,
            TALK_CONTACT_ID: this.CLK_CHAT,
            ASP_NEWCUST_KEY: this.CLK_CUST,
            ASP_CUST_KEY: this.CLK_CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/cnslt-hist-tmpr/regist",
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          this.successSysMsg("상담이력이 임시저장되었습니다.");
          this.chatTemp = false;
        })
        .catch((err) => {
          alert(err);
        });
    },

    //상담저장 확인
    confirmSave(num, saveMsg) {
      this.EXEC_RST_CD = num;
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: saveMsg,
        iconClass: "svg-chk-lg",
        type: "confirm",
        callYes: this.callSaveYes,
        callNo: this.callNo1,
      });
    },

    // 상담 저장 [확인] 클릭
    callSaveYes() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      if (this.EXEC_RST_CD == "3" || this.EXEC_RST_CD == "4") {
        this.saveChat(this.EXEC_RST_CD);
      } else {
        this.saveChatTemp();
      }
    },

    //주소검색 팝업
    execDaumPostcode() {
      // new window.daum.Postcode({
      //   oncomplete: (data) => {
      //     if (this.extraAddress !== "") {
      //       this.extraAddress = "";
      //     }
      //     if (data.userSelectedType === "R") {
      //       // 사용자가 도로명 주소를 선택했을 경우
      //       this.address = data.roadAddress;
      //     } else {
      //       // 사용자가 지번 주소를 선택했을 경우(J)
      //       this.address = data.jibunAddress;
      //     }

      //     // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
      //     if (data.userSelectedType === "R") {
      //       // 법정동명이 있을 경우 추가한다. (법정리는 제외)
      //       // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
      //       if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
      //         this.extraAddress += data.bname;
      //       }
      //       // 건물명이 있고, 공동주택일 경우 추가한다.
      //       if (data.buildingName !== "" && data.apartment === "Y") {
      //         this.extraAddress +=
      //           this.extraAddress !== ""
      //             ? `, ${data.buildingName}`
      //             : data.buildingName;
      //       }
      //       // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
      //       if (this.extraAddress !== "") {
      //         this.extraAddress = `(${this.extraAddress})`;
      //       }
      //     } else {
      //       this.extraAddress = "";
      //     }
      //     // 우편번호를 입력한다.
      //     this.postcode = data.zonecode;
      //   },
      // }).open();
    },

    //고객정보 초기화
    initUser() {
      this.USER_ID = "";
      this.USER_NM = "";
      this.USER_NICK = "";
    },

    //고객정보 파라미터 삽입
    btnCust(e) {

      console.log("123");
      console.log("e : " + e);

      let cust = {};

      if (e == this.CNSL_WK[0]) {
        cust = {
          ASP_CUST_KEY: this.SCH_ASP_CUST_KEY,
          CUST_NM: this.SCH_CUSTOMER_NM,
          PHONE_NUM: this.SCH_CUSTOMER_PHONE_NO,
          CNSL_WK : this.CNSL_WK[0] 
        }; 
      } else if (e == this.CNSL_WK[1]) {
        cust = {
          ASP_CUST_KEY: this.SRH_ASP_CUST,
          CUST_NM: this.SRH_CUST_NM,
          PHONE_NUM: this.SRH_PHONE_NUM,
          CNSL_WK : this.CNSL_WK[1]
        };
      }
      
      this.cusInfo = cust;
    },

    setCustData() {
      this.SRH_ASP_CUST = this.cusInfo.ASP_CUST_KEY;
      this.CLK_CUST = this.cusInfo.ASP_CUST_KEY;
      this.SRH_CUST_NM = this.cusInfo.CUST_NM;
      this.SRH_PHONE_NUM = this.cusInfo.CUSTOMER_PHONE_NO;

      this.SRH_CUST_NO = this.cusInfo.CUST_NO;
      this.CLK_USER = this.cusInfo.CUST_NO;

      this.getUserInfo();
      this.getTalkHist("","");
    },

    showToastInfo(MSG) {
      this.$store.commit("toastStore/POP", {
        act: true,
        msg: MSG,
        class: 'toast-info',
        hasToastIcon: true,
        icon: 'svg-info',
      })
    },

    //
    //220525 김태준 채팅이벤트 추가

    //
    //220530 웹소켓 이벤트 추가
    /**
     * websocket connect
     * @param connectCallback   연결 성공 콜백
     * @param errorCallback     연결 실패 콜백 ( 재연결 시도 )
     */
    connect() {
      let geUserId = this.$store.getters["userStore/GE_USER_ID"];

      //const subScribeUrl = this.baseSocketUrl + "queue/ready/" + geUserId;                //"http://local.hkpalette.com:8443/queue/ready/"
      let subScribeUrl = "/queue/ready/" + geUserId;

      let chatMessage = {
        chatType: "READY",
        userId: geUserId,
        userName: geUserId,
        userNickname: geUserId,
      };

      stompUtil.token = this.$store.state.messages.token; //SOCKET 연결 시 사용자 SOCKET Token 세팅
      stompUtil.userId = geUserId;
      stompUtil.aspNewCustKey = this.CUST;

      stompUtil.connect(
        () => {
          // 연결성공( 재연결 포함 )

          //this.connected = true;

          stompUtil.subscribe(subScribeUrl, chatMessage); // ready uri sub
          
          let subList = this.$store.getters['messages/GE_SUBSCRIPTION_LIST'];
          console.log("subList!!!!", subList)
          stompUtil.subscribeAfterReconnect(subList);      // 재연결 후 기존 sub 목록을다시 sub 해줌.최초는 대상이 없으므로 무관

          eventBus.getTalkers();                    // 웹소켓 연결 후 상담목록 조회

          ///// 선택된 상담이 잇다면 재조회 (재연결시...)
          ////if( this.store.getters.getContactInfo().talk_contact_id != null && .getters.getContactInfo().talk_contact_id != '' ) {
          /////  eventBus.refreshChatContent(this.store.getters.getContactInfo().talk_contact_id);
          ////}
        },
        (error) => {
          //연결실패

          if (error != null) {
            //console.log(error);
            stompUtil.setConnected(false);

            // 재연결 시도
            setTimeout(this.connect, 3000);
          }
        }
      );
    },

    /**
     * 웹소켓 연결종료
     * Stomp / Socket-Io 
     */
    disconnect() {

      if (stompUtil.isConnected()) {
        stompUtil.disconnect();
      }

      /** IPCC Socket 로그아웃 */
      if (this.CTI_LOGIN_BOOLEAN == true) {
        this.ctiLogout();                       // 로그아웃
        this.$socket.disconnect();
      }

    },

    /**
       * ready callback 함수  
       */
      readyCallback(message) {
        
        console.log("M110101 readyCallback message.body >>>> " + message.body);

        if (typeof message.body !== "undefined") {

          let recvJson = JSON.parse(message.body);
          let data     = JSON.parse(recvJson.telewebJsonString);
          let uri      = message.headers.destination;
          
          console.log("M110101 readyCallback recvJson.chatType >>>> " + recvJson.chatType);

          if (recvJson.chatType == "READY" && recvJson.chatEvent == "AGENT_READY") {
            let sessionId = recvJson.sessionId;
            this.$store.state.messages.simsessionId = sessionId;        
          }
          
          console.log("M110101 readyCallback data.DATA.length >>>> " + data.DATA.length);

          if (data.DATA != null && data.DATA.length > 0) {

            let i = 0;
            let dataLen = data.DATA.length;

            for (; i < dataLen; i++) {
                        
              let talkUserKey = data.DATA[i].TALK_USER_KEY;
              let userKey = data.DATA[i].user_key; 
              
              console.log("M110101 readyCallback talkUserKey >>>> " + talkUserKey);

              if (dataLen > 0 && ((talkUserKey != null && talkUserKey) || (userKey != null && userKey))) {
                
                // 상담종료 메시지 보여줌. unsubscription ( 필요없음 redis 삭제 되므로.)
                if (recvJson.chatEvent == 'EXPIRED_SESSION_CUST') {
                  
                  data.DATA[i].uri = uri;
                  
                  this.$store.dispatch("messages/AC_SPICE_TALKER", data.DATA[i]).then(() => {
                    this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.getAspCustKey()});
                  });     // 목록 삭제 (구독해제 포함)

                  //Vue.prototype.store.dispatch('spliceTalker', data.DATA[i]).then(() => {
                  //  Vue.prototype.store.dispatch("changeTalkProcessInfo", {selected_stat : ''});
                  //});     // 목록 삭제 (구독해제 포함)
                }   
                else {
                  
                  console.log("M110101 readyCallback talkUserKey else >>>> ");
                      
                  // data.DATA[i].TALK_STAT_NM = "대기중";
                  data.DATA[i].TALK_STAT_NM = this.mixin_get_talk_statnm(data.DATA[i].TALK_STAT_CD);
                  data.DATA[i].USER_ID = recvJson.userId;
                  data.DATA[i].AUTO_GREETING_YN = data.DATA[i].TALK_STAT_CD == '11' || data.DATA[i].TALK_STAT_CD == '13'? 'N' : 'Y';

                  this.$store.dispatch("messages/AC_UN_SHIFT_TALKER", data.DATA[i]).then(() => {
                    this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.getAspCustKey()});
                  }); 

                  //Vue.prototype.store.dispatch('unshiftTalker', data.DATA[i]).then(() => {
                  //  Vue.prototype.store.dispatch("changeTalkProcessInfo", {selected_stat : ''});
                  //});
                                 
                  this.getTalkers();      //신규 메시지를 받으면 새로고침
                }
              }
            }
          }
        }
      },

    /**
       *  inout callback 함수  
       */   
      inoutCallback(message) {
            
        console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 13");

        if (typeof (message.body) !== "undefined") {
 
          let recvJson = JSON.parse(message.body);
          let data = JSON.parse(recvJson.telewebJsonString);
          let uri = message.headers.destination;
                 
          if (data.DATA.length > 0 && data.DATA[0].user_key != null && data.DATA[0].user_key != '') {
 
            data.DATA[0].uri = uri;
 
            if (data.DATA[0].SNDRCV_CD == null || data.DATA[0].SNDRCV_CD == '') {
              data.DATA[0].sndrcv_cd = 'rcv';
            }
 
            // 자신이 보낸 메시지는 스킵 처리 한다.( 구독자 모두에게 다시 전송 되므로..)
            if (data.DATA[0].SNDRCV_CD == 'SND' && data.DATA[0].USER_ID == this.$store.getters["messages/GE_USER_ID"]) {   //Vue.prototype.store.getters.getUserId()
              return;
            }
                     
            // 제3자 채팅
            if (data.DATA[0].SNDRCV_CD == 'TRAN') {
              data.DATA[0].content = data.DATA[0].message;
            } 
                     
            // 무응답은 error_msg 가 메시지 내용임.
            // console.log("obj.inoutCallback >> " + recvJson.attachment + '-' + recvJson.org_cont_id  + '-' + data.DATA[0].attachment + '-' + data.DATA[0].org_cont_id  + '-' );
            if (recvJson.chatEvent == 'NO_CUSTOMER_RESPONSE' || recvJson.chatEvent == 'EXPIRED_AGENT_SESSION') {
              data.DATA[0].content = data.HEADER.ERROR_MSG;
              data.DATA[0].sndrcv_cd = 'snd';
              data.DATA[0].USER_NAME = 'SYSTEM';
            }

            if (typeof data.DATA[0].content == "object" || Array.isArray(data.DATA[0].content)){
              data.DATA[0].content = JSON.stringify(data.DATA[0].content);
            }
 
            let msg = this.$store.getters["messages/GE_MSG_OBJ"](data.DATA[0]);                                                             // Vue.prototype.store.getters.getMsgObj(data.DATA[0]);
            let msgObj = this.$store.getters["messages/GE_CONTACT_MSG"]({ talk_contact_id: data.DATA[0].TALK_CONTACT_ID });                 //Vue.prototype.store.getters.getContactMsg
                     
            // 상담종료 메시지 보여줌. unsubscription ( 필요없음 redis 삭제 되므로.)
            if (recvJson.chatEvent == 'EXPIRED_SESSION_CUST') {
 
              // 상담중 완료
              let talkerObj = _.find(this.$store.getters["messages/GE_TALK_LIST"], { 'TALK_CONTACT_ID': data.DATA[0].TALK_CONTACT_ID });    //Vue.prototype.store.getters.getTalkList()
              
              // console.log("obj.EXPIRED_SESSION_CUST >> " + talkerObj + '-' + data.DATA[0].TALK_CONTACT_ID);
              if (talkerObj != null && talkerObj != '' && data.DATA[0].TALK_CONTACT_ID != null) {     // 이미 상담 시작됨.
                
                talkerObj.TALK_STAT_CD = data.DATA[0].TALK_STAT_CD
                talkerObj.TALK_STAT_NM = this.mixin_get_talk_statnm(data.DATA[i].TALK_STAT_CD);
 
                msg.msg_notice = "상담이 종료되었습니다. 상담이력을 저장해 주시기 바랍니다.";  

                this.$store.dispatch("messages/AC_SUB_TALKER", data.DATA[0]).then(() => {
                  this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO" , {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.getAspCustKey()});
                });     // 구독해제

                //Vue.prototype.store.dispatch('AC_SUB_TALKER', data.DATA[0]).then(() => {
                //  Vue.prototype.store.dispatch("AC_CHANGE_TALK_PROCESS_INFO" , {selected_stat : ''});
                //});     // 구독해제

              } else {    // 대기중         
                             
                this.$store.dispatch("messages/AC_SPICE_TALKER", data.DATA[0]).then(() => {
                  this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO" , {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.getAspCustKey()});
                });     // // 대기중 완료 목록 삭제 (구독해제 포함)    

                //Vue.prototype.store.dispatch('spliceTalker', data.DATA[0]).then(() => {
                //  Vue.prototype.store.dispatch("changeTalkProcessInfo", {selected_stat : ''});
                //});     // // 대기중 완료 목록 삭제 (구독해제 포함)    
              }
                         
              // 현재 선택 채팅이 변경 된 경우만 변경 처리  this.$store.state.messages.talkContactInfo.talk_contact_id
              if( data.DATA[0].TALK_CONTACT_ID == this.$store.state.messages.talkContactInfo.talk_contact_id) {
                this.changeChatInfo();
              }

              window.focus();
            }
            else if (recvJson.chatEvent == 'EXPIRED_AGENT_SESSION') {         // 전달완료 (목록에서 삭제 )

              this.$store.dispatch("messages/AC_SPICE_TALKER",data.DATA[0]).then(() => {
                this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.getAspCustKey()});
 
                if( data.DATA[0].TALK_CONTACT_ID == this.$store.state.messages.talkContactInfo.talk_contact_id) {
                  this.changeChatInfo();
                }
              });     // 목록 삭제 (구독해제 포함)    

              //Vue.prototype.store.dispatch('spliceTalker', data.DATA[0]).then(() => {
              //  Vue.prototype.store.dispatch("AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.getAspCustKey()});
              //  if( data.DATA[0].TALK_CONTACT_ID == Vue.prototype.store.state.messages.talkContactInfo.talk_contact_id) {
              //    eventBus.changeChatInfo(()=>{});
              //  }
              //});     // 목록 삭제 (구독해제 포함)
                         
              return;
            }    

            this.$store.dispatch("messages/AC_USER_CHAT_MESSAGE", { "msg": msg, "msgObj": msgObj, aspCustKey : this.getAspCustKey()})
            //Vue.prototype.store.dispatch("userchatMessage", { "msg": msg, "msgObj": msgObj });
                     
          }
        }
      },

      /** 채팅 클릭시 채팅정보 검색 */
      getTalkers() {

        apiChat.post("api/chat/main/cnslt/inqire", {
          ASP_NEWCUST_KEY : this.getAspCustKey()
        })
        .then((ret) => {

          // 서버 error 체크 
          if(ret.data.error != null && ret.data.error != '') {

              if(ret.data.error == '-999') {
                this.showAlertWarn( '상세 목록 조회 Error');    
              }
          } 
          else {
            
            // this.$store.dispatch("messages/AC_SPICE_TALKER", data.DATA[i])
            if( ret.data.code >= 0) {

              this.$store.dispatch("messages/AC_INIT_TALK_LIST", ret.data.data).then(() => {
                  this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : this.selected_stat, userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.getAspCustKey()});
              });
            }
          }  
        });
      },

    /** 챗 사용자정보 세팅 */
    setChatUserInfo() {
      apiChat
        .post("api/stomp/chat/userinfo", {
          USER_ID: this.$store.getters["userStore/GE_USER_ID"],
          USER_NM: this.$store.getters["userStore/GE_USER_NAME"],
        })
        .then((ret) => {
          // 서버 error 체크
          if (ret.data.error != null && ret.data.error != "") {
            if (ret.data.error == "-999") {
              this.showAlertWarn("챗 상담 사용자 정보 Error(Talk Token)");
            }
          } else {
            this.$store.state.messages.token = ret.data.token;
            this.$store.state.messages.userId = ret.data.id;
            this.$store.state.messages.userNm = ret.data.name;
            this.$store.state.messages.aspCustKey = ret.data.aspCustKey;

            this.setTalkEnv(); // timeout 정보
            this.connect(); // ready websocket 연결
          }
        });
    },

    /** 상담설정 관련 정보 get */
    setTalkEnv() {
      apiChat
        .post("api/chat/common-vue/env/inqry", {
          ASP_NEWCUST_KEY: this.CUST,
        })
        .then((ret) => {
          // 서버 error 체크
          if (ret.data.error != null && ret.data.error != "") {
            if (ret.data.error == "-999") {
              this.showAlertWarn("챗 상담 사용자 정보 Error(Talk Token)");
            }
          } else {
            let retObj = Object.values(ret.data.data);

            if (retObj != null && retObj.length > 0) {
              this.$store.state.readyTimeout =
                retObj[0].RETRY_READY_MAX_TIMEOUT;
            }

            if (retObj != null && retObj.length > 0) {
              this.$store.state.messages.blobYn = retObj[0].BLOB_YN;
            }
          }
        });
    },

    //책깔피 등록
    markUp() {
      let MARK = ''
      if(this.MARK_UP == 'N' || this.MARK_UP == null || this.MARK_UP == ''){
        MARK = 'Y'
      } else {
        MARK = 'N'
      }
      api
        .post("/api/chat/main/markup/regist", {
          ASP_NEWCUST_KEY: this.CLK_CUST,
          TALK_CONTACT_ID: this.CLK_CHAT == "" ? "" : this.CLK_CHAT,
          MARK_UP: MARK,
          ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        },
        {
          head: {
            SERVICE: "chat.main.markup",
            METHOD: "regist",
            TYPE: "BIZ_SERVICE",
          },
        })
        .then((ret) => {
          console.log("등록완료>>>>>>>>>>>>>", ret.data.DATA);
          this.getTalkList();
        });
    },

    /** 고객등급 조회(외부API) */
    test() {
      api
        .post("/api/phone/main/blvip/inqire", {
          IN_NUM: '',         //내선번호
          PHONE_NUM: ''       //전화번호
        })
        .then((ret) => {
          console.log("고객등급>>>>>>>>>>>>>", ret.data.DATA);
        });
    },

    /** 고객등급 저장(외부API) */
    test1() {
      api
        .post("/api/phone/main/blvip/regist", {
          IN_NUM: '',         //내선번호
          PHONE_NUM: '',      //전화번호
          GRADE: ''           //고객등급
        })
        .then((ret) => {
          console.log("등급저장 결과>>>>>>>>>>>>>", ret.data.DATA);
        });
    },

    /** IPCC API호출 SAMPLE 전화걸기 */
    test2(){
      apiCall.ipcc("http://admin:meta12!!@192.168.101.104/cgi-bin/ConfigManApp.com?key=F_HEADSET;01054161796;ENTER");
    },

    /** IPCC API호출 SAMPLE 전화받기*/
    test3(){
      apiCall.ipcc("http://admin:meta12!!@192.168.101.104/cgi-bin/ConfigManApp.com?key=F_ACCEPT");
    },



    //220530 웹소켓 이벤트 추가
    //

  },
  
  computed: {

    ...mapGetters({
      INLNE_NO: "userStore/GE_USER_INLNE_NO",
      IP_ADDR: "userStore/GE_USER_IP_ADDR",
      geUserId: "userStore/GE_USER_ID",
      //topMsg: "userStore/GE_USER_TOPMSG",
    }),

    /** 공통코드 조회 */ 
    async selectCommonCode(){                      
      this.commonCodeLst = await this.mixin_common_code_get_all(this.commonCode);
    } ,

    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
      // return this.dates.join(' ~ ')
    },

    computedDateFormatted() {
      return this.formatDate(this.date);
    },

    computedDateFormatted2() {
      return `${this.date} ${this.timeStep}`;
    },

    chatListIng() {
      return this.chatList.filter((arr) => arr.type === "ing");
    },

    chatListDone() {
      return this.chatList.filter((arr) => arr.type === "done");
    },

    chatListAfter() {
      return this.chatList.filter(
        (arr) => arr.type === "after" || arr.type === "after countdown"
      );
    },

    chatListBookmark() {
      return this.chatList.filter((arr) => arr.bookmark === "bookmark");
    },

    chatListReseve() {
      return this.chatList.filter((arr) => arr.statChat === true);
    },

    /****************************************************************************************************************************************************
     * 콜 computed START
     ***************************************************************************************************************************************************/
    
    ctiStateText() {

      let ctiState;

      console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");  
      console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");  
      console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");  
      console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");  
      console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");  
      console.log("CTI_BRD_MOVE : " + this.CTI_BRD_MOVE.status);  
      console.log("CTI_BRD_MOVE status : " + this.CTI_BRD_MOVE.status);  
      console.log("CTI_BRD_MOVE current : " + this.CTI_BRD_MOVE.current);  
      console.log("CTI_BRD current : " + this.CTI_BRD.current);  

      if (this.CTI_BRD_MOVE.status == undefined ) {

        if (this.CTI_LOGIN_BOOLEAN == true) {
          ctiState = 'CTI ON';
        } else if (this.CTI_LOGIN_BOOLEAN == false) {
          ctiState = 'CTI OFF';
        } 
        
        if (this.CTI_LOGIN_BOOLEAN == true && this.CTI_BRD.current != undefined && this.CTI_BRD.current != '' && this.CTI_BRD.current != 'null' && this.CTI_BRD.current != 'null') {
          ctiState = this.CTI_BRD.current;  
        }
        
      } else if(this.CTI_BRD_MOVE.status == true ) {
        ctiState = this.CTI_BRD_MOVE.current;
      } else{
        ctiState = this.CTI_BRD.current;
      }

      return ctiState;

    }

    /****************************************************************************************************************************************************
     * 콜 computed END
     ***************************************************************************************************************************************************/    
  },

  
  //220525 김태준 채팅 이벤트 추가
  //
  async mounted() {

    /****************************************************************************************************************************************************
     * 공통 변수 / 함수 정보 START 
     ***************************************************************************************************************************************************/ 
      
      this.selectCommonCode;                                            //공통코드 조회 
      
    /****************************************************************************************************************************************************
     * 공통 변수 / 함수 정보 END 
     ****************************************************************************************************************************************************
     ***************************************************************************************************************************************************/


    /****************************************************************************************************************************************************
     * 챗 변수 / 함수 정보 START
     ***************************************************************************************************************************************************/
      
      window.addEventListener ('unload', this.disconnect());              //this.calculateWindowWidth();
      window.addEventListener ('beforeunload', this.disconnect());        //this.calculateWindowWidth();
      window.addEventListener("keydown", this.setAvailableRefresh);       // 새로고침 f5 방지 

      /** 상담메인 오픈 시 상담원상태 초기화  */ 
      this.changeUserStat(false, "ONREADY");                              // 초기 채팅 상담사 상태 이벤트 처리 
      
      this.setChatUserInfo();                                             // 상담사 정보 세팅    
      
      this.getTalkList();

    /****************************************************************************************************************************************************
     * 챗 변수 / 함수 정보 END 
     ****************************************************************************************************************************************************
     ***************************************************************************************************************************************************/
      

    /****************************************************************************************************************************************************
     * 콜 mounted START
     ***************************************************************************************************************************************************/

      /* 콜관련 공통코드 일괄조회 cmmnCd: ['PLT002', 'PLT003', 'PLT004','PLT006'] (콜관련공통코드_상담통합이력 콜유형_상담통합이력 IN_상담통합이력 OB_B/L 구분)*/
      this.cmmnCdLst = await this.mixin_common_code_get_all(this.cmmnCd);

      this.initCallCmmn();                    // 공통코드_cmmnCd: ['PLT002', 'PLT003', 'PLT004','PLT006']_콜관련공통코드_상담통합이력 콜유형_상담통합이력 IN_상담통합이력 OB_B/L 구분 
      this.intiCall('ALL');                   // 콜관련 전체 초기화 
    
      this.ctiIpccConnect(this.INLNE_NO);     // IPCC Connect

    /****************************************************************************************************************************************************
     * 콜 mounted END
     ***************************************************************************************************************************************************/  
    
  },
  //
  //220525 김태준 채팅 이벤트 추가

  beforeUnmount() {
    window.removeEventListener('beforeunload', this.disconnect());
  },

  watch: {
    //callnum() {
    //  this.callRing();
    //},
    // updated() {
    //   this.ini2();
    // },
  },
};
</script>

<style></style>
